define('widgets/tag-metadata/index',[
    'msgme/underscore',
    'msgme/viewmodel',
    './../three-widget',
    'json!./strings.json',
    'json!widgets/shared-strings.json',
    'text!./template.html'
], function (_, viewmodel, ThreeWidget, strings, sharedStrings, template) {
    var passedObservables = {
        metadata: null,
        value: null,
        isDisabled: false
    };

    var internalObservables = {
        select2DropdownCssClass: 'dynamic-insertion-dropdown',
        initialChange: true,
        validValuesNotifier: null,
        placeholder: null,
        operator: null,
        currentMetadata: null,
        currentValue: null
    };

    var allObservables = _.extend({}, passedObservables, internalObservables);
    var validationMessages = sharedStrings.metadata.validation.fields;

    var mapping = {
        defaults: _.clone(allObservables),
        validation: {
            currentValue: {
                validator: function (val) {
                    var field = this.parent.currentMetadata();
                    var record =
                        viewmodel.globals.metadata.oneById(field);
                    var tenDigitPattern = /^[0-9]{10}$/;
                    var pattern;

                    if (!record) {
                        return true;
                    }

                    if (field && !val) {
                        return false;
                    }

                    val = val == null ? '' : val;

                    if (record.format == null) {
                        pattern = /^\d{2}\d{2}\d{4}$/;
                    } else {
                        pattern =
                            new RegExp('^' + record.format + '$');
                    }

                    if (record.validValues) {
                        // fixed values are valid if they are in
                        // the validValues array
                        return _.all(val, function (value) {
                            return _.contains(
                                record.validValues, value);
                        });
                    } else {
                        // free values are valid if they match the
                        // format regex
                        return _.all(val, function (value) {
                            value = _.trim(value);
                            if (field ===
                                '4ec0a3dc0364de64869d93c2') {
                                return pattern.test(value) ||
                                    tenDigitPattern.test(value);
                            } else {
                                return pattern.test(value);
                            }
                        });
                    }
                },
                message: function () {
                    var field = this.parent.currentMetadata();
                    var record =
                        viewmodel.globals.metadata.oneById(field);

                    var messageName = !record ||
                            !(record.name in validationMessages) ?
                        '__default__' : record.name;
                    
                    if (field === '4ec0a3dc0364de64869d93c2') {
                        return sharedStrings.metadata.validation.msisdn;
                    } else {
                        return validationMessages
                            [messageName].single;
                    }
                }
            }
        }
    };

    $.widget('msgme.msgme_tag_metadata', ThreeWidget, {
        _template: template,

        _mapping: mapping,

        _create: function () {
            ThreeWidget.prototype._create.apply(this, arguments);

            var vm = this.option('viewmodel');

            this.on('click', '.open-popover', '_openPopover');
            this.on('click', '.close-popover', '_closePopover');
            this.on('mouseenter', '.popover', '_onEnter');
            this.on('mouseleave', '.popover', '_onLeave');
            this.on('click', '.clear-metadata', '_clearTags');

            if (vm.value()) {
                vm.currentValue(vm.value());
            }

            if (vm.metadata()) {
                vm.currentMetadata(vm.metadata());
            }
        },

        _createViewModel: function () {
            var vm = ThreeWidget.prototype._createViewModel.
                apply(this, arguments);
            vm.globals = viewmodel.globals;
            return vm;
        },

        _clearTags: function () {
            var vm = this.option('viewmodel');

            // clearing the observable doesn't clear the value so we have
            // to clear it with the select2 val param
            this.element.find('.metadata-select').select2('val', '');
            vm.value(null);
            vm.currentValue(null);
            vm.metadata(null);
        },

        _onEnter: function () {
            this.element.find('.tag-metadata').addClass('remove-hover');
        },

        _onLeave: function () {
            this.element.find('.tag-metadata').removeClass('remove-hover');
        },

        _openPopover: function () {
            var vm = this.option('viewmodel');

            // hide any popover that may already be open
            $('.toolbar-popover').hide();

            this.element.find('.toolbar-popover').show();
            if (vm.value()) {
                vm.currentValue(vm.value());
            }

            if (vm.metadata()) {
                vm.currentMetadata(vm.metadata());
            }
        },

        _closePopover: function () {
            var vm = this.option('viewmodel');

            vm.currentValue.valueHasMutated();

            if (vm.currentValue.isValid()) {
                vm.value(vm.currentValue());
                vm.metadata(vm.currentMetadata());
                this.element.find('.toolbar-popover').hide();
            } else {
                vm.currentValue.isModified(true);
            }
        }
    });

    return {
        widget: $.msgme.msgme_tag_metadata,
        mapping: mapping
    };
});

