define('widgets/segment-metadata-value/index',[
    'msgme/ko',
    'msgme/underscore',
    'msgme/viewmodel',
    'msgme/viewmodel/smartlists',
    'msgme/util/api',
    '../three-widget',
    'text!./template.html',
    'json!./strings.json',
    'json!widgets/shared-strings.json'
], function(
    ko,
    _,
    viewmodel,
    smartlist,
    api,
    ThreeWidget,
    template,
    strings,
    shared
) {
    function updateGeoValue(root, parent) {
        var params = {
            radius: parent.geoRadius(),
            zipcode: parent.geoZipcode()
        };
        var isGeo = parent.__operatorIsGeo__;

        // this is evaluated before the 'after' plugins, which include the
        // validation rules, so the `isValid` computed's may not be present
        var isValid = ko.utils.unwrapObservable(parent.geoRadius.isValid) &&
            ko.utils.unwrapObservable(parent.geoZipcode.isValid);

        if (isValid && params.radius && params.zipcode && isGeo) {
            parent.geoValueRequest(api.call('filters.zipcode', params).
                then(function (result) {
                    parent.value(result.zipcodes.join(', '));
                    parent.geoValueRequest(null);
                }));
        } else {
            parent.geoValueRequest(null);

            if (isGeo) {
                parent.value('');
            }
        }
    }

    function isGeo() {
        return this.root.type() === 'geo';
    }

    var validationMessages = shared.metadata.validation.fields;
    var multiValueOperators = _.reduce(['in', 'nin', 'all'], function (o, k) {
        o[k] = true;
        return o;
    }, {});
    var metadata = viewmodel.globals.metadata;
    var mapping = {
        defaults: {
            field: null,
            initialChange: true,
            validValuesNotifier: null,
            placeholder: null,
            operator: null,
            multiValue: null,
            value: null,
            metadataYear: null,
            geoRadius: null,
            geoZipcode: null,
            geoValueRequest: null,
            queryFilter: null
        },
        validation: {
            field: {
                required: true
            },
            geoZipcode: {
                validator: function () {
                    // the geo value could be either a zip or area code
                    return !isGeo.call(this) ||
                        (/^\d{1,5}$/).test(this.observable());
                },

                message: function () {
                    return [strings.geo.error.value];
                }
            },

            geoRadius: {
                number: {onlyIf: isGeo},
                required: {onlyIf: isGeo}
            },
            value: {
                validator: function (val) {
                    var field = this.parent.field();
                    var operator = this.parent.operator();
                    var record =
                        viewmodel.globals.metadata.oneById(field);
                    var tenDigitPattern = /^[0-9]{10}$/;
                    var pattern;

                    if (!record) {
                        return true;
                    }

                    if (operator === 'exists') {
                        return _.isBoolean(val);
                    }

                    val = val == null ? '' : val;

                    if (operator in multiValueOperators) {
                        val = _.isString(val) ? val.split(',') : '';
                    } else {
                        val = [val];
                    }

                    if (record.format == null) {
                        pattern = /^\d{2}\d{2}\d{4}$/;
                    } else {
                        pattern =
                            new RegExp('^' + record.format + '$');
                    }

                    if (record.validValues) {
                        // fixed values are valid if they are in
                        // the validValues array
                        return _.all(val, function (value) {
                            return _.contains(
                                record.validValues, value);
                        });
                    } else {
                        // free values are valid if they match the
                        // format regex
                        return _.all(val, function (value) {
                            value = _.trim(value);
                            if (field ===
                                '4ec0a3dc0364de64869d93c2') {
                                return pattern.test(value) ||
                                    tenDigitPattern.test(value);
                            } else {
                                return pattern.test(value);
                            }
                        });
                    }
                },
                message: function () {
                    var field = this.parent.field();
                    var operator = this.parent.operator();
                    var record =
                        viewmodel.globals.metadata.oneById(field);

                    var arity = operator in multiValueOperators ?
                        'multiple' : 'single';

                    var messageName = !record ||
                            !(record.name in validationMessages) ?
                        '__default__' : record.name;
                    
                    if (field === '4ec0a3dc0364de64869d93c2') {
                        return shared.metadata.validation.msisdn;
                    } else {
                        return validationMessages
                            [messageName][arity];
                    }
                }
            },
            metadataYear: {
                required: {
                    onlyIf: function () {
                        return this.parent.isDateMetadata();
                    }
                },
                minLength: 1,
                maxLength: 2,
                number: true,
                message: function () {
                    return 'Please enter an age value';
                }
            }
        },
        subscribe: {
            metadataYear: function (value, parent) {
                if (parent.isDateMetadata()) {
                    if (value && !isNaN(value)) {
                        var date = Date.create().rewind({ years: value }).
                            format('{MM}{dd}{yyyy}');

                        parent.value(date);
                    }
                }
            },
            geoRadius: updateGeoValue,
            geoZipcode: updateGeoValue
        },
        computed: {
            metadataProfile: function () {
                return viewmodel.globals.metadata.grouped().profile;
            },
            metadataSystem: function () {
                return viewmodel.globals.metadata.groupedAndSorted().system;
            },
            metadataCustom: function () {
                return viewmodel.globals.metadata.grouped().custom;
            },
            sortedMetadata: function () {
                return _.sortBy(viewmodel.globals.metadata(), function (meta) {
                    return meta.name.toLowerCase();
                });
            },
            isDateMetadata: function () {
                var field = this.field();

                if (field && viewmodel.globals.metadata.oneById(field)) {
                    return viewmodel.globals.metadata.oneById(
                        field).type === 'DATE';
                } else {
                    return false;
                }
            },
            operatorOptions: function () {
                if (this.isDateMetadata()) {
                    return shared.dateOperators;
                } else {
                    return shared.operators;
                }
            },
            setOperator: {
                read: function () {
                    var op = this.operator();

                    if (this.__operatorIsGeo__ && /(n)?in/.test(op)) {
                        return op === 'in' ? 'geo' : 'notgeo';
                    } else if (op === 'exists') {
                        return this.value() ? op : 'not' + op;
                    } else {
                        return op;
                    }
                },

                write: function (root, op) {
                    var current = this.operator();

                    if (/(not)?geo/.test(op)) {
                        this.__operatorIsGeo__ = true;
                        this.operator(op === 'geo' ? 'in' : 'nin');
                        return;
                    }

                    this.__operatorIsGeo__ = false;

                    if (/exists/.test(op)) {
                        this.operator('exists');
                        this.value(/not/.test(op) ? false : true);
                        return;
                    } else if (/exists/.test(current)) {
                        this.value(null);
                    }

                    this.operator(op);
                }
            },
            type: function () {
                var op = this.operator();

                if (this.__operatorIsGeo__) {
                    return 'geo';
                }

                if (/exists/.test(op)) {
                    return 'novalue';
                }

                return 'standard';
            },
            isMultiValue: function () {
                return ((this.operator() === 'nin' ||
                    this.operator() === 'in' || this.operator() === 'all') &&
                    this.field.hasOwnProperty('validValues') &&
                    this.field.validValues());
            }
        },
        subcomputed: {
            field: {
                validValues: function (root, parent) {
                    var record = metadata.oneById(parent());

                    // ensure there's a dependency on validValuesNotifier
                    root.validValuesNotifier();

                    return record ? record.validValues : null;
                }
            },
            value: {
                fixed: {
                    read: function (root, parent) {
                        return parent();
                    },
                    write: function (root, parent, value) {
                        value = value === void undefined ? null : value;

                        if (!this._locked && !!root.field.validValues() &&
                           !root.isMultiValue()) {
                            // ko.validation fires changes on observables when
                            // their validity changes, but passes the wrong
                            // value to subscribers (it passes the value of the
                            // private __valid__ observable sted the value of
                            // the observable itself).
                            //
                            // The upshot is that you have to select fixed
                            // values twice in the segmentation filter dropdown,
                            // which is a bummer. so, ignore attempts to write
                            // when we're already writing.

                            if (!((root.operator() === 'nin' ||
                                root.operator() === 'in' ||
                                root.operator() === 'all') &&
                                root.field.hasOwnProperty('validValues') &&
                                root.field.validValues()) &&
                                root.operator() !== 'exists') {
                                this._locked = true;
                                parent(value);
                                this._locked = false;
                            }
                        }
                    },
                    deferEvaluation: true
                },
                free: {
                    read: function (root, parent) {
                        return parent();
                    },

                    write: function (root, parent, value) {
                        if (!root.field.validValues()) {
                            parent(value);
                        }
                    },
                    deferEvaluation: true
                },
                multi: {
                    read: function (root, parent) {
                        return parent();
                    },

                    write: function (root, parent, value) {
                        parent(value);
                    },
                    deferEvaluation: true
                }
            }
        }
    };

    /**
     * View and change a fixed or free metadata value
     */
    $.widget('msgme.msgme_segment_metadata_value', ThreeWidget, {
        _mapping: mapping,
        _template: template,

        _create: function () {
            ThreeWidget.prototype._create.apply(this, arguments);

            var vm = this.option('viewmodel');

            this.on('click', '.or-button', 'onAdd');
            this.on('change', 'select.multiple', 'onMultipleSelectChange');

            if (vm.operator() && vm.operator() === 'nin' ||
                vm.operator() === 'in' || vm.operator() === 'all') {

                vm.operator.valueHasMutated();

                if (vm.value() && vm.value().length) {
                    _.defer(_.bind(function() {
                        this.element.find('.value.multiple').
                            val(vm.value().split(',')).trigger('change');
                    }, this));
                }
            }

            if (vm.operator() === 'exists' && vm.value() === 'false') {
                vm.value(false);
            }
        },

        onAdd: function () {
            var viewmodel = this.option('viewmodel');

            viewmodel.queryFilter.push(
                ko.mapping.fromJS(smartlist.mapping.details));
        },

        onMultipleSelectChange: function (event) {
            var value = $(event.target).val();

            if (value) {
                this.option('viewmodel').value.multi(value.join());
            }
        }
    });

    return {
        widget: $.msgme.msgme_segment_metadata_value
    };
});

