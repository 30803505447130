define('msgme/viewmodel/flowmodule-agegate',[
    'msgme/underscore',
    'msgme/viewmodel',
    'json!widgets/shared-strings.json'
], function (
    _,
    viewmodel,
    sharedStrings
) {
    var defaultStrings = sharedStrings.mobileflows.agegate.defaults;
    var numberToString = {
        read: function (root, parent) {
            var result = parent();
            return _.isNumber(result) ? '' + result : '';
        },
        write: function (root, parent, value) {
            value = parseInt(value, 10);
            parent(_.isNumber(value) && !_.isNaN(value) ? value : null);
        }
    };
    var validation = {
        ageThreshold: {
            required: true,
            number: true,
            min: 1
        },
        metadataId: {
            required: true
        },
        ageRequestMessage: {
            required: true
        },
        invalidFormatMessage: {
            required: true
        },
        mobileFlowId: function (val) {
            if (this.parent.nextStep() === 'forwardToMobileFlow') {
                if (viewmodel.flow() && viewmodel.flow().id) {
                    return !!val && val !== viewmodel.flow().id();
                } else {
                    return !!val;
                }
            } else {
                return true;
            }
        },
        notOfAgeMessage: function (val) {
            if (this.parent.nextStep() === 'terminate') {
                return !!val;
            } else {
                return true;
            }
        }
    };

    return {
        mapping: {
            local: {
                title: 'Age Verification',
            },

            params: {
                mapping: {
                    defaults: {
                        metadataId: '',
                        ageRequestMessage: defaultStrings.ageRequestMessage,
                        ageRequestSubject: '',
                        ageRequestFiles: [],
                        ageRequestAd: null,
                        ageRequestSponsorship: null,
                        ageRequestSMSFallback: null,
                        ageThreshold: 18,
                        ageComparison: 'over',
                        invalidFormatMessage:
                            defaultStrings.invalidFormatMessage,
                        invalidFormatSubject: '',
                        invalidFormatFiles: [],
                        invalidFormatAd: null,
                        invalidFormatSponsorship: null,
                        invalidFormatSMSFallback: null,
                        notOfAgeMessage: defaultStrings.notOfAgeMessage,
                        notOfAgeSubject: '',
                        notOfAgeFiles: [],
                        notOfAgeAd: null,
                        notOfAgeSponsorship: null,
                        notOfAgeSMSFallback: null,
                        nextStep: 'forwardToMobileFlow',
                        mobileFlowId: ''
                    },

                    subcomputed: {
                        ageThreshold: {
                            asString: numberToString
                        }
                    },

                    local: {
                        ageRequestCurrentTab: 'sms',
                        invalidFormatCurrentTab: 'sms',
                        notOfAgeCurrentTab: 'sms'
                    },

                    validation: validation
                }
            }
        }
    };
});

