define('msgme/widgets/flowmodule-collectmetadata',[
    'jquery',
    'msgme/ko',
    'msgme/widgets/flowmodule',
    'msgme/viewmodel/flowmodule-collectmetadata'
], function ($, ko, flowmodule, collectmetadata) {
    $.widget('msgme.msgme_flowmodule_collectmetadata', flowmodule, {
        options: {
            widgetEventPrefix: 'flowmodule-collectmetadata-'
        },

        _create: function () {
            flowmodule.prototype._create.apply(this);
            var params = this.options.data().params;

            params.requestMessage.extend({ required: true });
            params.invalidFormatMessage.extend({ required: true });
        }
    });

    $.msgme.msgme_flowmodule_collectmetadata.Model =
        flowmodule.modelFactory({}, { mapping: collectmetadata.mapping });

    return $.msgme.msgme_flowmodule_collectmetadata;
});


