define('widgets/stream/index',[
    'msgme/underscore',
    'msgme/util/api',
    './../three-widget',
    'text!./template.html',
    'json!./strings.json',
    './../stream-entry/index',
    './../async-button/index'
], function (_, api, ThreeWidget, template, strings) {
    var mapping = {
        defaults: {
            data: null,
            search: '',
            pageSize: 20,
            size: 20,
            pollInterval: 30 * 1000,
            newMessageCount: 0,
            moreMessages: false,
            inFlightMessagesRequest: null,
            cachedNewMessages: null,
            idle: 0,
            timeout: 0,
            idleModalShown: null
        },

        computed: {
            newMessagesText: function (root) {
                var count = root.newMessageCount();
                var string = strings[count > 1 ? 'newMessages' : 'newMessage'];

                return count ? count + ' ' + string : '';
            },

            moreMessagesText: function (root) {
                return root.moreMessages() ? strings.more : '';
            }
        }
    };

    $.widget('msgme.msgme_stream', ThreeWidget, {
        _mapping: mapping,

        _template: template,

        _create: function () {
            var vm;

            _.bindAll(this, '_setPollTimeout', 'refresh');

            this._super.apply(this, arguments);

            this._polling = true;

            this.on('cleared', '_onClearedStreamEntry');
            this.on('click', '.new-messages', '_onClickNewMessages');
            this.on('click', '.more-messages', '_onClickMoreMessages');

            vm = this.option('viewmodel');
            vm.search.subscribe(_.debounce(this.refresh, 300));

            this._setPollTimeout();

            $('body').on('click mousemove keyup', function () {
                vm.idle(0);
            });
        },

        _abortInFlightRequest: function () {
            var inFlight = this.option('viewmodel').inFlightMessagesRequest();

            if (inFlight) {
                inFlight.abort();
            }
        },

        _defaultParams: function () {
            return {size: this.option('viewmodel').size()};
        },

        _destroy: function () {
            this._super.apply(this, arguments);
            this._polling = false;
        },

        _onClearedStreamEntry: function (evt) {
            var index = $(evt.target).index();
            var vm = this.option('viewmodel');

            vm.data().splice(index, 1);
            vm.data.valueHasMutated();
        },

        _onClickMoreMessages: function () {
            var vm = this.option('viewmodel');
            var params = this._defaultParams();
            var lastMessage = _.last(vm.data());
            var promise;

            params.pivotId = lastMessage.id;
            params.orderBy = '-_id';
            promise = api.call('stream.fetch', params);
            vm.inFlightMessagesRequest(promise);

            return promise.
                then(_.bind(function (result) {
                    var vm = this.option('viewmodel');
                    vm.moreMessages(result.pageIndex < result.pageCount);

                    _.each(result, function (message) {
                        vm.data().push(message);
                    });

                    vm.data.valueHasMutated();

                    return result;
                }, this)).
                fail(api.getRequestFailureFn(null, 'stream.fetch')).
                always(function () {
                    vm.inFlightMessagesRequest(null);
                });
        },

        _onClickNewMessages: function () {
            var vm = this.option('viewmodel');
            var newSize = vm.size() + vm.newMessageCount();
            var newMessages = vm.cachedNewMessages().reverse();

            vm.search('');
            vm.size(newSize);
            vm.newMessageCount(0);
            _.each(newMessages, function (message) {
                _.each(vm.data(), function (currentMessage, i) {
                    if (currentMessage.subscriber === message.subscriber) {
                        vm.data().splice(i, 1);
                    }
                });
                vm.data().unshift(message);
            });
            vm.data.valueHasMutated();
            this._latestMessage = vm.data()[0];
        },

        _setPollTimeout: function () {
            var vm = this.option('viewmodel');
            var inFlight = vm.inFlightMessagesRequest() ||
                $.Deferred().resolve();

            inFlight.then(_.bind(function () {
                setTimeout(_.bind(function () {
                    if (this._polling) {
                        this.poll();
                    }
                }, this), vm.pollInterval());
            }, this));
        },

        poll: function () {
            var params = this._defaultParams();
            var vm = this.option('viewmodel');

            if (window.location.pathname === '/conversations') {
                if (this._latestMessage) {
                    params.pivotId = this._latestMessage.id;
                    params.orderBy = '_id';
                }

                params.size = 0;

                if (!!vm.idleModalShown() &&
                    $('#login-view').is(':hidden')) {
                    if (vm.timeout() < 59) {
                        vm.timeout(vm.timeout() + 1);
                    } else {
                        $('#msgme-modal').modal('hide');
                        return api.call('stream.fetch', params).
                            done(function () {
                                vm.timeout(0);
                            });
                    }
                }

                if (vm.idle() < 40 && $('#msgme-modal').is(':hidden') &&
                    $('#login-view').is(':hidden')) {
                    return api.call('stream.fetch', params).
                        then(_.bind(function (result) {
                            vm.cachedNewMessages(result);
                            vm.newMessageCount(result.total);
                            vm.idle(vm.idle() + 1);
                            vm.timeout(0);
                            vm.idleModalShown(false);
                        }, this)).
                        always(this._setPollTimeout);
                } else {
                    if (vm.idle() === 40 && !vm.idleModalShown()) {
                        vm.idleModalShown(true);
                        msgme.modal.notice('You have been idle for 20 minutes.'+
                            ' Continue viewing the Conversation Stream?', {
                            title: 'Idle Conversation Stream',
                            button: 'Continue'
                        });
                    }
                    this._setPollTimeout();
                }
            } else {
                this._polling = false;
            }
        },

        refresh: function () {
            var params = this._defaultParams();
            var vm = this.option('viewmodel');
            var promise;

            this._abortInFlightRequest();

            if (vm.search()) {
                params.search = vm.search();
            }

            promise = api.call('stream.fetch', params);
            vm.inFlightMessagesRequest(promise);

            return promise.
                then(_.bind(function (result) {
                    var vm = this.option('viewmodel');
                    var streamEntries;

                    _.each(result, _.bind(function (entry) {
                        if (!this._latestMessage ||
                                entry.createdAt >
                                this._latestMessage.createdAt) {
                            this._latestMessage = entry;
                        }
                    }, this));

                    vm.data(result);
                    vm.moreMessages(result.pageIndex < result.pageCount);

                    streamEntries =
                        this.element.find('.stream-entry-container');

                    _.each(streamEntries, function (entry) {
                        $(entry).
                            msgme_stream_entry('instantiateMessageTextbox');
                    });

                    return result;
                }, this)).
                fail(api.getRequestFailureFn(null, 'stream.fetch')).
                always(_.bind(function () {
                    vm.inFlightMessagesRequest(null);
                    if (!this._polling) {
                        this._polling = true;
                        this._setPollTimeout();
                    }
                }, this));
        }
    });

    return {
        widget: $.msgme.msgme_stream,
        mapping: mapping,
    };
});

