define('msgme/viewmodel/coupon',[
    'msgme/underscore',
    'msgme/ko',
    'msgme/viewmodel',
    'msgme/viewmodel/mapping'
], function (_, ko, viewmodel, viewmodelMapping) {
    var mapping = {
        local: {
            uploadType: null,
            inFlightRequest: null
        },
        defaults: {
            id: null,
            account: null,
            group: null,
            name: '',
            fileNames: [],
            total: 0,
            available: 0,
            uploads: []
        },
        computed: {
            delivered: function () {
                return this.total() - this.available();
            }
        },
        validation: {
            name: {
                required: true,
                minLength: 5,
                maxLength: 30
            }
        }
    };

    var model = ko.observable(null);

    model.error = ko.observable(false);

    return {
        mapping: mapping,
        model: viewmodel.coupon = model,
        collection: viewmodel.coupons = {
            rows: ko.observableArray(),
            pageIndex: ko.observable(-1),
            pageCount: ko.observable(-1),
            pageSize: ko.observable(15),
            links: ko.observableArray(),
            url: ko.observable('coupons/page'),
            name: ko.observable(),
            group: ko.observable(),
            file: ko.observable()
        },
        coupons: new viewmodelMapping.CursorModel(waterfall.coupons, {
            record: {
                mapping: mapping
            }
        })
    };
});

