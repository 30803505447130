define('msgme/views/report',[
    'msgme/underscore',
    'msgme/ko',
    'msgme/splash',
    'msgme/path',
    'msgme/views/View',
    'msgme/viewmodel',
    'msgme/viewmodel/mapping',
    'msgme/viewmodel/report',
    'msgme/util/format',
    'msgme/widgets/pager',
    'json!widgets/shared-strings.json',
    'widgets/report-parameters/index'
], function (
    _,
    ko,
    splash,
    path,
    View,
    viewmodel,
    mapping,
    report,
    format,
    Pager,
    sharedStrings
) {
    var view  = new View('#report-view');
    var reports = sharedStrings.sitemap.reports;
    var url = new RegExp(reports.url +
        reports.regexExcludeScheduled + reports.regexExcludeRecurring +
        reports.reportSubUrl + reports.resultSubUrl);
    var page, pager;
    var cm = new mapping.CursorModel(waterfall.reporting, {
        record: {
            mapping: report.reportViewMapping
        }
    });

    viewmodel.report = ko.observable(cm.create());

    function fetchAndPopulate(result, query) {
        result._record.view(query).
            done(function (reportInstance) {
                var reportModel = viewmodel.report();

                view.root.find('.parameters').msgme_report_parameters({
                    data: new ko.observable(result)
                });

                _.each(reportInstance, function (report) {
                    if (report.date) {
                        report.uiDate = format.uiDate(report.date);
                    }

                    if (report.msisdn) {
                        report.phoneNo = format.phoneNo(report.msisdn);
                    }
                });

                reportModel.type(result.query.type());
                reportModel.rows(reportInstance);
                pager.update(reportInstance);
                reportModel.pageCount(reportInstance.pageCount);
                reportModel.pageSize(reportInstance.pageIndex);
                reportModel.links(pager._viewmodel.links());

                splash.hide();
                $('#report-view-main').msgme('busy', false);
            }).
            fail(view.getRequestFailureFn(null,
                'reporting.fetch', result.query.type()));
    }

    $('#message-type-modal').msgme_modal();

    view.root.on('click', 'button.message-type', function () {
        $('#message-type-modal').msgme_modal('open');
    });

    path.map(url).to(function (pth, reportsPage, id, resultsPage) {
        if (!id) {
            return;
        }

        page = resultsPage;

        view.show();

        $('#report-view-main').msgme('busy', true);
        cm.fetch(id).
            done(function (result) {
                var query = {};

                query.page = page;
                viewmodel.report(result);
                viewmodel.report().url('reports/' +
                    (reportsPage > 1 ? 'page/' + reportsPage + '/' : '') + id +
                    '/page');
                if (!pager) {
                    pager = new Pager(result);
                }

                fetchAndPopulate(result, query);
            }).
            fail(view.getRequestFailureFn(null, 'reporting.fetch'));

    });

    return view;
});


