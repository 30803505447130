
define("json!widgets/create-report/strings.json", function(){ return {
  "title": "Reports Manager",
  "name": "Name",
  "type": {
    "label": "Report type",
    "placeholder": "Select report type...",
    "options": {
      "messageDetails": "Message Details",
      "messageSummary": "Message Summary",
      "subscriberGrowth": "Subscriber Growth",
      "broadcastSummary": "Broadcast Summary",
      "listDetails": "List Details",
      "pollSummary": "Poll Summary",
      "subscriberConversion": "Subscriber Conversion"
    }
  },
  "scope": {
    "label": "Scope",
    "placeholder": "All",
    "options": {
      "mobileFlow": "Campaign",
      "keyword": "Keyword"
    }
  },
  "flow": {
    "label": "Campaign",
    "placeholder": "All"
  },
  "keyword": {
    "placeholder": "All"
  },
  "excludeBroadcast": "Exclude broadcast MTs",
  "messageType": {
    "label": "Message type",
    "placeholder": "All",
    "options": {
      "MO": "Incoming (MOs)",
      "MT": "Outgoing (MTs)"
    }
  },
  "contentType": {
    "label": "Component type",
    "placeholder": "All",
    "options": {
      "SUBSCRIPTION": "Sub opt-in",
      "BASICTEXT": "Basic text",
      "COLLECTMETADATA": "Collect metadata",
      "TAGMETADATA": "Tag metadata",
      "POLL": "Polls",
      "TEXTTOWIN": "Text to win",
      "SWEEPS": "Sweepstakes",
      "VALIDATION": "Validation",
      "AGEGATE": "Age Verification",
      "DATEGATE": "Campaign Duration",
      "DYNAMICCONTENT": "Dynamic content",
      "POSTTOURL": "Post to URL",
      "ADDTOADDITIONALLIST": "Assign to Additional List",
      "SPORTSFEED": "Sports Feed",
      "STOP": "Stop",
      "CATCHALL": "Catchall",
      "HELP": "Help",
      "DAYPART": "Daypart"
    }
  },
  "timeframe": {
    "label": "Timeframe",
    "placeholder": "Select timeframe...",
    "options": {
      "TODAY": "Today",
      "YESTERDAY": "Yesterday",
      "THISWEEK": "This week (Sun - Today)",
      "LAST7": "Last 7 days",
      "LASTWEEK": "Last week (Sun - Sat)",
      "LAST14": "Last 14 days",
      "THISMONTH": "This month",
      "LAST30": "Last 30 days",
      "LASTMONTH": "Last month",
      "CUSTOM": "Custom"
    }
  },
  "summaryType": {
    "label": "Summarize by",
    "options": {
      "MOBILEFLOW": "All",
      "SINGLE_MOBILEFLOW": "Campaign",
      "KEYWORD": "Keyword"
    }
  },
  "bucketSize": {
    "label": "Time summary",
    "placeholder": "Select time summary...",
    "options": {
      "DAY": "Day",
      "WEEK": "Week",
      "MONTH": "Month"
    }
  },
  "list": {
    "label": "List",
    "placeholder": "All"
  },
  "couponGroup": {
    "label": "Coupon group",
    "placeholder": "All"
  },
  "repeatInterval": {
    "label": "Repeats",
    "placeholder": "Never",
    "options": {
      "DAILY": "Daily",
      "WEEKLY": "Weekly",
      "MONTHLY": "Monthly"
    }
  },
  "repeatEvery": {
    "label": "Repeats every",
    "options": {
      "1": "1",
      "2": "2",
      "3": "3",
      "4": "4",
      "5": "5",
      "6": "6",
      "7": "7",
      "8": "8",
      "9": "9",
      "10": "10",
      "11": "11",
      "12": "12",
      "13": "13",
      "14": "14",
      "15": "15",
      "16": "16",
      "17": "17",
      "18": "18",
      "19": "19",
      "20": "20",
      "21": "21",
      "22": "22",
      "23": "23",
      "24": "24",
      "25": "25",
      "26": "26",
      "27": "27",
      "28": "28",
      "29": "29",
      "30": "30"
    }
  },
  "to": "up until and excluding",
  "dateformat": "MM/dd/yyyy",
  "schedulelabel": "Schedule for",
  "scheduleplaceholder": "Run immediately",
  "startplaceholder": "Start date",
  "endplaceholder": "End date",
  "submit": "Generate Report",
  "cancel": "Cancel",
  "validation": {
    "startEndLimits": "%(type)s reports must not start more than %(limit)s in the past and must not span more than %(duration)s.\n",
    "days": "days",
    "scheduleDate": "Please enter a date in the future"
  },
  "largeReports": "For %(type)s reports that span across more than %(duration)s or require data more than %(limit)s in the past, please contact %(contact)s.\n",
  "timestampInfo": "All timestamps in report data will be in Pacific Time",
  "subscriberConversionInfo": "Complete opt-in data only available for entries after Nov. 3, 2016"
};});

