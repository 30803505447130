/* vim: set et ts=4 sw=4 sts=4: */
define('msgme/views/app',[
    'msgme/underscore',
    'msgme/path',
    'msgme/viewmodel',
    'msgme/views/View',
    'msgme/views/campaign-activity',
    'msgme/views/channel',
    'msgme/views/stream',
    'msgme/views/coupon',
    'msgme/views/coupons',
    'msgme/views/dashboard',
    'msgme/views/flow',
    'msgme/views/flows',
    'msgme/views/group',
    'msgme/views/groups',
    'msgme/views/keywords',
    'msgme/views/list',
    'msgme/views/lists',
    'msgme/views/message',
    'msgme/views/messages',
    'msgme/views/metadata',
    'msgme/views/pass',
    'msgme/views/passes',
    'msgme/views/recurring-reports',
    'msgme/views/reports',
    'msgme/views/report',
    'msgme/views/role',
    'msgme/views/roles',
    'msgme/views/scheduled-reports',
    'msgme/views/subscriber-search',
    'msgme/views/subscriber',
    'msgme/views/sweep',
    'msgme/views/sweeps',
    'msgme/views/ads',
    'msgme/views/ad',
    'msgme/views/sponsorship',
    'msgme/views/sponsorships',
    'msgme/views/smartlist',
    'msgme/views/smartlists',
    'msgme/views/user',
    'msgme/views/users',
    'msgme/views/uploads',
    'msgme/widgets/add-to-list',
    'msgme/widgets/remove-from-list',
    'msgme/widgets/send-message',
    'msgme/widgets/add-metadata',
], function (
    _,
    path,
    viewmodel,
    View,
    campaignActivityView,
    channelView,
    streamView,
    couponView,
    couponsView,
    dashboardView,
    flowView,
    flowsView,
    groupView,
    groupsView,
    keywordsView,
    listView,
    listsView,
    messageView,
    messagesView,
    metadataView,
    passView,
    passesView,
    recurringReportsView,
    reportsView,
    reportView,
    roleView,
    rolesView,
    scheduledReportsView,
    subscriberSearchView,
    subscriberView,
    sweepView,
    sweepsView,
    adsView,
    adView,
    sponsorshipView,
    sponsorshipsView,
    smartlistView,
    smartlistsView,
    userView,
    usersView,
    uploadsView
) {
    var appView = new View('#app-view');

    // prevent forms from submitting by default
    appView.root.on('submit', function (e) {
        e.preventDefault();
    });

    // wrap each subview's show method to hide the other views
    // TODO: maybe refactor this into views.View somehow
    function showWrapper(otherViews, showFn) {
        var me = this;
        var promises =
            _.map(otherViews, function (view) {
                return me === reportView && view === reportsView ?
                    $.Deferred().resolve() : view.hide();
            });
        var d = new $.Deferred();
        // only show when all the promises are resolved
        $.when.apply($, promises).
            then(function () {
                showFn.call(me).
                    then(function () {
                        d.resolve();
                    });
            });
        return d.promise();
    }

    _.each([
        campaignActivityView,
        channelView,
        streamView,
        couponView,
        couponsView,
        dashboardView,
        flowView,
        flowsView,
        groupView,
        groupsView,
        keywordsView,
        listView,
        listsView,
        messageView,
        messagesView,
        metadataView,
        passView,
        passesView,
        recurringReportsView,
        reportsView,
        reportView,
        roleView,
        rolesView,
        scheduledReportsView,
        subscriberSearchView,
        subscriberView,
        sweepView,
        sweepsView,
        adsView,
        adView,
        sponsorshipView,
        sponsorshipsView,
        smartlistView,
        smartlistsView,
        userView,
        usersView,
        uploadsView
    ], function (view, i, views) {
        var otherViews = views.slice(0, i).concat(views.slice(i + 1));

        view.show =
            _.wrap(view.show, _.bind(showWrapper, view, otherViews));
    });

    return appView;
});


