define('msgme/viewmodel/flowmodule-tagmetadata',[
    'msgme/ko'
], function(ko) {
    return {
        mapping: {
            local: {
                title: 'Tag Metadata',
                validValues: null
            },

            defaults: {
                params: null
            },

            /**
             * The computed and params mapping work around the busted payload
             * provided by the platform, which looks like:
             *
             * { 'some-id': 'the value' }
             */
            computed: {
                metadataId: {
                    read: function () {
                        var params = this.params();
                        for (var k in params) {
                            if (params.hasOwnProperty(k)) {
                                return k;
                            }
                        }
                        return null;
                    },
                    write: function (rootModel, value) {
                        var params = {};

                        if (value) {
                            params[value] = this.validValues() ?
                                this.fixedValue() : this.freeValue();
                            this.params(params);
                        }
                    }
                },

                fixedValue: {
                    read: function () {
                        var params = this.params();
                        for (var k in params) {
                            if (params.hasOwnProperty(k)) {
                                // return the first and only value
                                return params[k];
                            }
                        }

                        return '';
                    },
                    write: function (rootModel, value) {
                        if (this.validValues()) {
                            var id = this.metadataId();
                            var params = {};

                            if (id) {
                                params[id] = value;
                                this.params(params);
                            }
                        }
                    }
                },

                freeValue: {
                    read: function () {
                        var params = this.params();
                        for (var k in params) {
                            if (params.hasOwnProperty(k)) {
                                // return the first and only value
                                return params[k];
                            }
                        }

                        return '';
                    },
                    write: function (rootModel, value) {
                        if (!this.validValues()) {
                            var id = this.metadataId();
                            var params = {};

                            if (id) {
                                params[id] = value;
                                this.params(params);
                            }
                        }
                    }
                }
            },

            params: {
                create: function (options) {
                    return ko.observable(options.data);
                },
                mapping: false
            },

            validation: {
                metadataId: {
                    required: true
                },
                freeValue: {
                    required: {
                        onlyIf: function () {
                            return this.parent.validValues() == null;
                        }
                    }
                },
                fixedValue: {
                    required: {
                        onlyIf: function () {
                            return this.parent.validValues() != null;
                        }
                    }
                }
            }
        }
    };
});

