define('msgme/viewmodel/subscriber-profile',[
    'msgme/ko',
    'msgme/underscore',
    'msgme/util/format',
    'msgme/viewmodel',
    'msgme/util/feature-flags',
    'json!widgets/shared-strings.json'
], function (ko, _, format, viewmodel, featureFlags, strings) {
    var SINGLE_OPTIN = 'singleOptIn';

    return {
        /**
         * The subscriber field is a string, so create an empty
         * subscriber profile from it
         */
        create: function (options) {
            var metadataOA = ko.observableArray();

            var result = {
                id: ko.observable(options.data),
                mobilePhoneNo: ko.observable(''),
                carrier: ko.observable(''),
                isSubscriberNamesFeatureEnabled: ko.observable(false),
                messages: {
                    rows: ko.observableArray([]),
                    pageIndex: ko.observable(-1),
                    pageCount: ko.observable(-1),
                    pageSize: ko.observable(15),
                    links: ko.observableArray([]),
                    url: ko.observable('')
                },
                widgetId:
                    ko.observable('subscriber-profile-' + options.data +
                        '-' + Date.now()),
                lists: ko.observableArray([]),
                blacklist: ko.observable(''),
                metadata: metadataOA,
                groupedMetadata: ko.computed(function() {
                    var subscriberMetadata = _.map(metadataOA(),
                        function(metadatum) {
                            var record =
                                viewmodel.globals.metadata.byId(metadatum.id);
                            var scope = record ? record.scope : '';

                            return _.extend({scope: scope}, metadatum);
                        });

                    return format.groupMetadata(subscriberMetadata);
                }),
                restrictUI: ko.observable(false),
                specialPMIRole: ko.observable(false),
                /*
                messages: {
                    rows: ko.observableArray([])
                },
                */
                modals: {
                    sendmessage: {
                        message: ko.observable(''),
                        schedule: ko.observable('')
                    },

                    unsubscribe: {
                        listId: ko.observable(''),
                        lists: ko.observable(null)
                    },

                    subscribe: {
                        listId: ko.observable(''),
                        optInType: ko.observable(SINGLE_OPTIN),
                        optInMessage: ko.observable(null),
                        confirmMessage: ko.observable(null),
                        subscribedMessage: ko.observable(null)
                    },

                    metadata: {
                        id: ko.observable(''),
                        value: ko.observable(null),
                        validValues: ko.observableArray(null),
                        format: ko.observable(null)
                    }
                }
            };

            result.subscriberDisplayName = ko.computed(function () {
                var isSubscriberNamesFeatureEnabled =
                    result.isSubscriberNamesFeatureEnabled();
                var metadata = viewmodel.globals.metadata();
                var firstNameMetadata = _.find(metadata,
                    function (metadatum) {
                        return metadatum.scope === 'PROFILE' &&
                            metadatum.name === 'firstName';
                    });
                var lastNameMetadata = _.find(metadata,
                    function (metadatum) {
                        return metadatum.scope === 'PROFILE' &&
                            metadatum.name === 'lastName';
                    });
                var firstName, lastName;

                if (!isSubscriberNamesFeatureEnabled ||
                    !(firstNameMetadata && lastNameMetadata)) {
                    return result.mobilePhoneNo();
                }

                firstName = _.find(result.metadata(), function (metadata) {
                    return metadata.id === firstNameMetadata.id;
                });
                lastName = _.find(result.metadata(), function (metadata) {
                    return metadata.id === lastNameMetadata.id;
                });

                if (!firstName && !lastName) {
                    return result.mobilePhoneNo();
                }

                firstName = firstName ? firstName.values : '';
                lastName = lastName ? lastName.values : '';

                return [firstName, lastName].join(' ');

            });
            result.modals.sendmessage._errors =
                ko.validation.group(result.modals.sendmessage);
            result.modals.sendmessage.isValid = ko.computed(function () {
                return result.modals.sendmessage._errors().length < 1;
            });
            result.modals.sendmessage.message.extend({ required: true });
            result.modals.sendmessage.widgetId = ko.computed(function () {
                return this.widgetId() + '-message-textbox';
            }, result);

            result.modals.subscribe._errors =
                ko.validation.group(result.modals.subscribe);
            result.modals.subscribe.isValid = ko.computed(function () {
                return result.modals.subscribe._errors().length < 1;
            });
            result.modals.subscribe.listId.extend({ required: true });
            result.modals.subscribe.optInMessage.extend({
                validation: {
                    validator: function (val) {
                        var optInType = result.modals.subscribe.optInType();

                        if (optInType === SINGLE_OPTIN) {
                            return true;
                        } else {
                            return !!val;
                        }
                    }
                }
            });
            result.modals.subscribe.optIn = {
                widgetId: ko.computed(function () {
                    return this.widgetId() + '-opt-in';
                }, result)
            };
            result.modals.subscribe.confirm = {
                widgetId: ko.computed(function () {
                    return this.widgetId() + '-confirm';
                }, result)
            };
            result.modals.subscribe.subscribed = {
                widgetId: ko.computed(function () {
                    return this.widgetId() + '-subscribed';
                }, result)
            };

            result.modals.unsubscribe._errors =
                ko.validation.group(result.modals.unsubscribe);
            result.modals.unsubscribe.isValid = ko.computed(function () {
                return result.modals.unsubscribe._errors().length < 1;
            });
            result.modals.unsubscribe.listId.extend({ required: true });

            var metadataModalModel = result.modals.metadata;
            metadataModalModel._errors =
                ko.validation.group(metadataModalModel);
            metadataModalModel.isValid = ko.computed(function () {
                return metadataModalModel._errors().length < 1;
            });

            metadataModalModel.id.extend({ required: true });
            metadataModalModel.value.extend({
                validation: {
                    validator: function (val) {
                        var format = metadataModalModel.format();
                        var metadata = metadataModalModel.id() ?
                            viewmodel.globals.metadata.
                                oneById(metadataModalModel.id()) : null;

                        if (format) {
                            return !!val && !!val.match('^' + format + '$');
                        } else {
                            if (metadata && metadata.type === 'DATE') {
                                return !!val &&
                                    !!val.match(/^\d{2}\d{2}\d{4}$/);
                            } else {
                                return !!val;
                            }
                        }
                    },
                    message: strings.subscriberProfile.validation.value
                }
            });

            metadataModalModel.value.free = ko.computed({
                read: function () {
                    if (!metadataModalModel.validValues()) {
                        return metadataModalModel.value();
                    }
                },
                write: function (value) {
                    if (!metadataModalModel.validValues()) {
                        metadataModalModel.value(value);
                    }
                }
            });

            metadataModalModel.value.fixed = ko.computed({
                read: function () {
                    if (!!metadataModalModel.validValues()) {
                        return metadataModalModel.value();
                    }
                },
                write: function (value) {
                    if (!!metadataModalModel.validValues()) {
                        metadataModalModel.value(value);
                    }
                }
            });

            featureFlags('showSubscriberNames').then(_.bind(function () {
                result.isSubscriberNamesFeatureEnabled(true);
            }, this));

            return ko.observable(result);
        }
    };
});

