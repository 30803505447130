define('widgets/file-uploader/index',[
    'msgme/underscore',
    'msgme/ko',
    'msgme/alert',
    'msgme/util/api',
    './../three-widget',
    'text!./template.html',
    'json!./strings.json',
    'jquery.form'
], function(_, ko, alert, api, ThreeWidget, template, strings) {
    function basename(path) {
        return _.last((path || '').split(/[\/\\]/));
    }

    function bytesToMb(bytes) {
        return bytes / 1024 / 1024;
    }

    var mapping = {
        defaults: {
            widget: null,
            isDisabled: false,
            isMMSDisabled: false,
            data: null,
            accept: null,
            file: null,
            fileSize: null,
            immediateUpload: true,
            uploadButtonClasses: null,
            browseText: null,
            trash: 'link',
            error: null,
            uploadRequest: null,
            uploadPercent: null,
            $displayContainer: null,
            maxFileSize: null,
            thumbnail: false,
            filePreview: false
        },
        computed: {
            filename: function () {
                return this.file() ?
                    _.last(this.file().s3Key.split('/')) : '';
            },
            fileExists: function () {
                return !!this.file();
            },
            fileUrl: function () {
                if (!this.fileExists()) { return ''; }
                return waterfall.file.url + '/' + this.file().id;
            },
            $mainEl: {
                read: function () {
                    return this.$displayContainer() || this.widget().element;
                },
                deferEvaluation: true
            },
            filenameAndSize: {
                read: function () {
                    var filesize =
                        this.fileSize() ?
                        ' (' + bytesToMb(this.fileSize()).toFixed(2) + 'MB)' :
                        '';
                    return this.filename() ? this.filename() +
                         filesize : '';
                },
                deferEvaluation: true
            },
            isFileUploaded: function () {
                return this.error() == null && !this.uploadRequest();
            },
            type: function () {
                if (this.file()) {
                    return _.first(this.file().contentType.split('/'));
                }
            }
        },
        subscribe: {
            data: function (value, parent) {
                if (!value) {
                    parent.file(null);
                }
            }
        }
    };

    $.widget('msgme.msgme_file_uploader', ThreeWidget, {
        _template: template,

        _create: function () {
            this._mapping = mapping;
            ThreeWidget.prototype._create.apply(this, arguments);

            var vm = this.option('viewmodel');
            vm.widget(this);

            // this is heinous. we should look into auto-transferring matching
            // values from this.options.* to this.option.viewmodel
            vm.uploadButtonClasses(
                _.extend({btn: true, browse: true},
                    this.option('uploadButtonClasses')));
            vm.browseText(this.option('browseText'));
            vm.accept(this.option('accept'));
            vm.maxFileSize(this.option('maxFileSize'));
            vm.data.subscribe(_.bind(this._onDataChange, this));
            vm.$displayContainer(this.option('$displayContainer'));
            if (_.has(this.options, 'thumbnail')) {
                vm.thumbnail(this.option('thumbnail'));
            }
            if (_.has(this.options, 'filePreview')) {
                vm.filePreview(this.option('filePreview'));
            }

            if (vm.$displayContainer()) { this._prepareDisplayContainer(); }

            if (vm.data()) { this._populateFileAttributes();}

            this.on('click', '.browse', '_onClickBrowse');
            vm.$mainEl().on('click', '.remove',
                _.bind(this._onClickRemove, this));
            vm.$mainEl().on('change', '[type=file]',
                _.bind(this._onChangeFileInput, this));
            vm.$mainEl().on('click', '.file-preview-launcher',
                _.bind(this._toggleFilePreviewModal, this));
            vm.$mainEl().on('hide', '.file-preview.modal',
                _.bind(this._resetAttachments, this));
        },

        _onChangeFileInput: function (evt) {
            var vm = this.option('viewmodel');
            var file = evt.target.files[0];
            var isGif = file.type === 'image/gif';
            var maxFileSize = isGif ? 681574 : vm.maxFileSize();
            var maxFileSizeError = _.sprintf(strings.errors.maxFileSize,
                {maxSizeMBs: (bytesToMb(maxFileSize)).toFixed(2)});

            evt.stopPropagation();
            if (file && vm.immediateUpload()) {
                if (maxFileSize && file.size > maxFileSize) {
                    alert.error(maxFileSizeError);
                } else {
                    vm.fileSize(file.size);
                    this.submit();
                }
            }
        },

        _onClickBrowse: function () {
            var vm = this.option('viewmodel');

            vm.$mainEl().find('[name=file]').trigger('click');
        },

        _onClickRemove: function () {
            this.option('viewmodel').data(null);
            this.option('viewmodel').$mainEl().find('form').trigger('reset');
        },

        _toggleFilePreviewModal: function () {
            var vm = this.option('viewmodel');
            vm.$mainEl().find('.file-preview.modal').modal('toggle');
        },

        _resetAttachments: function () {
            var vm = this.option('viewmodel');
            var $video = _.first(vm.$mainEl().find('video'));
            var $audio = _.first(vm.$mainEl().find('audio'));
            $video.pause();
            $audio.pause();
        },

        _onDataChange: function (value) {
            this.element.trigger('change', value);
        },

        _populateFileAttributes: function () {
            var vm = this.option('viewmodel');

            api.call('fileV2.fetch', vm.data() + '/details').
                done(function (file) {
                    vm.file(file);
                });
        },

        _prepareDisplayContainer: function () {
            var vm = this.option('viewmodel');
            var $displayContainer = vm.$displayContainer();
            var $fileUploader = this.element.find('.file-uploader');
            var $uploadButton = this.element.find('.browse');

            $displayContainer.addClass('file-uploader-display-container');
            $fileUploader.contents().appendTo($displayContainer);
            $uploadButton.appendTo($fileUploader);
        },

        submit: function () {
            var dfd = $.Deferred();
            var vm = this.option('viewmodel');
            var filename = basename(vm.$mainEl().find('[name=file]').val());
            this.option('viewmodel').error(null);
            this.option('viewmodel').uploadRequest(dfd);
            vm.$mainEl().find('form').ajaxSubmit({
                data: {
                    visibility: 'PUBLIC',
                    fileName: filename
                },
                uploadProgress: _.bind(function (e, pos, total, percent) {
                    this.option('viewmodel').uploadPercent(percent);
                }, this),
                success: _.bind(function(file) {
                    this.option('viewmodel').file(file);
                    this.option('viewmodel').data(file.id);
                    this.option('viewmodel').uploadRequest(null);
                    this.option('viewmodel').uploadPercent(null);
                    dfd.resolve(file);
                }, this),
                error: _.bind(function(jqXHR) {
                    this.option('viewmodel').data(null);
                    this.option('viewmodel').error(jqXHR.status);
                    vm.$mainEl().find('form').trigger('reset');
                    this.option('viewmodel').uploadRequest(null);
                    this.option('viewmodel').uploadPercent(null);
                    dfd.reject(jqXHR);
                }, this)
            });

            return dfd;
        }
    });

    return {
        widget: $.msgme.msgme_file_uploader,
        mapping: mapping
    };
});

