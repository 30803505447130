define('widgets/mobile-message-body/index',[
    'msgme/underscore',
    'msgme/ko',
    'msgme/viewmodel',
    'msgme/util/is-mms-enabled',
    'msgme/util/account-features',
    'msgme/util/api',
    './../three-widget',
    'text!./template.html',
    'json!./strings.json',
    'msgme/util/feature-flags',
    'json!widgets/shared-strings.json',
    'widgets/toolbar/index'
], function(_,
    ko,
    viewmodel,
    isMmsEnabled,
    accountFeatures,
    api,
    ThreeWidget,
    template,
    strings,
    featureFlags,
    sharedStrings
) {

    function safeMaxSmsLength() {
        var shortcodeSession = viewmodel.globals.shortcodes.session();
        var maxSmsLengthSpecified =
            _.has(shortcodeSession, 'maxSmsLength') &&
            !_.isNull(shortcodeSession.maxSmsLength);
        return maxSmsLengthSpecified ?
            shortcodeSession.maxSmsLength : 160;
    }

    var passedObservables = {
        message: '',
        subject: '',
        fileIds: [],
        isDisabled: false,
        currentTab: 'sms',
        accountFeaturesKey: null,
        ad: null,
        sponsorship: null,
        enableAds: false,
        tagMetadata: false,
        metadata: null,
        value: null,
        smsFallback: null,
        enableSMSFallback: true
    };

    var internalObservables = {
        imageFileId: null,
        videoFileId: null,
        audioFileId: null,
        vcfFileId: null,
        imageFileUploadRequest: null,
        videoFileUploadRequest: null,
        audioFileUploadRequest: null,
        vcfFileUploadRequest: null,
        mmsMaxNumChars: 500,
        displayFallback: false
    };

    var allObservables = _.extend({}, passedObservables, internalObservables);

    var fixedMessageMapping =
        accountFeatures.fixedMessageMapping('message', 'accountFeaturesKey');

    var mapping = $.extend(true, {
        defaults: _.clone(allObservables),

        computed: {
            isMmsEnabled: isMmsEnabled,

            isImageFileUploading: function () {
                return !!this.imageFileUploadRequest();
            },

            isVideoFileUploading: function () {
                return !!this.videoFileUploadRequest();
            },

            isImageFileUploaded: function () {
                return !_.isNull(this.imageFileId());
            },

            isVideoFileUploaded: function () {
                return !_.isNull(this.videoFileId());
            },

            isAudioFileUploaded: function () {
                return !_.isNull(this.audioFileId());
            },

            isAudioFileUploading: function () {
                return !!this.audioFileUploadRequest();
            },
            
            isVcfFileUploaded: function () {
                return !_.isNull(this.vcfFileId());
            },

            isVcfFileUploading: function () {
                return !!this.vcfFileUploadRequest();
            },

            isImageFileUploadedOrInProgress: function () {
                return this.isImageFileUploading() ||
                    this.isImageFileUploaded();
            },

            isVideoFileUploadedOrInProgress: function () {
                return this.isVideoFileUploading() ||
                    this.isVideoFileUploaded();
            },

            isAudioFileUploadedOrInProgress: function () {
                return this.isAudioFileUploading() ||
                    this.isAudioFileUploaded();
            },

            isVcfFileUploadedOrInProgress: function () {
                return this.isVcfFileUploading() ||
                    this.isVcfFileUploaded();
            },

            isSubstituteMessage: function () {
                return accountFeatures.isSubstituteMessage(
                    this.accountFeaturesKey());
            },

            isButtonDisabled: function () {
                return this.isDisabled() || this.isSubstituteMessage() ||
                    !this.isMmsEnabled();
            },

            isMMSButtonDisabled: function () {
                return this.isVideoFileUploadedOrInProgress() ||
                    this.isImageFileUploadedOrInProgress() ||
                    this.isAudioFileUploadedOrInProgress() ||
                    this.isVcfFileUploadedOrInProgress();
            },

            hasSmsFallback: {
                read: function () {
                    return this.displayFallback() || this.smsFallback();
                },
                write: function (parent, val) {
                    this.displayFallback(val);
                    if (val) {
                        if (this.subject()) {
                            this.smsFallback(this.subject());
                        } else {
                            this.smsFallback(null);
                        }
                    } else {
                        this.smsFallback(null);
                    }
                }
            },

            isTextareaDisabled: {
                read: function () {
                    return this.isDisabled() || this.isSubstituteMessage();
                },

                deferEvaluation: true
            },

            messageBodyClasses: {
                read: function (root) {
                    var prefix = root.messagePrefix();
                    var postfix = root.messagePostfix();
                    var position = '';

                    if (prefix) {
                        position = ' has-prefix';
                    } else if (postfix) {
                        position = ' has-postfix';
                    }

                    return 'message' + position;
                },

                deferEvaluation: true
            },

            mmsUpsellInfoText: function () {
                return _.sprintf(strings.mmsUpsell.info,
                    {mmsMaxNumChars: this.mmsMaxNumChars()});
            },

            mmsUpsellCallToActionMarkup: function () {
                var mailToLinkFormat =
                    '<a href="mailto:%(contactEmail)s">%(contactEmail)s</a>';
                var mailToLink = _.sprintf(mailToLinkFormat,
                    {contactEmail: strings.mmsUpsell.contactEmail});
                return _.sprintf(strings.mmsUpsell.callToAction,
                    {mailToLink: mailToLink});
            },

            messageBodyHumanReadable: {
                read: function () {
                    var dynamicInsertionRegexRaw = new RegExp(
                        '\\{subscriber\\.metadata\\.\\("(.*?)"\\)(' +
                        '; default=".*?")?(; format=".*?")?\\}', 'g');
                    var couponRegexRaw = new RegExp(
                        '\\{coupon\\.\\("(.*?)"\\)(' +
                        '; default=".*?")?(; format=".*?")?\\}', 'g');
                    var messageBody = this.messageBody();

                    if (messageBody) {
                        messageBody = messageBody.replace(couponRegexRaw,
                            function (match, couponId) {
                            var coupons = viewmodel.globals.coupons();
                            var coupon = _.find(coupons, function (coupon) {
                                return coupon.id === couponId;
                            });

                            if (!coupon) {
                                return match;
                            }

                            return '{coupon:' + coupon.name + '}';
                        });
                    }

                    return messageBody && messageBody.replace(
                        dynamicInsertionRegexRaw,
                        function(match, metadatumId, defaultTextToken,
                        formatTextToken) {

                        defaultTextToken = defaultTextToken || '';
                        formatTextToken = formatTextToken || '';

                        var metadata = viewmodel.globals.metadata();
                        var metadatum = _.find(metadata, function(metadatum) {
                            return metadatum.id === metadatumId;
                        });

                        if (!metadatum) {
                            return match;
                        }

                        var sameNameMetadata = _.filter(metadata, function(m) {
                            return m.name === metadatum.name;
                        });
                        var metadatumIndex = _.indexOf(
                            _.pluck(sameNameMetadata, 'id'), metadatum.id);
                        var metadatumIndexToken = metadatumIndex > 0 ?
                            ' (' + metadatumIndex + ')' : '';

                        return '{' + metadatum.name + metadatumIndexToken +
                            defaultTextToken + formatTextToken + '}';
                    });
                },

                write: function (root, value) {
                    var dynamicInsertionRegexHuman = new RegExp(
                        '\\{(.*?)( \\((d+)\\))?(; default=".*?")?' +
                        '(; format=".*?")?\\}', 'g');

                    this.messageBody(value.replace(dynamicInsertionRegexHuman,
                        function(match, metadatumName, metadatumIndexToken,
                            metadatumIndexString, defaultTextToken,
                            formatTextToken) {
                        var couponName;
                        var couponRegex = new RegExp(
                            'coupon\\:.*?', 'g');

                        defaultTextToken = defaultTextToken || '';
                        formatTextToken = formatTextToken || '';

                        if (metadatumName.match(couponRegex)) {
                            couponName = metadatumName.split(':')[1];
                        }

                        var coupon = _.find(viewmodel.globals.coupons(),
                            function(coupon) {
                                return coupon.name === couponName;
                            });

                        if (coupon) {
                            return '{coupon.("' + coupon.id + '")}';
                        }

                        var metadatumIndex =
                            metadatumIndexToken ? +metadatumIndexString : 0;
                        var metadatum = _.filter(viewmodel.globals.metadata(),
                            function(metadatum) {
                            return metadatum.name === metadatumName;
                        })[metadatumIndex];

                        if (!metadatum) { return match; }

                        return '{subscriber.metadata.("' + metadatum.id + '")' +
                            defaultTextToken + formatTextToken + '}';
                    }));
                },

                deferEvaluation: true
            },

            isAdExpired: function () {
                if (sharedStrings.deployTarget === 'marketron' &&
                   msgme.auth.has('ad:read:*')) {
                    return viewmodel.globals.expiredAds.oneById(this.ad());
                } else {
                    return false;
                }
            },

            adCopy: function () {
                if (sharedStrings.deployTarget === 'marketron' &&
                   msgme.auth.has('ad:read:*')) {
                    if (viewmodel.globals.ads.oneById(this.ad())) {
                        return viewmodel.globals.ads.oneById(this.ad()).adCopy;
                    } else {
                        if (this.isAdExpired()) {
                            return viewmodel.globals.expiredAds.
                                oneById(this.ad()).adCopy;
                        }
                    }
                }
            },

            isSponsorshipExpired: function () {
                if (sharedStrings.deployTarget === 'marketron' &&
                   msgme.auth.has('sponsorship:read:*')) {
                    return viewmodel.globals.expiredSponsorships.
                        oneById(this.sponsorship());
                } else {
                    return false;
                }
            },

            sponsorshipName: function () {
                if (sharedStrings.deployTarget === 'marketron' &&
                   msgme.auth.has('sponsorship:read:*')) {
                    if (viewmodel.globals.sponsorships.
                        oneById(this.sponsorship())) {
                        return viewmodel.globals.sponsorships.
                            oneById(this.sponsorship()).name;
                    } else {
                        if (this.isSponsorshipExpired()) {
                            return viewmodel.globals.expiredSponsorships.
                                oneById(this.sponsorship()).name;
                        }
                    }
                }
            },

            adCopyLength: function () {
                if (this.adCopy() && !this.isAdExpired()) {
                    return this.adCopy().length;
                } else {
                    return 0;
                }
            },

            metadataName: function () {
                if (this.metadata() && viewmodel.globals.metadata.
                    oneById(this.metadata())){
                    return viewmodel.globals.metadata.
                        oneById(this.metadata()).name;
                }
            },

            subjectLength: function () {
                if (this.subject()) {
                    return this.subject().length;
                }
            },

            smsFallbackCount: function () {
                if (this.smsFallback()) {
                    return this.smsFallback().length;
                }
            }
        },
        validation: {
            subject: {
                validator: function (val) {
                    if (this.parent.currentTab() === 'mms') {
                        return this.parent.smsFallback() ||
                            !!val && val.length <= 40;
                    } else {
                        return true;
                    }
                },

                message: 'Please enter a subject'
            },

            smsFallback: {
                validator: function (val) {
                    if (this.parent.currentTab() === 'mms') {
                        return this.parent.subject() || !!val &&
                            val.length <= 110;
                    } else {
                        return true;
                    }
                },

                message: 'Please enter a fallback message'
            },

            message: {
                validator: function (val) {
                    var tab = this.parent.currentTab();
                    var files;

                    if (tab === 'sms') {
                        return true;
                    } else {
                        files = this.parent.fileIds();

                        return val || (files && files.length);
                    }
                }
            }
        }
    }, fixedMessageMapping);

    $.widget('msgme.msgme_mobile_message_body', ThreeWidget, {
        _mapping: mapping,

        _template: template,

        _create: function() {
            ThreeWidget.prototype._create.apply(this, arguments);

            this._setCorrectTab();
            this._prepareFixedMessageInitialState();
            this._initToolbars();
            this._uniquifyBootstrapTabAttrs();
            this._updateFileIdsOnFileIdChanges();
            this._syncBootstrapToCurrentTabObservable();
            this._syncMessageBodyModifiedToMessage();
            this._syncMessageBodyDisabled();

            // force update of the message observable so that the cursor
            // doesn't jump to the end upon editing an existing message
            // because of the write portion of the humanReadableDI.
            this.option('viewmodel').message.valueHasMutated();

            this.on('click', '.remove-ad', '_removeAd');
            this.on('click', '.remove-sponsorship', '_removeSponsorship');
            this.on('show', 'a[data-toggle="tab"]', '_resetObservables');
            this.on('shown', 'a[data-toggle="tab"]',
                '_syncCurrentTabObservableToBootstrap');
        },

        _setCorrectTab: function () {
            var vm = this.option('viewmodel');

            if (vm.subject() || vm.fileIds().length > 0 || vm.smsFallback()) {
                vm.currentTab('mms');
            } else {
                vm.currentTab('sms');
            }
        },

        _prepareFixedMessageInitialState: function () {
            var vm = this.option('viewmodel');
            vm.messageBody(vm.messageBody());
        },

        _initToolbars: function () {
            this._initSmsToolbar();
            this._initMmsToolbar();
        },

        _initSmsToolbar: function () {
            var vm = this.option('viewmodel');
            var toolbarOptions = {
                characterCounter: {
                    model: {
                        message: vm.messageBody,
                        maxNumChars: ko.computed(function() {
                            return accountFeatures.
                                getMaxLength(vm.accountFeaturesKey(),
                                    safeMaxSmsLength());
                        }),
                        adCopyLength: vm.adCopyLength
                    }
                }
            };

            if (vm.enableAds() &&
                sharedStrings.deployTarget === 'marketron' &&
                msgme.auth.has('ad:read:*') &&
                msgme.auth.has('sponsorship:read:*')) {
                toolbarOptions.adsSponsorships = {
                    model: {
                        ad: vm.ad,
                        sponsorship: vm.sponsorship
                    }
                };
            }

            if (vm.tagMetadata()) {
                toolbarOptions.tagMetadata = {
                    model: {
                        metadata: vm.metadata,
                        value: vm.value
                    }
                };
            }

            featureFlags('dynamicInsertion').
                then(function () {
                    var vm = this.option('viewmodel');

                    this.element.find('.sms-pane .toolbar-container').
                        msgme_toolbar(_.extend({}, toolbarOptions, {
                            dynamicInsertion: {
                                model: {
                                    message: vm.messageBody
                                }
                            }
                        }));
                }.bind(this)).
                fail(function () {
                    this.element.find('.sms-pane .toolbar-container').
                        msgme_toolbar(toolbarOptions);
                }.bind(this));
        },

        _syncFileIdObservablesToFileIds: function () {
            var vm = this.options.viewmodel;

            var fileId = vm.fileIds().length > 0 ? vm.fileIds()[0] : null;
            if (_.isNull(fileId)) { return $.Deferred().resolve(); }

            function updateFileIdObservables(file) {
                var fileType = _.first(file.contentType.split('/'));

                if (fileType === 'image') { vm.imageFileId(file.id); }
                if (fileType === 'video') { vm.videoFileId(file.id); }
                if (fileType === 'audio') { vm.audioFileId(file.id); }
                // for vcf file types
                if (fileType === 'text') {
                    vm.vcfFileId(file.id);
                }
            }

            return api.call('fileV2.fetch', fileId + '/details').
                then(updateFileIdObservables).
                fail(api.getRequestFailureFn(null, 'fileV2.fetch', fileId));
        },

        _initMmsToolbar: function () {
            function mbToBytes(mb) { return mb * 1024 * 1024; }

            var vm = this.options.viewmodel;
            var $imageFileUploaderDisplayContainer =this.element.find(
                '.mms-pane .image-file-uploader-display-container');
            var $videoFileUploaderDisplayContainer = this.element.find(
                '.mms-pane .video-file-uploader-display-container');
            var $audioFileUploaderDisplayContainer = this.element.find(
                '.mms-pane .audio-file-uploader-display-container');
            var $vcfFileUploaderDisplayContainer = this.element.find(
                '.mms-pane .vcf-file-uploader-display-container');

            var toolbarOptions = {
                imageFileUploader: {
                    data: vm.imageFileId,
                    uploadButtonClasses: {btn: false, 'fa-picture-o': true},
                    browseText: '',
                    accept: 'image/gif,image/jpeg,image/png,text/x-vcard',
                    maxFileSize: mbToBytes(3),
                    $displayContainer: $imageFileUploaderDisplayContainer,
                    thumbnail: true,
                    filePreview: true,
                    multipleContentTypes: true,
                    model: {
                        uploadRequest: vm.imageFileUploadRequest,
                        isDisabled: vm.isButtonDisabled,
                        isMMSDisabled: vm.isMMSButtonDisabled
                    }
                },

                videoFileUploader: {
                    data: vm.videoFileId,
                    uploadButtonClasses: {btn: false, 'fa-film': true},
                    browseText: '',
                    accept: 'video/3gpp,video/mp4,video/mpeg,video/mpg,' +
                        'video/avi,video/x-ms-wmv,video/quicktime',
                    maxFileSize: mbToBytes(100),
                    $displayContainer: $videoFileUploaderDisplayContainer,
                    thumbnail: true,
                    filePreview: true,
                    multipleContentTypes: true,
                    model: {
                        uploadRequest: vm.videoFileUploadRequest,
                        isDisabled: vm.isButtonDisabled,
                        isMMSDisabled: vm.isMMSButtonDisabled
                    }
                },

                audioFileUploader: {
                    data: vm.audioFileId,
                    uploadButtonClasses: {btn: false, 'fa-music': true},
                    browseText: '',
                    accept: 'audio/mp3,audio/wav',
                    maxFileSize: mbToBytes(10),
                    $displayContainer: $audioFileUploaderDisplayContainer,
                    thumbnail: true,
                    filePreview: true,
                    multipleContentTypes: true,
                    model: {
                        uploadRequest: vm.audioFileUploadRequest,
                        isDisabled: vm.isButtonDisabled,
                        isMMSDisabled: vm.isMMSButtonDisabled
                    }
                },

                vcfFileUploader: {
                    data: vm.vcfFileId,
                    uploadButtonClasses: {btn: false, 'fa-address-card-o':
                        true},
                    browseText: '',
                    accept: 'text/x-vcard',
                    maxFileSize: mbToBytes(3),
                    $displayContainer: $vcfFileUploaderDisplayContainer,
                    thumbnail: true,
                    filePreview: true,
                    multipleContentTypes: true,
                    model: {
                        uploadRequest: vm.vcfFileUploadRequest,
                        isDisabled: vm.isButtonDisabled,
                        isMMSDisabled: vm.isMMSButtonDisabled
                    }
                },

                characterCounter: {
                    model: {
                        message: vm.messageBody,
                        maxNumChars: ko.computed(function() {
                            return accountFeatures.
                                getMaxLength(vm.accountFeaturesKey(),
                                    vm.mmsMaxNumChars());
                        }),
                        adCopyLength: vm.adCopyLength
                    }
                }
            };

            if (vm.enableAds() &&
                sharedStrings.deployTarget === 'marketron' &&
                msgme.auth.has('ad:read:*') &&
                msgme.auth.has('sponsorship:read:*')) {
                toolbarOptions.adsSponsorships = {
                    model: {
                        ad: vm.ad,
                        sponsorship: vm.sponsorship,
                        isDisabled: vm.isButtonDisabled
                    }
                };
            }

            if (vm.tagMetadata()) {
                toolbarOptions.tagMetadata = {
                    model: {
                        metadata: vm.metadata,
                        value: vm.value,
                        isDisabled: vm.isButtonDisabled
                    }
                };
            }

            function initToolbar() {
                featureFlags('dynamicInsertion').
                    then(function () {
                        var vm = this.option('viewmodel');

                        this.element.find('.mms-pane .toolbar-container').
                            msgme_toolbar(_.extend({}, toolbarOptions, {
                                dynamicInsertion: {
                                    model: {
                                        message: vm.messageBody,
                                        isDisabled: vm.isButtonDisabled
                                    }
                                }
                            }));
                    }.bind(this)).
                    fail(function () {
                        this.element.find('.mms-pane .toolbar-container').
                            msgme_toolbar(toolbarOptions);
                    }.bind(this));
            }

            this._syncFileIdObservablesToFileIds().
                done(_.bind(initToolbar, this));
        },

        // Bootstrap requires ids for each tab. We need to make each id
        // unique across widget instances.
        _uniquifyBootstrapTabAttrs: function () {
            var widgetId = this.element.attr('data-msgme-widget-id');

            this.element.find('a[data-toggle="tab"]').each(function (index, a) {
                var base = $(a).parent().is('[data-tab=mms]') ? 'mms' : 'sms';
                var newId = '#' + base + '-' + widgetId;
                $(a).attr('href', newId);
            });

            this.element.find('.tab-pane').each(function (index, tabPane) {
                var base = $(tabPane).hasClass('mms-pane') ? 'mms' : 'sms';
                var newId = base + '-' + widgetId;
                $(tabPane).attr('id', newId);
            });
        },

        _resetObservables: function () {
            var vm = this.option('viewmodel');
            var observablesToReset = _.omit(allObservables, 'isDisabled',
                    'currentTab', 'accountFeaturesKey', 'enableSMSFallback',
                    'message');

            function resetObservable(defaultVal, observableName) {
                vm[observableName](defaultVal);

                if (_.has(vm[observableName], 'isModified')) {
                    vm[observableName].isModified(false);
                }
            }

            _.each(observablesToReset, resetObservable);
        },

        _syncBootstrapToCurrentTabObservable: function () {
            var vm = this.option('viewmodel');
            var currentTab = vm.currentTab;
            var widget = this;

            currentTab.subscribe(function () {
                var $bootstrapTab = widget.element.find(
                    'li[data-tab="' + currentTab() + '"] a[data-toggle="tab"]');
                $bootstrapTab.tab('show');
            });

            currentTab.valueHasMutated();
        },

        _syncMessageBodyModifiedToMessage: function () {
            var vm = this.option('viewmodel');
            var isModified = vm.message.isModified;

            // if the message observable isn't validated, then we don't need to
            // sync these two up
            if (!isModified) {
                return;
            }

            isModified.subscribe(function () {
                vm.messageBody.isModified(isModified());
            });
        },

        _syncCurrentTabObservableToBootstrap: function () {
            var vm = this.option('viewmodel');
            var bootstrapCurrentTab =
                this.element.find('li.active').data('tab');
            vm.currentTab(bootstrapCurrentTab);
            vm.message.isModified(false);
        },

        _syncMessageBodyDisabled: function () {
            var isTextareaDisabled =
                this.option('viewmodel').isTextareaDisabled;

            isTextareaDisabled.subscribe(_.bind(function (isTextareaDisabled) {
                this.element.find('textarea.message').
                    prop('disabled', isTextareaDisabled);
            }, this));

            this.element.find('textarea.message').
                prop('disabled', isTextareaDisabled());
        },

        _updateFileIdsOnFileIdChanges: function () {
            var vm = this.option('viewmodel');
            var defaultFileIds = _.clone(passedObservables.fileIds);
            var fileIdObservables =
                [vm.imageFileId, vm.videoFileId, vm.audioFileId, vm.vcfFileId];

            _.each(fileIdObservables, function (fileIdObservable) {
                var otherFileIdObservables =
                    _.without(fileIdObservables, fileIdObservable);

                function updateFileIdsObservable(newFileId) {
                    var newFileIds =
                        _.isNull(newFileId) ? defaultFileIds : [newFileId];
                    vm.fileIds(newFileIds);
                    vm.fileIds.isModified(false);
                }

                function getObservableValue(observable) { return observable(); }

                function updateFileIdsObservableUnlessOthers(newFileId) {
                    var otherFileIdValues =
                        _.map(otherFileIdObservables, getObservableValue);

                    if (!_.any(otherFileIdValues)) {
                        updateFileIdsObservable(newFileId);
                    }
                }

                fileIdObservable.subscribe(updateFileIdsObservableUnlessOthers);
            });
        },

        _removeAd: function () {
            this.option('viewmodel').ad(null);
        },

        _removeSponsorship: function () {
            this.option('viewmodel').sponsorship(null);
        }
    });

    return {
        widget: $.msgme.msgme_mobile_message_body,
        mapping: mapping
    };
});

