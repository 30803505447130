define('msgme/viewmodel/flowmodule-collectmetadata',[
], function () {
    var mapping = {
        defaults: {
            params: {
                metadataId: null,

                requestMessage: '',
                requestSubject: '',
                requestFiles: [],
                requestAd: null,
                requestSponsorship: null,
                requestSMSFallback: null,

                responseMessage: '',
                responseSubject: '',
                responseFiles: [],
                responseAd: null,
                responseSponsorship: null,
                responseSMSFallback: null,

                invalidFormatMessage: '',
                invalidFormatSubject: '',
                invalidFormatFiles: [],
                invalidFormatAd: null,
                invalidFormatSponsorship: null,
                invalidFormatSMSFallback: null
            }
        },

        local: {
            title: 'Collect Metadata',

            requestCurrentTab: 'sms',
            responseCurrentTab: 'sms',
            invalidFormatCurrentTab: 'sms'
        },

        params: {
            mapping: {
                validation: {
                    metadataId: {
                        required: true
                    }
                }
            }
        }
    };

    return {
        mapping: mapping
    };
});

