define('msgme/views/passes',[
    'msgme/splash',
    'msgme/path',
    'msgme/underscore',
    'msgme/ko',
    'msgme/util/format',
    'msgme/viewmodel',
    'msgme/viewmodel/pass',
    'msgme/views/View',
    'msgme/widgets/pager',
    'json!widgets/shared-strings.json',
    'msgme/plugins'
],
function (splash, path, _, ko, format, viewmodel, pass, View,
    Pager, sharedStrings
) {
    var view = new View('#passes-view');
    var passes, page, pager;
    var url = sharedStrings.sitemap.passes.url;

    pager = new Pager(pass.collection);

    function onPassesSuccess(result) {
        passes = result.slice();
        _.each(passes, function (p) {
            p.lastUpdatedDate = format.uiDate(p.lastUpdatedDate);
        });
        pass.collection.rows(passes);
        pager.update(result);

        splash.hide();
        $('#passes-view-main').msgme('busy', false);
        $('#passes-view-empty').removeClass('hidden');
    }

    function fetchPasses() {
        page = page && _.isNumber(parseInt(page, 10)) ? page : 1;
        $('#passes-view-main').msgme('busy', true);

        $.when(view.callAPI('passes.fetch', {
                page: page,
                size: pass.collection.pageSize
            }), viewmodel.globals.flows.refresh()).
            done(onPassesSuccess).
            fail(view.getRequestFailureFn(null, 'mobileflows|passes.fetch'));
    }

    $('#passes-view').on('click', 'td i.fa.fa-remove', function () {
        var rowEl = $(this).closest('tr');
        var record = pass.collection.rows()[rowEl.index()];
        var name = record.name;

        msgme.modal.confirm('Are you sure you want to delete pass ' +
            name + '?', {
            title: 'Delete Pass'
        }).done(_.bind(function (value) {
            if (value) {
                rowEl.addClass('deleting');
                record.del().
                    done(function () {
                        fetchPasses();
                        msgme.alert.success('The pass has been deleted');
                    }).
                    fail(function () { rowEl.removeClass('deleting'); }).
                    fail(view.getRequestFailureFn(null,
                        'passes.delete',
                        record.data().id));
            }
        }, this));
    });

    path.map(url + '(/page/:page)').to(function () {
        view.show();
        page = this.params.page;
        fetchPasses();
    });

    return view;
});

