/* vim: set et ts=4 sw=4 sts=4: */
define('msgme/views/subscriber',[
    'msgme/splash',
    'msgme/path',
    'msgme/ko',
    'msgme/viewmodel',
    'msgme/viewmodel/subscriber',
    'msgme/views/View',
    'msgme/widgets/pager',
    'msgme/globals',
    'msgme/underscore',
    'msgme/util/format',
    'json!widgets/shared-strings.json',
    'msgme/widgets/subscriber-profile'
],
function (splash, path, ko, viewmodel, subscriber, View, Pager, globals, _,
    format, sharedStrings
) {
    var view = new View('#subscriber-view');
    var pager;
    var url = sharedStrings.sitemap.subscriber.url;

    var metadataModel = subscriber.model.modals.metadata;

    metadataModel.value.free = ko.computed({
        read: function () {
            if (!metadataModel.validValues()) {
                return metadataModel.value();
            }
        },
        write: function (value) {
            if (!metadataModel.validValues()) {
                metadataModel.value(value);
            }
        }
    });

    metadataModel.value.fixed = ko.computed({
        read: function () {
            if (!!metadataModel.validValues()) {
                return metadataModel.value();
            }
        },
        write: function (value) {
            if (!!metadataModel.validValues()) {
                metadataModel.value(value);
            }
        }
    });
    pager = new Pager(subscriber.model.messages);

    function populateMessages(messages) {
        var model = subscriber.model;

        _.each(messages, function (message) {
            message.date = format.uiDate(message.date);
        });

        model.messages.rows(messages.slice());
        model.messages.url(
            _.sprintf('subscriber/%s/messages',
                waterfall.authenticate.isMasked ?
                    model.id() :
                    format.unformattedPhoneNo(model.mobilePhoneNo())));
        pager.update(messages);
        view.root.find('.spinner-container').hide();
        view.root.find('.body-container').show();
        view.root.find('.messages.body').msgme('busy', false);
    }

    var profileWidgetInitialized = false;
    function populate(page, subscriberInstance) {
        var model = subscriber.model;
        var profileEl = view.root.find('#subscriber-profile');
        var mobileNumberUrl =
            format.unformattedPhoneNo(subscriberInstance.mobileNumber);
        var pageUrl = location.href.indexOf('messages') > -1 ?
            '/messages/' + page : '';

        if (!waterfall.authenticate.isMasked) {
            path.history.replaceState(null, null,
                    url + '/' + mobileNumberUrl + pageUrl);
        }

        model.id(subscriberInstance.id);
        model.mobilePhoneNo(format.phoneNo(subscriberInstance.mobileNumber));
        model.carrier(subscriberInstance.carrier);
        model.blacklist(_.chain(subscriberInstance.blacklist)
            .map(function (id) {
                if (viewmodel.globals.shortcodes.oneById(id)) {
                    return viewmodel.globals.shortcodes.oneById(id).shortcode;
                }
            }).
            compact().
            value().
            join(', '));

        model.metadata(
            _.filter(_.map(subscriberInstance.metadata,
                function (metadatum, id) {
                    if (_.isArray(metadatum)) {
                        return {
                            id: id,
                            name: (viewmodel.globals.metadata.byId(id) || {}).
                                name,
                            values: metadatum
                        };
                    }
                }), function (val) { return val; }));

        model.lists(
            _.map(subscriberInstance.lists, function (list, id) {
                var listRecord = viewmodel.globals.lists.byId(id) || {};
                return {
                    id: id,
                    name: listRecord.name,
                    url: format.urlString(listRecord.id),
                    dateJoined: format.uiDate(list.joinDate)
                };
            }));

        model.record = subscriberInstance;

        if (!profileWidgetInitialized) {
            profileEl.msgme_subscriber_profile({
                data: ko.observable(subscriber.model)
            });
            view.root.find('ul.nav.nav-tabs li a.details-tab').tab('show');
            profileWidgetInitialized = true;
        } else {
            profileEl.msgme_subscriber_profile('hideModals');
        }

        subscriber.model.messages.rows([]);
        subscriberInstance.messages({ page: page, size: 15 }).
            done(populateMessages).
            fail(_.bind(onFailure, this, 'fetch messages for subscriber'));
    }

    function onFailure(what, e) {
        console.error('Failed to', what + ': ', e);
    }

    function getRequestFailure(id) {
        return function (jqXHR, status, err) {
            if (jqXHR.status === 404) {
                $('#subscriber-view-header').addClass('hidden');
                $('#subscriber-view-body').addClass('hidden');
                $('#subscriber-view-error').removeClass('hidden');
            } else {
                view.getRequestFailureFn(
                    null, 'subscriber.fetch', id)(jqXHR, status, err);
            }
        };
    }

    function fetch(id, page) {
        $.when(waterfall.subscribers.fetch(id),
                viewmodel.globals.lists.refresh(),
                viewmodel.globals.metadata.refresh()
            ).
            done(function (result) {
                splash.hide();
                populate(page, result);
                $('#subscriber-view-header').removeClass('hidden');
                $('#subscriber-view-body').removeClass('hidden');
                $('#subscriber-view-error').addClass('hidden');
            }).fail(getRequestFailure(id));
    }

    view.root.on('subscriber-profile-changed',
        '.msgme-subscriber-profile',
        function (e, data) {
            fetch(data.id, 1);
        });

    path.map('/subscribers/:id(/messages/:page)').to(function () {
        if (this.params.id) {
            var page = this.params.page ? '/messages/' + this.params.page : '';

            path.history.replaceState(null, null,
                url + '/' + this.params.id + page);
        } else {
            path.history.replaceState(null, null, url);
        }
    });

    path.map(url + '/:id(/messages/:page)').to(function () {
        view.show();
        if (this.params.page) {
            view.root.find('.messages.body').msgme('busy', true);
        } else {
            view.root.find('.spinner-container').show();
            view.root.find('.body-container').hide();
        }
        fetch(this.params.id, this.params.page || 1);
    });

    return view;
});



