define('widgets/character-counter/index',[
    'msgme/underscore',
    'msgme/ko',
    'msgme/viewmodel',
    './../three-widget',
    'json!./strings.json',
    'text!./template.html'
], function (_, ko, viewmodel, ThreeWidget, strings, template) {

    var requiredOptions = {maxNumChars: null, message: null};
    var optionalOptions = {adCopyLength: null};
    var acceptedOptions = _.extend({}, requiredOptions, optionalOptions);
    var dynamicInsertionRe = /{[^}]+}/;
    var doubleLengthCharRe = /([_{}[\]|€~\\])/g;
    var mapping = {
        defaults: _.clone(acceptedOptions),

        computed: {
            hasDynamicInsertion: function (root) {
                return root.message() &&
                    dynamicInsertionRe.test(root.message());
            },
            doubleLengthChars: function (root) {
                if (root.message() && doubleLengthCharRe.test(root.message())) {
                    return root.message().match(doubleLengthCharRe).length;
                } else {
                    return 0;
                }
            },
            numCharsRemaining: function (root) {
                var length = root.message() ? root.message().length : 0;
                var urlRegex = new RegExp('<URL:(.*?)>', 'gi');
                var diff = 0;

                if (root.message() && root.message().match(urlRegex)) {
                    _.each(root.message().match(urlRegex), function (match) {
                        diff = diff - 7 + match.length;
                    });
                }

                if (root.adCopyLength()) {
                    length = length + root.adCopyLength();
                }

                return root.maxNumChars() - length + diff -
                    root.doubleLengthChars();
            },
            isNegative: function (root) {
                return root.numCharsRemaining() < 0;
            }
        }
    };

    $.widget('msgme.msgme_character_counter', ThreeWidget, {
        _template: template,

        _mapping: mapping,

        _create: function () {
            ThreeWidget.prototype._create.apply(this, arguments);
            this._extractOptions();
        },

        _extractOptions: function () {
            this._checkRequiredOptions();
            this._applyAcceptedOptionsToViewmodel();
        },

        _checkRequiredOptions: function () {
            var passedOptionNames = _.union(
                _.keys(this.options),
                _.keys(this.option('model')));
            var requiredUnpassedOptions =
                _.omit(requiredOptions, passedOptionNames);

            function warnRequiredOptionNotProvided(optionVal, optionName) {
                var warningString = _.sprintf(
                    strings.warning.requiredOptionNotProvided,
                    {option: optionName});

                console.warn(warningString);
            }

            _.each(requiredUnpassedOptions, warnRequiredOptionNotProvided);
        },

        _applyAcceptedOptionsToViewmodel: function () {
            var passedOptions = this.options;
            var acceptedOptionNames = _.keys(acceptedOptions);
            var acceptedPassedOptions =
                _.pick(passedOptions, acceptedOptionNames);

            function applyOptionToViewmodel(optionVal, optionName) {
                var o = passedOptions.viewmodel[optionName];

                if (ko.isWriteableObservable(o)) {
                    passedOptions.viewmodel[optionName](optionVal);
                }
            }

            _.each(acceptedPassedOptions, applyOptionToViewmodel);
        }
    });

    return {
        widget: $.msgme.msgme_character_counter,
        mapping: mapping
    };
});

