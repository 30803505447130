define('msgme/views/reports',[
    'msgme/underscore',
    'lib/path',
    'msgme/splash',
    'msgme/ko',
    'msgme/viewmodel',
    'msgme/viewmodel/mapping',
    'msgme/viewmodel/report',
    'msgme/util/api',
    'msgme/util/feature-flags',
    'msgme/util/format',
    'msgme/views/View',
    'msgme/widgets/pager',
    'msgme/modal',
    'widgets/report-parameters/index',
    'json!widgets/shared-strings.json',
    'json!widgets/create-report/strings.json',
    'widgets/create-report/index',
    'widgets/spinner/index'
],
function (
    _,
    path,
    splash,
    ko,
    viewmodel,
    mapping,
    report,
    api,
    featureFlags,
    format,
    View,
    Pager,
    modal,
    reportParameters,
    sharedStrings,
    createReportStrings
) {
    var view = new View('#reports-view');
    var pager = new Pager(report.collection);
    var cm = new mapping.CursorModel(waterfall.reporting, {
        record: {mapping: report.mapping}
    });
    var page;
    var $createReport;
    var strings = sharedStrings.sitemap.reports;
    var url = new RegExp(strings.url +
        sharedStrings.sitemap.reports.regexExcludeScheduled +
        sharedStrings.sitemap.reports.regexExcludeRecurring +
        strings.reportSubUrl);

    function instantiateCreateReport(data) {
        $createReport = view.root.find('.create-report.modal').modal().
            msgme_create_report({
                data: ko.observable(data),
                model: {withinModal: true}
            }).
            on('cancelled', function () {
                $createReport.modal('hide');
            }).
            on('finished', function () {
                $createReport.modal('hide');

                // navigate to the base reports view. this will:
                // 1. ensure that we're on the page with the new report
                // 2. close the results view if it's open
                path.history.pushState(null, null, strings.url);
                if (page === 1) {
                    fetchReports();
                }
            });
    }

    function fetchReports() {
        page = page && _.isNumber(parseInt(page, 10)) ? page : 1;
        $('#reports-view-main').msgme('busy', true);

        waterfall.reporting.report.fetch({
            page: page,
            size: report.collection.pageSize(),
            orderBy: '-dateScheduled'
        }).
            done(populate).
            fail(view.getRequestFailureFn(null, 'reporting.fetch'));
    }

    function populate(reportsInstance) {
        var reportStrings = sharedStrings.reports;
        _.each(reportsInstance, function (report) {
            var data = report.data();
            var model = ko.mapping.fromJS({}, reportParameters.mapping);
            var repeatPrefix = report.repeatEvery > 1 ? 'Every ' + report.
                repeatEvery : '';

            report.reportStatus =
                reportStrings.status[report.report.status];
            if (report.report.status === 'COMPLETE') {
                report.date = format.uiDate(report.report.scheduledAt);
            } else if (report.report.status === 'ERROR') {
                report.date = 'Failed';
            } else {
                report.date = 'In progress';
            }
            report.type = createReportStrings.type.options[report.query.type];
            report.url = strings.url + (page > 1 ? '/page/' + page : '') +
                '/' + report.id;
            report.viewable = report.report.status === 'COMPLETE' &&
                report.query.type !== 'listDetails';

            model.data(ko.mapping.fromJS(data, report.mapping));
            report.summary =  model.summary();

            switch (report.repeatInterval) {
            case 'DAILY':
                report.recurringSummary = report.repeatEvery > 1 ?
                repeatPrefix + ' days' : 'Daily';
                return;
            case 'WEEKLY':
                report.recurringSummary = report.repeatEvery > 1 ?
                repeatPrefix + ' weeks' : 'Weekly';
                return;
            case 'MONTHLY':
                report.recurringSummary = report.repeatEvery > 1 ?
                repeatPrefix + ' months' : 'Monthly';
                return;
            default:
                report.recurringSummary = 'Never';
            }
        });

        splash.hide();
        $('#reports-view-main').msgme('busy', false);
        report.collection.rows(reportsInstance);
        pager.update(reportsInstance);
    }

    view.root.on('click', 'i.remove.fa.fa-remove', function () {
        var reportDeleteStrings = sharedStrings.reports['delete'];
        var $this = $(this);
        var entryEl = $this.closest('tr');
        var record = report.collection.rows()[entryEl.index()];
        var name = record.name;

        modal.confirm('Are you sure you want to delete report ' + name + '?', {
            title: reportDeleteStrings.title
        }).done(_.bind(function (value) {
            if (value) {
                record.del().
                    done(function () {
                        fetchReports();
                        msgme.alert.success(reportDeleteStrings.success);
                    }).
                    fail(view.getRequestFailureFn(null,
                        'reporting.delete', record.id));
            }
        }, this));
    });

    function resetShareReportModal() {
        var $modal = view.root.find('.share-report.modal');
        $modal.find('.permalink .spinner').show();
        $modal.find('.permalink input').val('').hide();
        $modal.find('.email .spinner').show();
        $modal.find('.email a').attr('href', '').hide();
        $modal.find('.error').hide();
    }

    function errorShareReportModal() {
        var $modal = view.root.find('.share-report.modal');
        $modal.find('.permalink .spinner').hide();
        $modal.find('.permalink input').show();
        $modal.find('.email .spinner').hide();
        $modal.find('.email a').hide();
        $modal.find('.error').show();
    }

    function successShareReportModal(reportFileUrl) {
        var $modal = view.root.find('.share-report.modal');
        $modal.find('.permalink .spinner').hide();
        $modal.find('.permalink input').val(reportFileUrl).show();
        $modal.find('.email .spinner').hide();
        $modal.find('.email a')
            .attr('href', 'mailto:?body=' + encodeURI(reportFileUrl)).show();
        $modal.find('.error').hide();
    }

    function showAndPopulateShareReportModal(event) {
        var $shareReportIcon = $(event.target);
        var reportId = $shareReportIcon.data('reportId');
        var $modal = view.root.find('.share-report.modal');

        $modal.modal('show');
        resetShareReportModal();

        function isReport(report) { return report.id === reportId; }
        var fileId = _.find(report.collection.rows(), isReport).report.file;

        api.call('fileV2.fetch', fileId + '/details').
            done(function (file) {
                if (file.url == null) {
                    errorShareReportModal();
                } else {
                    successShareReportModal(file.url);
                }
            }).
            fail(errorShareReportModal);
    }

    view.root.on('click', 'i.share-report', showAndPopulateShareReportModal);

    view.root.on('focus', '.share-report.modal .permalink input', function () {
        $(this).select();

        // Address WebKit bug that causes text to be de-selected on mouse-up:
        // https://code.google.com/p/chromium/issues/detail?id=4505
        $(this).one('mouseup', function (event) {
            event.preventDefault();
        });
    });

    view.root.on('click', '.add', function () {
        var data = cm.create();

        if (!$createReport) {
            instantiateCreateReport(data);
        } else {
            $createReport.msgme_create_report('option', 'viewmodel').data(data);
            view.root.find('.create-report.modal').modal('show');
        }

        // this sucks, i don't know why, i think b/c of the modal animation, we
        // have to set a timeout before focusing the first select box
        setTimeout(function () {
            $createReport.find('select:first').focus();
        }, 500);
    });

    path.map(url).to(function (pth, reportPage) {
        page = reportPage;
        view.show();
        fetchReports();
    });

    return view;
});

