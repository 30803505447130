define('msgme/views/scheduled-reports',[
    'msgme/underscore',
    'msgme/ko',
    'msgme/path',
    'msgme/splash',
    'msgme/views/View',
    'msgme/viewmodel',
    'msgme/viewmodel/mapping',
    'msgme/viewmodel/report',
    'msgme/viewmodel/scheduled-reports',
    'msgme/widgets/pager',
    'msgme/modal',
    'widgets/report-parameters/index',
    'msgme/util/feature-flags',
    'msgme/util/format',
    'json!widgets/shared-strings.json',
    'json!widgets/create-report/strings.json'
], function (
    _,
    ko,
    path,
    splash,
    View,
    viewmodel,
    mapping,
    report,
    scheduledReports,
    Pager,
    modal,
    reportParameters,
    featureFlags,
    format,
    sharedStrings,
    createReportStrings
) {
    var view = new View('#scheduled-reports-view');
    var url = sharedStrings.sitemap.reports.url +
        sharedStrings.sitemap.reports.scheduled;
    var pager = new Pager(scheduledReports.collection);
    var strings = sharedStrings.sitemap.reports;
    var page;

    function fetchReports() {
        page = page && _.isNumber(parseInt(page, 10)) ? page : 1;
        $('#scheduled-reports-view-main').msgme('busy', true);

        view.callAPI('reporting.fetch', {
                page: page,
                size: scheduledReports.collection.pageSize(),
                orderBy: 'dateScheduled',
                runAtGt: Date.create().format(Date.ISO8601_DATETIME),
                type: 'NONRECURRING'
            }).
                done(populate).
                fail(view.getRequestFailureFn(null, 'reporting.fetch'));
    }

    function populate(reportsInstance) {
        _.each(reportsInstance, function (report) {
            var data = report.data();
            var model = ko.mapping.fromJS({}, reportParameters.mapping);

            report.date = format.uiDate(report.lastReport.scheduledAt);
            report.type = createReportStrings.type.options[report.query.type];
            report.url = strings.url + (page > 1 ? '/page/' + page : '') +
                '/' + report.id;
            report.viewable = report.lastReport.status === 'COMPLETE' &&
                report.query.type !== 'listDetails';
            model.data(ko.mapping.fromJS(data, report.mapping));
            report.summary =  model.summary();
        });

        splash.hide();
        view.root.find('#scheduled-reports-view-main').msgme('busy', false);
        scheduledReports.collection.rows(reportsInstance);
        pager.update(reportsInstance);
    }

    view.root.on('click', 'i.remove.fa.fa-remove', function () {
        var reportDeleteStrings = sharedStrings.reports['delete'];

        modal.confirm(reportDeleteStrings.confirmation, {
            title: reportDeleteStrings.title
        }).done(_.bind(function (value) {
            if (value) {
                var $this = $(this);
                var entryEl = $this.closest('tr');
                var record =
                    scheduledReports.collection.rows()[entryEl.index()];

                record.del().
                    done(function () {
                        fetchReports();
                        msgme.alert.success(reportDeleteStrings.success);
                    }).
                    fail(view.getRequestFailureFn(null,
                        'reporting.delete', record.id));
            }
        }, this));
    });

    path.map(url + '(/page/:page)').to(function () {
        page = this.params.page ? this.params.page : '';
        view.show();
        fetchReports();
    });

    return view;
});

