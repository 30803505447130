define('msgme/widgets/flowmodule-tagmetadata',[
    'jquery',
    'msgme/ko',
    'msgme/underscore',
    'msgme/widgets/flowmodule',
    'msgme/util/api',
    'msgme/viewmodel/flowmodule-tagmetadata'
],
function ($, ko, _, flowmodule, api, flowmoduleTagMetadata) {
    $.widget('msgme.msgme_flowmodule_tagmetadata', flowmodule, {
        options: {
            widgetEventPrefix: 'flowmodule-tagmetadata-'
        },

        _create: function () {
            flowmodule.prototype._create.apply(this);
            this._updateMetadata(this.options.data().metadataId());

            this.on('change', 'select.field', '_onFieldChange');
        },

        _updateMetadata: function(metadataId) {
            // mark the valid values dropdown busy
            var busyEl = this.element.find('select.value');
            busyEl.msgme('busy', true);

            // save off handle to local observable for validValues
            var validValues = this.options.data().validValues;

            api.call('metadata.fetch', metadataId).
                done(function (metadata) {
                    // clear the busy indicator for the valid values dropdown
                    busyEl.msgme('busy', false);
                    validValues(metadata.validValues);
                });
        },

        _onFieldChange: function () {
            var metadataId = this.options.data().metadataId();

            if (metadataId) {
                this._updateMetadata(metadataId);
            }
        }
    });

    $.msgme.msgme_flowmodule_tagmetadata.Model = flowmodule.modelFactory({}, {
        mapping: flowmoduleTagMetadata.mapping
    });

    return $.msgme.msgme_flowmodule_tagmetadata;
});


