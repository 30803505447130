define('msgme/views/coupons',[
    'msgme/underscore',
    'msgme/splash',
    'msgme/path',
    'msgme/ko',
    'msgme/viewmodel',
    'msgme/viewmodel/coupon',
    'msgme/views/View',
    'msgme/widgets/pager',
    'json!widgets/shared-strings.json',
    'widgets/coupon-modal/index',
    'msgme/plugins'
], function (_, splash, path, ko, viewmodel, couponViewmodel, View, Pager,
    sharedStrings
) {
    var view = new View('#coupons-view');
    var page, pager;
    var url = sharedStrings.sitemap.coupons.url;
    var $couponModalEl = view.root.find('.new-coupon-modal');

    pager = new Pager(couponViewmodel.collection);

    function onCouponsSuccess(coupons) {
        var rows = _.map(coupons.slice(), function (c) {
            var group = viewmodel.globals.groups.oneById(c.group);
            c.groupName = group ? group.name : '';
            c.sent = c.total - c.available;
            c.lastStatus = c.uploads.length ?
                _.last(c.uploads).status : 'PROCESSED';

            return c;
        });

        couponViewmodel.collection.rows(rows);
        pager.update(coupons);

        splash.hide();
        $('#coupons-view-main').msgme('busy', false);
        $('#coupons-view-empty').removeClass('hidden');
    }

    function fetchCoupons() {
        page = page && _.isNumber(parseInt(page, 10)) ? page : 1;
        $('#coupons-view-main').msgme('busy', true);

        $.when(view.callAPI('coupons.fetch', {
                page: page,
                size: couponViewmodel.collection.pageSize
            }).
            done(onCouponsSuccess).
            fail(view.getRequestFailureFn(null, 'coupons.fetch')));
    }

    $('#coupons-view').on('click', 'td i.fa.fa-remove', function () {
        var rowEl = $(this).closest('tr');
        var record =
            couponViewmodel.collection.rows()[rowEl.index()];
        var name = record.name;

        msgme.modal.confirm(
            'Are you sure you want to delete coupon group ' + name + '?', {
                title: 'Delete Coupon Group'
            }).done(_.bind(function (value) {
                if (value) {
                    rowEl.addClass('deleting');
                    record.del().
                        done(function () {
                            fetchCoupons();
                            msgme.alert.success('The coupon has been deleted');
                        }).
                        fail(function () { rowEl.removeClass('deleting'); }).
                        fail(view.getRequestFailureFn(null,
                            'coupons.delete',
                            record.data().id));
                }
            }, this));
    });

    path.map(sharedStrings.sitemap.coupon.url + '/:id').to(function () {
        if(this.params.id.toLowerCase() === 'new') {
            if ($couponModalEl.find('.coupon-modal').length) {
                $couponModalEl.msgme_coupon_modal('show');
            }
        }
    });
    path.map(url + '(/page/:page)').to(function () {
        view.show();
        page = this.params.page;
        fetchCoupons();
    });

    return view;
});

