/* vim: set et ts=4 sw=4 sts=4: */
/* jshint newcap: false */
define('msgme/viewmodel',[
    'msgme/ko',
    'msgme/underscore',
    'msgme/util/format',
    'json!widgets/shared-strings.json'
],
function (ko, _, format, sharedStrings) {
    var NOW_REFRESH_INTERVAL = 60 * 1000;
    var viewmodel = {
        now: ko.observable(new Date()),
        globals: {
            flows: ko.observableArray(),
            groups: ko.observableArray(),
            keywords: ko.observableArray(),
            lists: ko.observableArray(),
            metadata: ko.observableArray(),
            organizations: ko.observableArray(),
            roles: ko.observableArray(),
            shortcodes: ko.observableArray(),
            users: ko.observableArray(),
            coupons: ko.observableArray(),
            passes: ko.observableArray(),
            channels: ko.observableArray(),
            inboxCount: ko.observable(),
            uploads: ko.observable(),
            sweeps: ko.observable(),
            ads: ko.observable(),
            sponsorships: ko.observable(),
            expiredAds: ko.observable(),
            expiredSponsorships: ko.observable(),
            teams: ko.observable(),
            smartlists: ko.observable()
        }
    };

    setInterval(function () {
        viewmodel.now(new Date());
    }, NOW_REFRESH_INTERVAL);

    viewmodel.globals.shortcodes.session = ko.observable({
        shortcode: ''
    });

    function getValue(index) {
        return this[index];
    }

    function getIndex(term) {
        return this[term] || [];
    }

    function getIndexIgnoringCase(term) {
        return this[term.toLowerCase()] || [];
    }

    function search(key, query) {
        query = _.isArray(query) ? query : [query];
        var data = this();
        var result = [];
        var ignoreCase = this._indexable.config.ignoreCase.indexOf(key) > -1;

        if (!data._indices || !data._indices[key]) {
            return [];
        }

        result = _.chain(query).
            map(ignoreCase ?
                getIndexIgnoringCase : getIndex, data._indices[key]).
            flatten().
            filter(_.isNumber).
            map(getValue, this._indexable.copy).
            value();

        return result;
    }

    function searchOne(key, value) {
        var result = search.call(this, key, value).pop();
        return _.isUndefined(result) ? null : result;
    }

    function refresh(force) {
        var source = this._indexable.source;
        var now = Date.now();
        var config = this._indexable.config;
        var lastUpdated = this._indexable.timestamp;
        var d = new $.Deferred();

        if (now - config.cacheDuration > lastUpdated || force === true) {
            // refresh data if our data is old or the caller demands it
            source.fetch({size: -1}).
                done(_.bind(function (result) {
                    var keys = config.keys;
                    var ignoreCase =
                        _.reduce(config.ignoreCase, function (result, item) {
                            result[item] = true;

                            return result;
                        }, {});

                    // index the result
                    // TODO: break this out into an index function
                    result._indices = {};
                    _.each(result, function (record, i) {
                        var key, value, bucket, index;

                        for (var j = 0; j < keys.length; j++) {
                            key = keys[j];

                            value = result[i][key];
                            if (ignoreCase[key]) {
                                value = value.toLowerCase();
                            }

                            index = result._indices[key] =
                                result._indices[key] || {};
                            bucket = index[value];

                            if (_.isUndefined(bucket)) {
                                index[value] = [i];
                            } else {
                                if (_.isArray(bucket)) {
                                    bucket.push(i);
                                }
                            }
                        }
                    });

                    this._indexable.timestamp = Date.now();
                    this._indexable.copy = result.slice();
                    this(result);
                    d.resolve(this());
                }, this)).
                fail(_.bind(function () {
                    d.reject.apply(d, _.toArray(arguments));
                }, this));
        } else {
            // return the cached data
            d.resolve(this());
        }

        return d.promise();
    }

    function Indexable(oa, source, config) {
        config = _.defaults(config || {}, {
            cacheDuration: 1000 * 60, // cache for 1 minute
            keys: ['id'],
            ignoreCase: []
        });

        oa._indexable = {
            source: source,
            config: config,
            timestamp: -1
        };

        _.each(config.keys, function (key) {
            var keyName = _.capitalize(key);
            // TODO: deprecate byFoo
            oa['by' + keyName] = _.bind(searchOne, oa, key);
            oa['oneBy' + keyName] = oa['by' + keyName];
            oa['allBy' + keyName] = _.bind(search, oa, key);
        });

        oa.refresh = refresh;
    }

    viewmodel.Indexable = Indexable;

    Indexable(viewmodel.globals.flows, waterfall.mobileflows, {
        keys: [ 'id', 'campaign', 'name' ]
    });
    viewmodel.globals.flows.refresh();

    Indexable(viewmodel.globals.channels, waterfall.channels, {
        keys: [ 'name' ]
    });
    viewmodel.globals.channels.refresh();

    Indexable(viewmodel.globals.keywords, waterfall.keywords, {
        keys: [ 'name', 'id' ]
    });
    viewmodel.globals.keywords.refresh();

    Indexable(viewmodel.globals.lists, waterfall.lists, {
        keys: [ 'id', 'name' ],
        ignoreCase: [ 'name' ]
    });
    viewmodel.globals.lists.newListOption = ko.observable(null);
    viewmodel.globals.lists.refresh = function refresh(force) {
        var source = this._indexable.source;
        var now = Date.now();
        var config = this._indexable.config;
        var lastUpdated = this._indexable.timestamp;
        var d = new $.Deferred();

        if (now - config.cacheDuration > lastUpdated || force === true) {
            // refresh data if our data is old or the caller demands it
            source.fetch().
                done(_.bind(function (result) {
                    var keys = config.keys;
                    var ignoreCase =
                        _.reduce(config.ignoreCase, function (result, item) {
                            result[item] = true;

                            return result;
                        }, {});
                    var resultClone = [];

                    // index the result
                    // TODO: break this out into an index function
                    result._indices = {};
                    _.each(result, function (record, i) {
                        var key, value, bucket, index;

                        for (var j = 0; j < keys.length; j++) {
                            key = keys[j];

                            value = result[i][key];
                            if (ignoreCase[key]) {
                                value = value.toLowerCase();
                            }

                            index = result._indices[key] =
                                result._indices[key] || {};
                            bucket = index[value];

                            if (_.isUndefined(bucket)) {
                                index[value] = [i];
                            } else {
                                if (_.isArray(bucket)) {
                                    bucket.push(i);
                                }
                            }
                        }
                    });

                    this._indexable.timestamp = Date.now();
                    this._indexable.copy = result.slice();
                    this(result);

                    d.resolve(this());

                    _.each(result, function (record) {
                        resultClone.push(_.clone(record));
                    });

                    resultClone.push({id: 'placeholder', name: '---------'});
                    resultClone.push({id: 'new', name: '+ Create new list'});

                    viewmodel.globals.lists.newListOption(resultClone);
                }, this)).
                fail(_.bind(function () {
                    d.reject.apply(d, _.toArray(arguments));
                }, this));
        } else {
            // return the cached data
            d.resolve(this());
        }

        return d.promise();
    };
    viewmodel.globals.lists.refresh();

    Indexable(viewmodel.globals.metadata, waterfall.metadata, {
        keys: ['id', 'scope']
    });
    viewmodel.globals.metadata.refresh();
    viewmodel.globals.metadata.grouped = ko.computed(function() {
        var metadata = viewmodel.globals.metadata().slice(0);

        return format.groupMetadata(metadata);
    });
    viewmodel.globals.metadata.groupedAndSorted = ko.computed(function () {
        var metadata = viewmodel.globals.metadata().slice(0);

        return format.groupAndSortMetadata(metadata);
    });
    viewmodel.globals.metadata.nonSystemGrouped = ko.computed(function () {
        var metadata = viewmodel.globals.metadata().slice(0);

        return format.groupNonSystemMetadata(metadata);
    });

    Indexable(viewmodel.globals.organizations, waterfall.organizations);
    viewmodel.globals.organizations.refresh();

    Indexable(viewmodel.globals.groups, waterfall.groups);
    viewmodel.globals.groups.refresh();

    Indexable(viewmodel.globals.roles, waterfall.roles, {
        keys: [ 'id', 'group' ]
    });
    viewmodel.globals.roles.refresh();

    Indexable(viewmodel.globals.users, waterfall.users, {
        keys: [ 'id', 'group', 'role' ]
    });
    viewmodel.globals.users.refresh();

    Indexable(viewmodel.globals.shortcodes, waterfall.shortcodes, {
        keys: ['id', 'shortcode']
    });
    viewmodel.globals.shortcodes.refresh = function (force) {
        var d = new $.Deferred();

        $.when(waterfall.shortcodes.session(), refresh.call(this, force)).
            done(_.bind(function (sessionCode) {
                var result = this();

                this.session(sessionCode);

                d.resolve(result);
            }, this)).
            fail(_.bind(function () {
                d.reject.apply(d, _.toArray(arguments));
            }, this));

        return d.promise();
    };
    viewmodel.globals.shortcodes.refresh();

    Indexable(viewmodel.globals.coupons, waterfall.coupons);
    viewmodel.globals.coupons.refresh();

    Indexable(viewmodel.globals.passes, waterfall.passes);
    viewmodel.globals.passes.refresh();

    Indexable(viewmodel.globals.uploads, waterfall.uploads);
    viewmodel.globals.uploads.refresh();

    Indexable(viewmodel.globals.sweeps, waterfall.sweeps);
    viewmodel.globals.sweeps.refresh();

    Indexable(viewmodel.globals.smartlists, waterfall.smartlists, {
        keys: ['id', 'name']
    });
    viewmodel.globals.smartlists.refresh();

    if (_.contains(['localhost', 'beta.waterfall.com'],
        window.location.hostname) ||
        sharedStrings.deployTarget === 'marketron') {
        Indexable(viewmodel.globals.teams, waterfall.teams);
        viewmodel.globals.teams.refresh();
    }

    if (sharedStrings.deployTarget === 'marketron') {
        Indexable(viewmodel.globals.ads, waterfall.currentAds);
        viewmodel.globals.ads.refresh();

        Indexable(viewmodel.globals.sponsorships,
            waterfall.currentSponsorships);
        viewmodel.globals.sponsorships.refresh();

        Indexable(viewmodel.globals.expiredAds, waterfall.expiredAds);
        viewmodel.globals.expiredAds.refresh();

        Indexable(viewmodel.globals.expiredSponsorships,
            waterfall.expiredSponsorships);
        viewmodel.globals.expiredSponsorships.refresh();
    }
    window.viewmodel = viewmodel;
    return viewmodel;
});

