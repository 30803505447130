define('msgme/viewmodel/flowmodule-poll',[
    'msgme/underscore',
    'msgme/ko',
    'msgme/viewmodel',
    'json!widgets/shared-strings.json'
], function (_, ko, viewmodel, sharedStrings) {
    var BASE_VALUE = 'A'.charCodeAt(0);
    var pollChoiceId = 0;
    var choiceMapping = {
        local: {
            messageChecked: false,
            widgetIdValue: null,
            metadataChecked: false,
            metadataCheckbox: false,
            currentTab: 'sms'
        },

        defaults: {
            value: '',
            description: '',
            nextStep: 'terminate',
            message: null,
            files: [],
            subject: null,
            mobileFlow: null,
            metadataValue: null,
            metadataId: null,
            ad: null,
            sponsorship: null,
            smsFallback: null
        },

        computed: {
            sendMessage: {
                read: function () {
                    var message = this.message();
                    return this.messageChecked() ||
                        (message !== null && message.length > 0);
                },
                write: function (root, value) {
                    if (!value) {
                        this.message('');
                    }
                    this.messageChecked(value);
                }
            },
            widgetId: function () {
                var id = this.widgetIdValue();

                if (id === null) {
                    id = 'flowmodule-poll-choice-' + pollChoiceId++;
                    this.widgetIdValue(id);
                }

                return id;
            },
            metadata: function () {
                return viewmodel.globals.metadata.byId(this.metadataId());
            },
            validValues: function () {
                return !!this.metadata() && this.metadata().validValues;
            },
            fixedValue: {
                read: function () {
                    return this.metadataValue();
                },
                write: function (rootModel, value) {
                    if (!!this.validValues()) {
                        this.metadataValue(value);
                    }
                }
            },
            freeValue: {
                read: function () {
                    return this.metadataValue();
                },
                write: function (rootModel, value) {
                    if (!this.validValues()) {
                        this.metadataValue(value);
                    }
                }
            },
            metadataCheckbox: {
                read: function () {
                    return this.metadataChecked() || this.metadataValue();
                },
                write: function (root, value) {
                    if (!value) {
                        this.metadataChecked(false);
                        this.metadataId(null);
                        this.metadataValue(null);
                    } else {
                        this.metadataChecked(true);
                    }
                }
            },
        },

        validation: {
            nextStep: {
                required: true
            },
            description: {
                required: true
            },
            mobileFlow: {
                validation: {
                    validator: function (val) {
                        if (this.parent.nextStep() === 'forwardToMobileFlow') {
                            if (viewmodel.flow() && viewmodel.flow().id) {
                                return !!val && val !== viewmodel.flow().id();
                            } else {
                                return !!val;
                            }
                        } else {
                            return true;
                        }
                    },

                    message: 'Please select a different list',
                }
            },
            message: {
                validation: {
                    validator: function (val) {
                        if (this.parent.messageChecked() === true) {
                            return !!val;
                        } else {
                            return true;
                        }
                    }
                }
            }
        }
    };

    return {
        mapping: {
            local: {
                title: 'Poll'
            },

            params: {
                mapping: {
                    defaults: {
                        pollQuestion: '',
                        pollQuestionSubject: '',
                        pollQuestionFiles: [],
                        pollQuestionAd: null,
                        pollQuestionSponsorship: null,
                        pollQuestionSMSFallback: null,
                        allowMultipleVotes: true,
                        sendResults: false,
                        choices: [],
                        invalidAnswer: sharedStrings.mobileflows.poll.
                            defaults.invalidAnswer,
                        invalidAnswerSubject: null,
                        invalidAnswerFiles: [],
                        invalidAnswerAd: null,
                        invalidAnswerSponsorship: null,
                        invalidAnswerSMSFallback: null,
                        resetCounts: null,
                    },

                    local: {
                        invalidAnswerCurrentTab: 'sms',
                        pollQuestionCurrentTab: 'sms'
                    },

                    computed: {
                        widgetId: function (root) {
                            return root.widgetId() + '-question';
                        },

                        choiceCount: {
                            read: function () {
                                return this.choices().length;
                            },
                            write: function (root, value) {
                                var currentCount = this.choices().length;
                                var choice;

                                if (currentCount < 2) {
                                    value = 2;
                                }

                                if (currentCount > value) {
                                    this.choices.splice(
                                        value, currentCount - value);
                                } else {
                                    while (currentCount < value) {
                                        // the new index is the current count
                                        choice = ko.mapping.fromJS({
                                            value: String.fromCharCode(
                                                currentCount + BASE_VALUE)
                                        }, choiceMapping);
                                        this.choices.push(choice);
                                        currentCount++;
                                    }
                                }
                            }
                        },

                        choicesList: function () {
                            var choices = _.map(this.choices(),
                                function (choice) {
                                    return choice.value();
                                });
                            var result = choices.slice(0, choices.length - 1).
                                join(', ');

                            result += ' or ' + choices[choices.length - 1];

                            return result;
                        }
                    },

                    choices: {
                        mapping: choiceMapping
                    },

                    validation: {
                        pollQuestion: {
                            required: true
                        }
                    }
                }
            }
        }
    };
});

