define('widgets/sms-engagement-chart/index',[
    'msgme/underscore',
    'msgme/ko',
    'lib/d3',
    'msgme/util/format',
    './../three-widget',
    './../bar-chart/index',
    'text!./template.html',
    'text!./popover-template.html',
    'json!./strings.json',
    'lib/bootstrap'
], function (
    _,
    ko,
    d3,
    format,
    ThreeWidget,
    barChart,
    template,
    popoverTemplate,
    strings
) {
    var mapping = {
        defaults: {
            timeframe: 'LAST30',
            data: [],
            hierarchy: null
        }
    };

    function SmsEngagementChartPopover(el, opts) {
        this.init('popover', el, _.extend({
            placement: 'in left',
            template: popoverTemplate
        }, opts));
    }

    SmsEngagementChartPopover.prototype =
        _.extend({}, $.fn.popover.Constructor.prototype, {
            constructor: SmsEngagementChartPopover,
            getPosition: function () {
                return this.options.position;
            }
        });

    $.widget('msgme.msgme_sms_engagement_chart', ThreeWidget, {
        options: {
            chartConfig: {}
        },

        _template: template,

        _mapping: mapping,

        _create: function () {
            var vm;

            _.bindAll(this, '_onBarMouseenter', '_onChartMouseleave');

            ThreeWidget.prototype._create.apply(this, arguments);

            vm = this.option('viewmodel');
            // ignore any external value of data, since we'll set it ourselves
            vm.data([]);

            this.$chart =
                this.element.find('.bar-chart-container').msgme_bar_chart({
                    data: vm.data
                });

            this.$chart.on('bar-mouseenter', this._onBarMouseenter);
            this.$chart.on('mouseleave', this._onChartMouseleave);
            vm.timeframe.subscribe(_.bind(this._render, this));
            vm.hierarchy.subscribe(_.bind(this._render, this));

            this._render();
        },

        _render: function () {
            var vm = this.option('viewmodel');
            var chart = this.element.find('.bar-chart-container');
            var element = this.element;
            var hierarchy = vm.hierarchy();
            var fetchQuery = {
                LAST7: {
                    size: 7,
                    bucketSize: 'DAY',
                    timeframe: 'LAST7',
                    group: hierarchy
                },
                LAST14: {
                    size: 14,
                    bucketSize: 'DAY',
                    timeframe: 'LAST14',
                    group: hierarchy
                },
                LAST30: {
                    size: 30,
                    bucketSize: 'DAY',
                    timeframe: 'LAST30',
                    group: hierarchy
                }
            };

            // force dummy data so in the case that the timeframe changes
            // before the data has loaded, it won't try to call destroy
            // on the widget that hasn't been instantiated yet
            chart.msgme_bar_chart({ data: [] });
            chart.msgme_bar_chart('destroy');
            element.msgme('busy', true);

            waterfall.reporting.messageSummary.MOBILEFLOW.
                fetch(fetchQuery[vm.timeframe()]).
                    done(function (result) {
                        var data = [_.pluck(result, 'mosTotal'),
                            _.pluck(result, 'smsMtsTotal')];
                        vm.data(data);
                        chart.msgme_bar_chart({
                            data: vm.data
                        });
                    }).
                    always(function () {
                        element.msgme('busy', false);
                    });
        },

        _onChartMouseleave: function () {
            if (this._popover) {
                this._popover.hide();
            }
        },

        _onBarMouseenter: function (e) {
            var $bar = $(e.target);
            var $value = $bar.find('.value');
            var index = $bar.index();
            var config = this.$chart.msgme_bar_chart('option', 'chartConfig');
            var width = +$value.attr('width');
            var height = +$value.attr('height');
            var data = this.option('viewmodel').data();

            if (this._popover) {
                this._popover.hide();
            }

            this._popover = new SmsEngagementChartPopover(this.$chart, {
                content: _.template('<div class="date"><%- date %></div>' +
                    '<div><span class="bold-text"><%- inbound %> </span>' +
                    '<%- mo %></div><div><span class="bold-text">' +
                    '<%- outbound %> </span><%- mt %></div>', {
                    date: format.uiDateOnly((new Date()).addDays(index - 30)),
                    inbound: strings.inboundMessages,
                    mo: format.integer(data[0][index]),
                    outbound: strings.outboundMessages,
                    mt: format.integer(data[1][index])
                }),
                animation: false,
                position: {
                    top: this.$chart.height() - height,
                    left: index * (config.spacing + width) + config.margin.left,
                    width: width,
                    height: height
                }
            });
            this._popover.show();
        }
    });

    return {
        widget: $.msgme.msgme_sms_engagement_chart,
        mapping: mapping
    };
});


