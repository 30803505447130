define('msgme/views/pass',[
    'jquery',
    'underscore',
    'msgme/ko',
    'msgme/splash',
    'msgme/path',
    'msgme/alert',
    'msgme/views/View',
    'msgme/viewmodel',
    'msgme/viewmodel/mapping',
    'msgme/viewmodel/pass',
    'msgme/util/api',
    'msgme/util/format',
    'json!widgets/shared-strings.json',
    'jquery.form'
],
function (
    $,
    _,
    ko,
    splash,
    path,
    alert,
    View,
    viewmodel,
    mapping,
    pass,
    api,
    format,
    sharedStrings
) {
    var view = new View('#passbook-view');
    var headerMapping =
        pass.mapping.passbook.mapping.coupon.mapping.headerFields.mapping;
    var secondaryMapping =
        pass.mapping.passbook.mapping.coupon.mapping.secondaryFields.mapping;
    var backMapping =
        pass.mapping.passbook.mapping.coupon.mapping.backFields.mapping;
    var locationMapping = pass.mapping.passbook.mapping.locations.mapping;

    var cm = new mapping.CursorModel(waterfall.passes, {
        record: {
            mapping: pass.mapping
        }
    });
    var previewSubscription;

    function populate(passInstance) {
        pass.model(passInstance);
        passInstance.isModified(false);

        splash.hide();
        view.root.msgme('busy', false);

        if (previewSubscription) {
            previewSubscription.dispose();
        }

        previewSubscription =
            passInstance.previewPayload.subscribe(onPreviewPayloadChange);
        onPreviewPayloadChange(passInstance.previewPayload());
    }

    var onPreviewPayloadChange = _.debounce(
        function onPreviewPayloadChangeFn(payload) {
            var previewContainer = view.root.find('.preview-container');
            var pass = previewContainer.find('iframe')[0].contentDocument.
                querySelector('.pass');
            var flipped = pass && pass.className.indexOf('flipped') > -1;

            previewContainer.msgme('busy', false);
            $.ajax({
                    type: 'POST',
                    url: '/api/v1/coupon/passbook/generate/preview',
                    contentType: 'application/json',
                    data: payload,
                    dataType: 'html'
                }).
                done(function (preview) {
                    var frame = document.createElement('iframe');

                    previewContainer.find('iframe').remove();
                    previewContainer.append(frame);

                    if (frame.contentDocument) {
                        frame.contentDocument.write(preview);
                        frame.contentDocument.querySelector('.pass').
                            className += flipped ? ' flipped' : '';
                    } else {
                        $(frame).on('load', function () {
                            frame.contentDocument.write(preview);
                            frame.contentDocument.querySelector('.pass').
                                className += flipped ? ' flipped' : '';
                        });
                    }

                    previewContainer.msgme('busy', false);
                }).
                fail(api.getRequestFailureFn(null,
                    'coupon.passbook.generate.preview',
                    payload));
        }, 100);

    /*
     * Browsers format file input controls inconsistently, so we will instead
     * use a normal button and disabled text input to simulate one. When the
     * button is clicked, we find its related hidden file input control and
     * trigger a click on it. When the file input changes, we update the text
     * input with the selected filename.
     */
    view.root.on('click', '.btn.browse', function (e) {
        var button = $(e.currentTarget);

        button.closest('.passbook-details').
            find(button.data('target') + ' input[type="file"]').
            trigger('click');
    });

    var targetToImageField = {
        '.ikon-image': 'icon',
        '.logo-image': 'logo',
        '.strip-image': 'strip'
    };
    view.root.on('change', 'input[type="file"]', function (e) {
        var input = $(e.currentTarget);
        var filepath = input.val();
        var filename = filepath.slice(filepath.lastIndexOf('\\') + 1);
        var target = input.data('target');

        input.closest('.passbook-details').
            find(input.data('target') + ' .filename').val(filename);

        input.closest('form').ajaxSubmit({
            data: {
                visibility: 'PUBLIC',
                fileName: filename
            },
            success: function (file) {
                pass.model().
                    images[targetToImageField[target]](file.id);
            }
        });
    });

    view.root.on('click', '.color-and-images .btn.remove', function (e) {
        var controlGroup = $(e.currentTarget).closest('.control-group');
        var observableName = controlGroup.data('image-observable');

        pass.model().images[observableName](null);
        controlGroup.find('.filename').val('');
        controlGroup.closest('.color-and-images').
            find('form.' + observableName + ' input[type="file"]').val('');
    });

    view.root.on('click', '.header.fields .btn.add', function () {
        pass.model().passbook.coupon.headerFields.push(
            ko.mapping.fromJS({}, headerMapping));
    });

    view.root.on('click', '.header.fields .btn.remove', function (e) {
        var index = $(e.currentTarget).closest('.header-field').index();

        pass.model().passbook.coupon.headerFields.splice(index, 1);
    });

    view.root.on('click', '.secondary.fields .btn.add', function () {
        pass.model().passbook.coupon.secondaryFields.push(
            ko.mapping.fromJS({}, secondaryMapping));
    });

    view.root.on('click', '.secondary.fields .btn.remove', function (e) {
        var index = $(e.currentTarget).closest('.secondary-field').index();

        pass.model().passbook.coupon.secondaryFields.splice(index, 1);
    });

    view.root.on('click', '.back-content .btn.add', function () {
        pass.model().passbook.coupon.backFields.push(
            ko.mapping.fromJS({}, backMapping));
    });

    view.root.on('click', '.back.fields .btn.remove', function (e) {
        var index = $(e.currentTarget).closest('.back-field').index();

        pass.model().passbook.coupon.backFields.splice(index, 1);
    });

    view.root.on('click', '.locations .btn.add', function () {
        pass.model().passbook.locations.push(
            ko.mapping.fromJS({}, locationMapping));
    });

    view.root.on('click', '.location .btn.remove', function (e) {
        var index = $(e.currentTarget).closest('.location').index();

        pass.model().passbook.locations.splice(index, 1);
    });

    view.root.on('click', '.settings.sidebar button.save', function () {
        var passInstance = pass.model();

        if (!passInstance.isValid || passInstance.isValid()) {
            view.root.find('.settings.sidebar button.save').msgme('busy', true);
            passInstance.save().
                done(function () {
                    // FIXME: this should come from the resources file
                    alert.success('Pass saved successfully.');
                    view.root.find('.settings.sidebar button.save').
                        msgme('busy', false);
                    path.history.pushState(null, null,
                        sharedStrings.sitemap.passes.url + '/' +
                            passInstance.id());
                    viewmodel.globals.passes.refresh(true);
                }).
                fail(function () {
                    view.root.find('.settings.sidebar button.save').
                        msgme('busy', false);
                }).
                fail(view.getRequestFailureFn(null,
                    'pass.save',
                    passInstance.id ? passInstance.id() : 'new'));
        } else {
            passInstance.isModified(true);
        }
    });

    view.root.on('change', '.passbook-details select.type', function () {
        var itemValue;

        if ($(this).closest('.header-field').length > 0) {
            itemValue = $(this).closest('.header-field').find('.item-value');
        } else if ($(this).closest('.secondary-field').length > 0) {
            itemValue = $(this).closest('.secondary-field').find('.item-value');
        } else if ($(this).closest('.back-field').length > 0) {
            itemValue = $(this).closest('.back-field').find('.item-value');
        } else {
            itemValue = $(this).closest('.fields').find('.item-value');
        }

        switch (this.value) {
        case 'string':
            itemValue.val('').change();
            break;
        case 'number':
            itemValue.val(0).change();
            break;
        case 'date':
            itemValue.val(format.apiDate(Date.create())).change();
            break;
        }
    });

    path.map(sharedStrings.sitemap.passes.url + '/:id').to(function () {
        var id = this.params.id.toLowerCase();

        view.show();

        if (id === 'new') {
            populate(cm.create({
                data: {
                    images: {
                        icon: '510017760cf222e4af81d3dd',
                        logo: '510019fa0cf222e4af81d5b8',
                        strip: '51001ae30cf222e4af81d686'
                    }
                }
            }));
        } else {
            view.root.msgme('busy', true);
            cm.fetch(this.params.id).
                done(populate).
                fail(view.getRequestFailureFn(null,
                    'passes.fetch',
                    this.params.id));
        }
    });

    return view;
});

