define('msgme/viewmodel/flowmodule-texttowin',[
    'jquery',
    'msgme/ko',
    'msgme/underscore',
    'msgme/util/format',
    'msgme/widgets/flowmodule',
    'msgme/util/api',
    'msgme/viewmodel/util/dateparts',
    'msgme/viewmodel',
    'json!widgets/shared-strings.json'
],
function (
    $,
    ko,
    _,
    format,
    flowmodule,
    api,
    getDateParts,
    viewmodel,
    sharedStrings
) {
    var numberToString = {
        read: function (root, parent) {
            var result = parent();
            return _.isNumber(result) ? '' + result : '';
        },
        write: function (root, parent, value) {
            value = parseInt(value, 10);
            parent(_.isNumber(value) && !_.isNaN(value) ? value : null);
        }
    };

    return {
        mapping: {
            local: {
                title: 'Text-to-Win'
            },

            computed: {
                isNewModule: function () {
                    return !this.head;
                }
            },

            params: {
                mapping: {
                    defaults: {
                        metadataId: null,
                        startDateTime: format.apiDate(new Date()),
                        endDateTime:
                            format.apiDate(Date.now() + 24 * 60 * 60 * 1000),
                        allowMultipleEntry: false,
                        duplicateEntryMessage: '',
                        duplicateEntryNextStep: 'terminate',
                        duplicateEntryMobileFlow: '',
                        duplicateEntrySubject: '',
                        duplicateEntryFiles: [],
                        duplicateEntryAd: null,
                        duplicateEntrySponsorship: null,
                        duplicateEntrySMSFallback: null,
                        preFlightMessage: '',
                        preFlightNextStep: 'terminate',
                        preFlightMobileFlow: '',
                        preFlightSubject: '',
                        preFlightFiles: [],
                        preFlightAd: null,
                        preFlightSponsorship: null,
                        preFlightSMSFallback: null,
                        methodology: '',
                        noOfSubscribers: '',
                        noOfTotalWinners: '',
                        winnerGroups: [],
                        postFlightMessage: '',
                        defaultMessage: '',
                        defaultNextStep: 'terminate',
                        defaultMobileFlow: '',
                        defaultSubject: '',
                        defaultFiles: [],
                        defaultAd: null,
                        defaultSponsorship: null,
                        defaultSMSFallback: null,
                        postFlightNextStep: 'terminate',
                        postFlightMobileFlow: '',
                        postFlightSubject: '',
                        postFlightFiles: [],
                        postFlightAd: null,
                        postFlightSponsorship: null,
                        postFlightSMSFallback: null,
                        resetCounts: false,
                        oncePerDay: false
                    },

                    computed: {
                        multipleEntryValue: {
                            read: function () {
                                if (this.allowMultipleEntry() &&
                                    !this.oncePerDay()) {
                                    return 'allowMultipleEntry';
                                } else if (this.oncePerDay()) {
                                    return 'oncePerDay';
                                } else {
                                    return 'onlyOnce';
                                }
                            },

                            write: function (parent, value) {
                                if (value === 'onlyOnce') {
                                    this.oncePerDay(false);
                                    this.allowMultipleEntry(false);
                                } else if (value === 'oncePerDay') {
                                    this.oncePerDay(true);
                                    this.allowMultipleEntry(true);
                                } else if (value === 'allowMultipleEntry') {
                                    this.oncePerDay(false);
                                    this.allowMultipleEntry(true);
                                }
                            }
                        }
                    },

                    validation: {
                        endDate: {
                            validation: {
                                validator: function () {
                                    return (new Date(
                                        this.parent.startDateTime())) <
                                        (new Date(this.parent.endDateTime()));
                                },
                                message:
                                    sharedStrings.mobileflows.validation.endDate
                            }
                        },
                        methodology: {
                            required: true
                        },
                        metadataId: {
                            required: {
                                onlyIf: function () {
                                    return this.parent.tagMetadata();
                                }
                            }
                        },
                        defaultNextStep: {
                            required: true
                        },
                        defaultMobileFlow: {
                            validation: {
                                validator: function (val) {
                                    if (this.parent.defaultNextStep() ===
                                        'forwardToMobileFlow') {
                                        if (viewmodel.flow() &&
                                            viewmodel.flow().id) {
                                            return !!val && val !==
                                                viewmodel.flow().id();
                                        } else {
                                            return !!val;
                                        }
                                    } else {
                                        return true;
                                    }
                                },

                                message: 'Please select a different list'
                            }
                        },
                        duplicateEntryMobileFlow: {
                            validation: {
                                validator: function (val) {
                                    if (this.parent.duplicateEntryNextStep() ===
                                        'forwardToMobileFlow') {
                                        if (viewmodel.flow() &&
                                            viewmodel.flow().id) {
                                            return !!val && val !==
                                                viewmodel.flow().id();
                                        } else {
                                            return !!val;
                                        }
                                    } else {
                                        return true;
                                    }
                                },

                                message: 'Please select a different list'
                            }
                        },
                        postFlightNextStep: {
                            required: true
                        },
                        postFlightMobileFlow: {
                            validation: {
                                validator: function (val) {
                                    if (this.parent.postFlightNextStep() ===
                                        'forwardToMobileFlow') {
                                        if (viewmodel.flow() &&
                                            viewmodel.flow().id) {
                                            return !!val && val !==
                                                viewmodel.flow().id();
                                        } else {
                                            return !!val;
                                        }
                                    } else {
                                        return true;
                                    }
                                },

                                message: 'Please select a different list'
                            }
                        },
                        preFlightNextStep: {
                            required: true
                        },
                        preFlightMobileFlow: {
                            validation: {
                                validator: function (val) {
                                    if (this.parent.preFlightNextStep() ===
                                        'forwardToMobileFlow') {
                                        if (viewmodel.flow() &&
                                            viewmodel.flow().id) {
                                            return !!val && val !==
                                                viewmodel.flow().id();
                                        } else {
                                            return !!val;
                                        }
                                    } else {
                                        return true;
                                    }
                                },

                                message: 'Please select a different list'
                            }
                        }
                    },

                    local: {
                        tagMetadata: false,
                        metadataIdLocal: null,
                        validValues: null,
                        startDate: '',
                        endDate: '',
                        duplicateEntryCurrentTab: 'sms',
                        preFlightCurrentTab: 'sms',
                        postFlightCurrentTab: 'sms',
                        defaultCurrentTab: 'sms',
                        mapping: {
                            computed: {
                                metadataIdLocal: {
                                    read: function (rootModel) {
                                        return rootModel.params.metadataId();
                                    },
                                    write: function (rootModel, value) {
                                        if (rootModel.params.tagMetadata()) {
                                            rootModel.params.metadataId(value);
                                        } else {
                                            rootModel.params.metadataId(null);
                                            _.each(rootModel.params.
                                                winnerGroups(), function (grp) {
                                                    grp.tagName(null);
                                                });
                                        }
                                    }
                                }
                            },
                            subcomputed: {
                                startDate: getDateParts('startDateTime'),
                                endDate: getDateParts('endDateTime')
                            }
                        }
                    },

                    metadataId: {
                        create: function (options) {
                            options.parent.
                                tagMetadata(options.data !== null);
                            return ko.observable(options.data);
                        }
                    },

                    winnerGroups: {
                        mapping: {
                            defaults: {
                                message: '',
                                subject: '',
                                files: [],
                                ad: null,
                                sponsorship: null,
                                tagName: '',
                                messageLimit: null,
                                noOfWinners: null,
                                everyNth: null,
                                percentage: null,
                                widgetId: '',
                                nextStep: 'terminate',
                                mobileFlow: '',
                                smsFallback: null
                            },

                            local: {
                                limit: 'No Limit',
                                currentTab: 'sms'
                            },

                            messageLimit: {
                                create: function (options) {
                                    options.parent.limit(
                                        _.isNumber(options.data) ?
                                        'Limit' : 'No Limit');
                                    return ko.observable(options.data);
                                }
                            },

                            computed: {
                                fixedValue: {
                                    read: function () {
                                        return this.tagName();
                                    },
                                    write: function (rootModel, value) {
                                        if (rootModel.params.validValues()) {
                                            this.tagName(value);
                                        }
                                    }
                                },

                                freeValue: {
                                    read: function () {
                                        return this.tagName();
                                    },
                                    write: function (rootModel, value) {
                                        if (!rootModel.params.validValues()) {
                                            this.tagName(value);
                                        }
                                    }
                                }
                            },

                            subcomputed: {
                                messageLimit: {
                                    asString: numberToString
                                },
                                noOfWinners: {
                                    asString: numberToString
                                },
                                everyNth: {
                                    asString: numberToString
                                },
                                percentage: {
                                    asString: numberToString
                                }
                            },

                            validation: {
                                messageLimit: {
                                    number: true,
                                    required: {
                                        onlyIf: function () {
                                            return this.parent.limit() ===
                                                'Limit';
                                        }
                                    }
                                },
                                everyNth: {
                                    validator: function () {
                                        if (this.root.params.methodology() !==
                                            'PATTERN') {
                                            return true;
                                        } else {
                                            return !!this.observable() &&
                                                this.observable() > 0;
                                        }
                                    },
                                    message:
                                        'Please enter a number greater than 0'
                                },
                                percentage: {
                                    number: true,
                                    min: 1,
                                    max: 99,
                                    required: {
                                        onlyIf: function () {
                                            return this.root.
                                                params.methodology() ===
                                                'RANDOM';
                                        }
                                    }
                                },
                                freeValue: {
                                    required: {
                                        onlyIf: function () {
                                            var params = this.root.params;
                                            return params.tagMetadata() &&
                                                !params.validValues();
                                        }
                                    }
                                },
                                fixedValue: {
                                    required: {
                                        onlyIf: function () {
                                            var params = this.root.params;
                                            return params.tagMetadata() &&
                                                !!params.validValues();
                                        }
                                    }
                                },
                                nextStep: {
                                    required: true
                                },
                                mobileFlow: {
                                    validation: {
                                        validator: function (val) {
                                            if (this.parent.nextStep() ===
                                                'forwardToMobileFlow') {
                                                if (viewmodel.flow() &&
                                                    viewmodel.flow().id) {
                                                    return !!val && val !==
                                                        viewmodel.flow().id();
                                                } else {
                                                    return !!val;
                                                }
                                            } else {
                                                return true;
                                            }
                                        },

                                        message:
                                            'Please select a different list'
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    };
});


