define('widgets/calendar/index',[
    'msgme/underscore',
    'msgme/viewmodel',
    './../three-widget',
    './../file-preview/index',
    'text!./template.html',
    'json!./strings.json'
], function (_,
    viewmodel,
    ThreeWidget,
    filePreview,
    template,
    strings)
{
    var maxVisibleBroadcastsPerDay = 4;

    function dayLabel(date) {
        var month = date.getDate() === 1 ? date.format('{Mon}') : '';

        return month + ' ' + date.getDate();
    }

    function formatSendAt(date) {
        var ampm = date.format('{t}');
        var time = date.format('{h}:{mm}').replace(/:00$/, '');

        return time + ampm;
    }

    function getMessage(broadcast) {
        var message = broadcast.content[0].message;
        var slices;
        var slice;

        if (!message) {
            slices = broadcast.content[0].slices ||
                broadcast.content[0].segments;

            slice = _.find(slices, function (slice) {
                return slice.message;
            });

            message = slice ? slice.message : '';
        }

        return message;
    }

    function getSubject(broadcast) {
        var contentItem = _.find(broadcast.content, function (contentItem) {
            return contentItem.channel === 'sms';
        });
        var mmsSlice;

        if (contentItem) {
            mmsSlice = _.find(contentItem.slices, function (slice) {
                return slice.subject;
            });

            return mmsSlice && mmsSlice.subject;
        }
    }

    function getAttachment(broadcast) {
        var contentItem = _.find(broadcast.content, function (contentItem) {
            return contentItem.channel === 'sms';
        });
        var mmsSlice;

        if (!contentItem) {
            return;
        }

        mmsSlice = _.find(contentItem.slices, function (slice) {
            return slice.files && slice.files[0];
        });

        if (!mmsSlice) {
            return;
        }

        return waterfall.file.url + '/' + mmsSlice.files[0];
    }

    function getAudienceName(broadcast) {
        var audiences;
        var contentWithList;
        var list;

        if (broadcast.content[0].audience &&
            broadcast.content[0].audience.length) {
            audiences = broadcast.content[0].audience;
        } else {
            audiences = broadcast.content[0].embeddedAudience;
        }

        if (audiences) {
            return audiences[0].description;
        }

        contentWithList = _.find(broadcast.content, function (item) {
            return item.lists && item.lists.length;
        });

        list = contentWithList &&
            viewmodel.globals.lists.byId(contentWithList.lists[0]);

        return list && list.name ? list && list.name : 'Smart Segment';
    }

    function formatBroadcast(broadcast) {
        var message = getMessage(broadcast);

        var result = {
            sendAt: formatSendAt(broadcast.sendAt || broadcast.sentAt),
            channel: broadcast.content[0].channel,
            audienceName: getAudienceName(broadcast),

            // newlines in `text` bindings for IE and chrome turn into <br>
            // elements: http://stackoverflow.com/a/10491017/5377
            // this messes up the height of the calendar day elements, and i
            // couldn't figure out a good css fix, so i'll just truncate it
            // here
            message: message.split('\n')[0]
        };

        result.title = [
            result.sendAt,
            result.audienceName,
            '-',
            message
        ].join(' ');

        return result;
    }

    function formatBroadcasts(broadcasts) {
        if (!broadcasts) {
            return [];
        }

        var hideMessages =
            broadcasts.length > Math.floor(maxVisibleBroadcastsPerDay / 2);
        var messageOverride = hideMessages ? {message: ''} : null;
        var sliced;

        if (broadcasts.length > maxVisibleBroadcastsPerDay) {
            sliced = broadcasts.slice(0, maxVisibleBroadcastsPerDay - 1);
        } else {
            sliced = broadcasts;
        }

        return _.map(sliced, function (broadcast) {
            return $.extend(true, {}, broadcast, messageOverride);
        });
    }

    function formatMore(broadcasts) {
        if (broadcasts && broadcasts.length > maxVisibleBroadcastsPerDay) {
            return _.sprintf(strings.more, broadcasts.length -
                             maxVisibleBroadcastsPerDay + 1);
        } else {
            return '';
        }
    }

    function formatBroadcastDetails(broadcast) {
        var date = broadcast.sendAt || broadcast.sentAt;

        return {
            timeString: date.format('{h}:{mm}{tt}'),
            channel: broadcast.content[0].channel,
            audiences: getAudienceName(broadcast),
            message: getMessage(broadcast),
            subject: getSubject(broadcast),
            attachment: getAttachment(broadcast)
        };
    }

    var mapping = {
        defaults: {
            baseDate: Date.create().set({day: 1}).toISOString(),
            futureBroadcasts: [],
            pastBroadcasts: [],
            detailsDateString: null,
            filePreviewId: null
        },

        computed: {
            broadcastDetails: {
                read: function () {
                    var dateString = this.detailsDateString();
                    var broadcasts = this.broadcastsByDate()[dateString];

                    if (!broadcasts) {
                        return [];
                    }

                    return broadcasts.map(formatBroadcastDetails);
                },

                deferEvaluation: true
            },

            broadcastsByDate: function () {
                function reduceBroadcast(broadcastsByDate, broadcast) {
                    var date = broadcast.sendAt || broadcast.sentAt;
                    var dateString = date.format('{yyyy}-{MM}-{dd}');

                    if (!broadcastsByDate[dateString]) {
                        broadcastsByDate[dateString] = [];
                    }

                    broadcastsByDate[dateString].push(broadcast);

                    broadcastsByDate[dateString].sort(function(a, b) {
                        return (a.sentAt || a.sendAt) - (b.sentAt || b.sendAt);
                    });

                    return broadcastsByDate;
                }

                var futureBroadcasts =
                    _.reduce(this.futureBroadcasts(), reduceBroadcast, {});

                return _.reduce(this.pastBroadcasts(), reduceBroadcast,
                    futureBroadcasts);
            },

            date: function () {
                return Date.create(this.baseDate());
            },

            formattedBroadcastsByDate: function () {
                return _.reduce(this.broadcastsByDate(),
                    function (formattedBroadcastsByDate, broadcasts, date) {
                        formattedBroadcastsByDate[date] =
                            broadcasts.map(formatBroadcast);

                        return formattedBroadcastsByDate;
                    }, {});
            },

            formattedDetailsDateString: function () {
                return Date.create(this.detailsDateString()).
                    format('{Weekday}, {Month} {d}, {yyyy}');
            },

            monthName: function () {
                return this.date().format('{Month}');
            },

            weeksInMonth: function () {
                var date = this.date();
                var lastOfMonth = date.
                    clone().
                    advance({month: 1}).
                    rewind({day: 1});

                return Math.ceil((date.getDay() + lastOfMonth.getDate()) / 7);
            },

            days: function () {
                var weeksInMonth = this.weeksInMonth();
                var firstOfMonth = this.date();

                return _.map(_.range(7 * weeksInMonth), function(i) {
                    return firstOfMonth.clone().rewind({
                        days: firstOfMonth.getDay() - i
                    });
                });
            },

            weeks: function () {
                var days = this.days();
                var month = this.date().getMonth();
                var weeksInMonth = this.weeksInMonth();
                var broadcastsByDate = this.formattedBroadcastsByDate();

                return _.map(_.range(weeksInMonth), function(i) {
                    return _.map(_.range(7), function(j) {
                        var date = days[i * 7 + j];
                        var className = 'day';
                        var dateString = date.format('{yyyy}-{MM}-{dd}');
                        var broadcasts = broadcastsByDate[dateString];

                        if (date.getMonth() !== month) {
                            className += ' off-month';
                        }

                        if (date.isBefore('today')) {
                            className += ' past';
                        }

                        if (date.isToday()) {
                            className += ' today';
                        }

                        if (broadcasts && broadcasts.length) {
                            className += ' has-broadcasts';
                        }

                        return {
                            dateString: dateString,
                            className: className,
                            dayLabel: dayLabel(date),
                            broadcasts: formatBroadcasts(broadcasts),
                            more: formatMore(broadcasts)
                        };
                    });
                });
            },

            year: function () {
                return this.date().format('{year}');
            }
        }
    };

    $.widget('msgme.msgme_calendar', ThreeWidget, {
        _mapping: mapping,

        _template: template,

        _create: function () {
            var vm;

            this._super();

            vm = this.option('viewmodel');

            this.on('click', '.day.has-broadcasts',
                    '_onClickDayWithBroadcasts');
            this.on('click', '[data-nav=next]', '_goToNextMonth');
            this.on('click', '[data-nav=prev]', '_goToPreviousMonth');
            this.on('click', '.show-preview', '_onClickShowPreview');
            this.element.on('click', '[data-back]',
                vm.filePreviewId.bind(vm, null));

            this.refresh();

            vm.detailsDateString.subscribe(this._onDetailsChange.bind(this));
        },

        _createBroadcastDetailsModal: function () {
            var vm = this.option('viewmodel');

            this.$modal = this.element.find('.broadcast-details.modal').
                modal().
                on('hide', vm.detailsDateString.bind(vm, null));
        },

        _fetchFutureBroadcasts: function () {
            return waterfall.broadcasts.fetch({count: false, size: 100}).
                then(this.option('viewmodel').futureBroadcasts);
        },

        _fetchPastBroadcasts: function () {
            var query = {
                startDate: Date.create().rewind({months: 3}).toISOString(),
                count: false,
                size: 300
            };
            var config = {
                url: waterfall.broadcasts.config.url + '/summaries'
            };

            return waterfall.broadcasts.fetch(query, config).
                then(this.option('viewmodel').pastBroadcasts);
        },

        _goToNextMonth: function () {
            var vm = this.option('viewmodel');
            var newDate = new Date(vm.baseDate());

            newDate.advance({month: 1});
            vm.baseDate(newDate.toISOString());
        },

        _goToPreviousMonth: function () {
            var vm = this.option('viewmodel');
            var newDate = new Date(vm.baseDate());

            newDate.rewind({month: 1});
            vm.baseDate(newDate.toISOString());
        },

        _hideBroadcastDetails: function () {
            if (this.$modal) {
                this.$modal.modal('hide');
            }
        },

        _showBroadcastDetails: function () {
            if (!this.$modal) {
                this._createBroadcastDetailsModal();
            }

            this.option('viewmodel').filePreviewId(null);

            this.$modal.modal('show');
        },

        _onClickDayWithBroadcasts: function (event) {
            var dateString = $(event.currentTarget).data('date');

            this._setBroadcastDetailsModalDate(dateString);
        },

        _onClickShowPreview: function (event) {
            var href = $(event.currentTarget).attr('href');
            var filePreviewId = _.last(href.split('/'));

            event.preventDefault();

            this._setFilePreviewId(filePreviewId);
        },

        _onDetailsChange: function () {
            if (this.option('viewmodel').detailsDateString()) {
                this._showBroadcastDetails();
            } else {
                this._hideBroadcastDetails();
            }
        },

        _setBroadcastDetailsModalDate: function (dateString) {
            this.option('viewmodel').detailsDateString(dateString);
        },

        _setFilePreviewId: function (filePreviewId) {
            this.option('viewmodel').filePreviewId(filePreviewId);
        },

        refresh: function () {
            this.element.msgme('busy', true);
            $.when(this._fetchFutureBroadcasts(), this._fetchPastBroadcasts()).
                always(this.element.msgme.bind(this.element, 'busy', false));
        }
    });

    return {
        widget: $.msgme.msgme_calendar,
        mapping: mapping
    };
});

