define('widgets/stream-entry/index',[
    'msgme/underscore',
    'msgme/ko',
    'msgme/viewmodel',
    'msgme/viewmodel/subscriber',
    'msgme/util/format',
    'msgme/util/api',
    'msgme/util/feature-flags',
    'msgme/modal',
    './../three-widget',
    './../file-uploader/index',
    'text!./template.html',
    'text!./sub-widgets/add-to-list.html',
    'text!./sub-widgets/remove-from-list.html',
    'text!./sub-widgets/add-metadata.html',
    'json!./strings.json',
    'json!./../shared-strings.json',
    'msgme/viewmodel/add-to-list',
    'msgme/viewmodel/remove-from-list',
    'msgme/viewmodel/add-metadata',
    'msgme/plugins',
    'msgme/widgets/add-to-list',
    'msgme/widgets/remove-from-list',
    'msgme/widgets/add-metadata'
], function (
    _,
    ko,
    viewmodel,
    subscriberViewmodel,
    format,
    api,
    featureFlags,
    modal,
    ThreeWidget,
    fileUploader,
    template,
    addToListTemplate,
    removeFromListTemplate,
    addMetadataTemplate,
    strings,
    sharedStrings,
    addToList,
    removeFromList,
    addMetadata
) {
    var subscriberCache = ko.observable({});
    var subscriberRequests = $.Deferred().resolve().promise();

    function invalidateViewmodelSubscriberCache() {
        subscriberCache()[viewmodel.subscriber.id()] = null;
    }

    // if the user navigates to a subscriber profile, that will update one of
    // the below fields in `viewmodel`, so if they change something there and
    // then navigate back, we should re-fetch the subscriber info
    viewmodel.subscriber.mobilePhoneNo.
        subscribe(invalidateViewmodelSubscriberCache);
    viewmodel.subscriber.metadata.
        subscribe(invalidateViewmodelSubscriberCache);

    function emailMetadata() {
        var profileMetadata = viewmodel.globals.metadata.allByScope('PROFILE');

        return  _.find(profileMetadata, function(metadatum) {
            return metadatum.name === 'email';
        });
    }

    function validateEmail(val) {
        if (val && emailMetadata()) {
            return val.match(emailMetadata().format);
        } else {
            return false;
        }
    }

    var uploadButtonClasses = fileUploader.mapping.defaults.uploadButtonClasses;
    var unwrap = ko.utils.unwrapObservable;
    var subWidgets = {
        'add-to-list': {
            mapping: addToList.mapping,
            template: addToListTemplate
        },
        'remove-from-list': {
            mapping: removeFromList.mapping,
            template: removeFromListTemplate
        },
        'add-metadata': {
            mapping: addMetadata.mapping,
            template: addMetadataTemplate
        }
    };
    var dateSubcomputed = {
        relative: function (root, parent) {
            // force a dependency on viewmodel.now
            viewmodel.now();

            return format.uiShortRelativeDate(parent());
        },

        absolute: function (root, parent) {
            return format.uiDate(parent());
        }
    };
    var _rawFBMessages = {
        defaults: {
            createdAt: null,
            details: {
                imageURL: null,
                editedTime: null,
                firstName: null,
                lastName: null,
                pictureURL: null,
                postId: null,
                messageType: null,
                parentStreamMessage: null,
                likes: null,
                edited: null
            },
            subscriber: null,
            channel: null,
            id: null
        },
        computed: {
            fullName: function () {
                return this.details.firstName() + ' ' + this.details.lastName();
            },
            isReply: function () {
                return this.details.messageType() === 'REPLY';
            }
        },
        subcomputed: {
            createdAt: dateSubcomputed
        }
    };
    var _rawSMSMessages = {
        defaults: {
            createdAt: null,
            details: {
                mobileFlowName: null,
                mobileFlow: null,
                messageType: null,
                list: [],
                mobilePhoneNo: null,
            },
            subscriber: null,
            channel: null,
            message: null,
            id: null,
            emailed: null
        },
        computed: {
            isReply: function () {
                return this.details.messageType() === 'MT';
            }
        },
        subcomputed: {
            createdAt: dateSubcomputed
        }
    };
    var mapping = {
        defaults: {
            data: null,
            subscriber: null,
            messages: [],
            subject: null,
            sendMessage: '',
            fileIds: [],
            mobileReplyType: 'sms',
            subscriberProfile: null,
            facebookPost: null,
            replyPromise: false,
            replyChannel: null,
            emailFrom: null,
            emailTo: null,
            emailAddress: null,
            emailSubject: null,
            attachment: null,
            _expand: false,
            _visibleWidget: null,
            editEmailTo: false,
            addEmailButtonClicked: false,
            storedEmailTo: null,
            isSubscriberFetched: false,
            isSubscriberNamesFeatureEnabled: false
        },

        computed: {
            addEmailHelpVisible: {
                read: function (root) {
                    if (!root.isSubscriberFetched()) {
                        console.warn(strings.warning.subscriberNotFetched);
                    }

                    return !root.storedEmailTo() &&
                        !root.addEmailButtonClicked();
                },

                deferEvaluation: true
            },

            bodyVisible: {
                read: function (root) {
                    return !root.visibleWidget() && !root.expand();
                },

                deferEvaluation: true
            },

            channel: function (root) {
                return (unwrap(root.data().channel) || '').toLowerCase();
            },

            cssClass: function (root) {
                var cls = {};

                cls[root.channel()] = true;

                return cls;
            },

            createdAt: function (root) {
                return unwrap(root.data().createdAt);
            },

            emailFormVisible: {
                read: function (root) {
                    return root.isChannelEmail() && !root.addEmailHelpVisible();
                },

                deferEvaluation: true
            },

            expand: {
                read: function (root) {
                    return root._expand();
                },

                write: function (root, val) {
                    root._expand(val);

                    if (val && root.visibleWidget) {
                        root.visibleWidget(null);
                    }
                }
            },

            isChannelEmail: function (root) {
                return root.replyChannel() === 'email';
            },

            logoClass: function (root) {
                var channel = root.channel();
                var className = 'fa-' +
                    (channel === 'sms' ? 'mobile-phone' : channel);
                var cls = {};

                cls[className] = true;

                return cls;
            },

            pageName: function (root) {
                var post = root.facebookPost();

                return (post && post.pageName) || strings.company;
            },

            phoneNo: {
                read: function (root) {
                    var val = unwrap(root.data().details.mobilePhoneNo);

                    return format.phoneNo(val);
                },

                deferEvaluation: true
            },

            postVisible: function (root) {
                return root.channel() === 'facebook' && root.expand();
            },

            mobileReplyButtonText: function (root) {
                var mobileReplyType = root.mobileReplyType();

                if (mobileReplyType === 'mms') {
                    return strings.reply.mms;
                } else {
                    return strings.reply.sms;
                }
            },

            subscriberUrl: {
                read: function (root) {
                    var data = root.data();
                    var end = waterfall.authenticate.isMasked ?
                        unwrap(data.subscriber) :
                        format.unformattedPhoneNo(
                            unwrap(data.details.mobilePhoneNo));

                    return sharedStrings.sitemap.subscriber.url + '/' + end;
                },

                deferEvaluation: true
            },

            visibleWidget: {
                read: function (root) {
                    return root._visibleWidget();
                },

                write: function (root, value) {
                    root._visibleWidget(value);

                    // reset values when hiding stuff
                    if (root.addToList && value !== 'add-to-list') {
                        root.addToList.listId(null);
                        root.addToList.optInType('singleOptIn');
                        root.addToList.optInMessage(
                            addToList.mapping.local.optInMessage);
                        root.addToList.confirmMessage(
                            addToList.mapping.local.confirmMessage);
                        root.addToList.subscribedMessage('');
                    }

                    if (root.removeFromList && value !== 'remove-from-list') {
                        root.removeFromList.listId(null);
                    }

                    if (root.addMetadata && value !== 'add-metadata') {
                        root.addMetadata.metadataId(null);
                        root.addMetadata.value(null);
                    }

                    if (value && root.expand) {
                        root.expand(false);
                    }
                },

                deferEvaluation: true
            },

            companyName: function () {
                function isSignedIn(user) {
                    return user.name === waterfall.authenticate.username;
                }

                var currentUser = _.find(viewmodel.globals.users(), isSignedIn);
                var nameSpecified = currentUser && currentUser.details.company;

                return nameSpecified ? nameSpecified : strings.company;
            },

            fullName: function (root) {
                var data = root.data();

                return root.channel() === 'facebook' ?
                    data.details.firstName + ' ' + data.details.lastName :
                    strings.company;
            },

            conversation: {
                read: function () {
                    return this.messages();
                },
                write: function (root, value) {
                    var channel = root.channel() === 'sms' ?
                        _rawSMSMessages : _rawFBMessages;

                    value = value.reverse();

                    root.messages(_.map(value, function (message) {
                        return ko.mapping.fromJS(message, channel);
                    }));
                }
            },

            characterCount: function (root) {
                if (root.channel() === 'sms') {
                    return viewmodel.globals.shortcodes.session().maxSmsLength;
                } else {
                    return null;
                }
            },

            // used by the 'remove from list' widget
            list: {
                read: function (root) {
                    var list =
                        root.removeFromList && root.removeFromList.lists();

                    return _.map(list, function (list, id) {
                        return {
                            id: id,
                            name: (viewmodel.globals.lists.byId(id) || {}).name
                        };
                    });
                },

                deferEvaluation: true
            },

            subscriberDisplayName: {
                read: function () {
                    var isSubscriberNamesFeatureEnabled =
                        this.isSubscriberNamesFeatureEnabled();
                    var cache = subscriberCache();
                    var message = this.data();
                    var subscriber = cache[message.subscriber];
                    var metadata = viewmodel.globals.metadata();
                    var firstNameMetadata = _.find(metadata,
                        function (metadatum) {
                            return metadatum.scope === 'PROFILE' &&
                                metadatum.name === 'firstName';
                        });
                    var lastNameMetadata = _.find(metadata,
                        function (metadatum) {
                            return metadatum.scope === 'PROFILE' &&
                                metadatum.name === 'lastName';
                        });
                    var firstName, lastName;

                    if (!isSubscriberNamesFeatureEnabled ||
                        this.channel() !== 'sms' ||
                        !subscriber ||
                        !(firstNameMetadata && lastNameMetadata)) {
                        return this.phoneNo();
                    }

                    firstName =
                        subscriber.metaData[firstNameMetadata.id] ||
                        [];
                    lastName =
                        subscriber.metaData[lastNameMetadata.id] ||
                        [];

                    if (!firstName.length && !lastName.length) {
                        return this.phoneNo();
                    }

                    return [firstName[0], lastName[0]].join(' ');
                },

                deferEvaluation: true
            },

            hasEmailProfileMetadata: function () {
                return !!emailMetadata();
            },

            isMarketron: function () {
                return sharedStrings.deployTarget === 'marketron';
            }
        },

        subcomputed: {
            createdAt: dateSubcomputed
        },

        validation: {
            emailFrom: {
                required: {
                    onlyIf: function () {
                        return this.root.isChannelEmail();
                    }
                }
            },

            emailAddress: {
                validation: {
                    validator: function (val) {
                        if (this.root.isChannelEmail()) {
                            return validateEmail(val);
                        } else {
                            return true;
                        }
                    },
                    message: strings.sendMessage.email.validation
                }
            },

            emailTo: {
                validation: {
                    validator: function (val) {
                        if (this.root.isChannelEmail()) {
                            return validateEmail(val);
                        } else {
                            return true;
                        }
                    },
                    message: strings.sendMessage.email.validation
                }
            },

            emailSubject: {
                required: {
                    onlyIf: function () {
                        return this.root.isChannelEmail();
                    }
                }
            },

            subject: {
                required: {
                    onlyIf: function () {
                        return this.root.replyChannel() === 'sms' &&
                            this.root.mobileReplyType() === 'mms';
                    }
                }
            },

            sendMessage: {
                required: true
            }
        }
    };

    $.widget('msgme.msgme_stream_entry', ThreeWidget, {
        _mapping: mapping,

        _template: template,

        _create: function () {
            var el, vm;
            var subscribers = localStorage.getItem('hideSubscribers') ?
                JSON.parse(localStorage.getItem('hideSubscribers')) : [];

            ThreeWidget.prototype._create.apply(this, arguments);

            this._instantiated = {};

            el = this.element;
            vm = this.option('viewmodel');

            vm.visibleWidget.
                subscribe(_.bind(this._onVisibleWidgetChange, this));

            el.find('.options .dropdown-toggle').dropdown();

            this.on('click', '[data-widget] .cancel', '_onClickCancel');
            this.on('click', '.clear-message', '_onClickClear');
            this.on('click', '[data-show]', '_onClickShowWidget');
            this.on('click', '.toggle-expand', '_onClickExpand');
            this.on('click', '.edit-email-to', '_makeEditableAndFocusEmailTo');
            this.on('click', '.add-email-help button',
                '_addEmailButtonClicked');
            this.on('click', '.send-message [type=submit]', '_onSubmitReply');
            this.on('submit', '.sub-widgets', '_onSubmitSubWidget');
            this.on('change', '.reply-channel', '_resetReplyForms');
            this.on('click', '.hide-subscriber', '_hideSubscriber');

            featureFlags('showSubscriberNames').then(_.bind(function () {
                vm.isSubscriberNamesFeatureEnabled(true);
                this._fetchSubscriber();
            }, this));

            if (_.indexOf(subscribers, vm.data().subscriber) !== -1) {
                el.hide();
            }
        },

        _hideSubscriber: function (e) {
            var vm = this.option('viewmodel');
            var subscribers = localStorage.getItem('hideSubscribers') ?
                JSON.parse(localStorage.getItem('hideSubscribers')) : [];

            msgme.modal.confirm('Are you sure you want to hide this ' +
                'subscriber? You can unhide subscribers by clearing ' +
                'your cookies', {
                title: 'Hide Subscriber'
            }).done(_.bind(function (value) {
                if (value) {
                    subscribers.push(vm.data().subscriber);
                    localStorage.setItem('hideSubscribers',
                        JSON.stringify(subscribers));
                    $(e.target.closest('.stream-entry-container')).hide();
                }
            }));
        },

        _createViewModel: function () {
            var vm = ThreeWidget.prototype._createViewModel.
                apply(this, arguments);

            vm.globals = viewmodel.globals;

            return vm;
        },

        _emptySubscriber: function () {
            if (!this._emptySubscriberInstance) {
                this._emptySubscriberInstance =
                    waterfall.subscribers.recordFactory({
                        id: this.option('viewmodel').data().subscriber,
                        metadata: {}
                    });
            }

            return this._emptySubscriberInstance;
        },

        _instantiateWidget: function (name) {
            var vm = this.option('viewmodel');

            this._instantiated[name] = $(subWidgets[name].template).
                appendTo(this.element.find('.sub-widgets'));

            vm[_.camelize(name)] =
                ko.mapping.fromJS({}, subWidgets[name].mapping);

            ko.applyBindings(vm, this._instantiated[name][0]);

            this._instantiated[name]['msgme_' + _.underscored(name)]({
                data: vm
            });
        },

        _instantiateSmsOnlyWidgets: function() {
            var vm = this.option('viewmodel');
            var mmbModel;
            var fileUploaderOptions;

            if (!this._instantiated['mobile-message-body']) {
                mmbModel = {
                    subject: vm.subject,
                    message: vm.sendMessage,
                    fileIds: vm.fileIds,
                    isDisabled: false,
                    currentTab: vm.mobileReplyType,
                    enableSMSFallback: false,
                    // THIS will cause BUGS
                    accountFeaturesKey: 'fixedBroadcastMessage'
                };

                this._instantiated['mobile-message-body'] =
                    this.element.find('.mobile-message-body-container').
                        msgme_mobile_message_body({model: mmbModel});
            }

            if (!this._instantiated['file-uploader']) {
                fileUploaderOptions = {
                    data: vm.attachment,
                    uploadButtonClasses: _.extend({}, uploadButtonClasses, {
                        btn: false,
                        'fa-paperclip': true
                    }),
                    browseText: ''
                };

                this._instantiated['file-uploader'] =
                    this.element.find('.file-uploader-inner').
                        msgme_file_uploader(fileUploaderOptions);
            }

        },

        _fetchSubscriber: function () {
            var vm = this.option('viewmodel');
            var message = vm.data();

            if (vm.channel() !== 'sms' ||
                subscriberCache()[message.subscriber]) {
                return;
            }

            subscriberRequests = subscriberRequests.
                then(_.bind(function () {
                    var message = this.option('viewmodel').data();

                    if (this.element.closest('body').length === 0) {
                        return;
                    }

                    return waterfall.subscribers.fetch(message.subscriber);
                }, this)).
                then(function (subscriber) {
                    var newCache = {};

                    newCache[subscriber.id] = subscriber;
                    subscriberCache(_.extend({}, subscriberCache(), newCache));
                });

        },

        _invalidateSubscriberCache: function () {
            var message = this.option('viewmodel').data();

            if (message) {
                subscriberCache()[message.subscriber] = null;
            }
        },

        _onClickCancel: function (evt) {
            evt.preventDefault();
            this.option('viewmodel').visibleWidget(null);
        },

        _onClickClear: function (evt) {
            var vm = this.option('viewmodel');
            var el = this.element;

            evt.preventDefault();

            return modal.confirm(strings.clear.confirmation, {
                    title: strings.clear.title
                }).
                then(function (confirmed) {
                    if (confirmed) {
                        return vm.data().clear().
                            then(function () {
                                return waterfall.stream.inboxCount();
                            }).
                            then(function (inboxCount) {
                                viewmodel.globals.inboxCount(inboxCount);
                                el.trigger('cleared');
                            }).
                            fail(api.getRequestFailureFn(null,
                                    'stream.clear'));
                    }
                });
        },

        _onClickShowWidget: function (evt) {
            var widget = evt.target.getAttribute('data-show');
            var vm = this.option('viewmodel');
            var el;

            evt.preventDefault();
            vm.visibleWidget(widget);
            el = $('.' + widget, this.element);
            el.msgme('busy', true);

            api.call('subscribers.fetch', vm.data().subscriber).
                done(function (record) {
                    vm.subscriberProfile(record);
                    if (vm.removeFromList) {
                        vm.removeFromList.lists(record.lists);
                    }
                }).
                always(function () { el.msgme('busy', false); }).
                fail(api.getRequestFailureFn(null, 'subscribers.fetch',
                    vm.data().subscriber));
        },

        // whenever one of the forms in a sub-widget is submitted, call the
        // appropriate handler.
        //
        // for example, if the form in the `msgme_add_to_list` widget was
        // submitted, call `this._addToList`
        _onSubmitSubWidget: function (evt) {
            var widget = $(evt.target).closest('[data-widget]').data('widget');
            var callback = _.camelize(widget.replace(/^msgme_/, ''));

            evt.preventDefault();

            this['_' + callback](this.option('viewmodel')[callback]).
                then(_.bind(function () {
                    this.option('viewmodel').visibleWidget(null);
                }, this));
        },

        _onVisibleWidgetChange: function (widget) {
            if (widget && !this._instantiated[widget]) {
                this._instantiateWidget(widget);
            }
        },

        _addToList: function (data) {
            return api.call('messaging.send', {
                    modules: [{
                        type: 'SUBSCRIPTION',
                        params: {
                            listId: data.listId(),
                            optInType: data.optInType(),
                            optInMessage: data.optInMessage(),
                            confirmMessage: data.confirmMessage(),
                            subscribedMessage: data.subscribedMessage()
                        }
                    }],
                    subscribers: [this.option('viewmodel').data().subscriber]
                }).
                then(function () {
                    msgme.alert.success(strings.subscribed);
                }).
                fail(api.getRequestFailureFn(null, 'subscription', data));
        },

        _removeFromList: function (data) {
            var subscriber = this._emptySubscriber();
            var params = {list: data.listId()};

            return subscriber.unsubscribe(params).
                then(function () {
                    msgme.alert.success(strings.unsubscribed);
                }).
                fail(api.getRequestFailureFn(null,
                    'subscriber.unsubscribe', params));
        },

        _addMetadata: function (data) {
            var subscriber = this._emptySubscriber();
            var params = {
                id: data.metadataId(),
                value: data.value()
            };

            return subscriber.metadata.add(params).
                then(_.bind(function () {
                    this._invalidateSubscriberCache();
                    msgme.alert.success(strings.metadataAdded);
                    featureFlags('showSubscriberNames').
                        then(_.bind(this._fetchSubscriber, this));
                }, this)).
                fail(api.getRequestFailureFn(null,
                    'subscriber.metadata.add', params));
        },

        _onClickExpand: function () {
            var vm = this.option('viewmodel');
            var data = vm.data();
            var convo;
            var sub = $.Deferred().resolve();
            var post = $.Deferred().resolve();
            var el = this.element;

            if (vm.expand()) {
                vm.expand(false);
                return;
            }

            el.msgme('busy', true);
            if (vm.channel() === 'facebook') {
                post = viewmodel.globals.channels.oneByName('facebook').
                    posts.fetch(data.details.postId).
                        fail(api.getRequestFailureFn(null,
                            'channels.facebook.posts',
                            data.details.postId));
            } else if (vm.channel() === 'sms') {
                this._instantiateSmsOnlyWidgets();
                sub = waterfall.subscribers.v2.fetch(vm.data().subscriber);
            }

            convo = data.conversation.fetch({ page: 1, size: 10 });

            $.when(convo, post, sub).
                done(function (conversation, facebookPost, subscriber) {
                    if (facebookPost) {
                        vm.facebookPost(facebookPost);
                    } else if (subscriber) {
                        vm.isSubscriberFetched(true);
                        if (subscriber.email) {
                            vm.emailTo(subscriber.email);
                            vm.storedEmailTo(subscriber.email);
                        }
                    }

                    vm.conversation(conversation);
                    vm.expand(true);
                }).
                always(function () { el.msgme('busy', false); }).
                fail(api.getRequestFailureFn(null,
                    'stream.conversation', { page: 1, size: 10 }));
        },

        _onSubmitReply: function () {
            var vm = this.option('viewmodel');
            var message;
            var replyPromise;
            var saveEmailToPromise;
            var params;
            var attachment;
            var widget = this;

            if (vm.isValid()) {
                if (vm.isChannelEmail()) {
                    if (vm.attachment()) {
                        attachment = [vm.attachment()];
                    } else {
                        attachment = null;
                    }
                    params = {
                        id: emailMetadata().id,
                        value: vm.emailTo()
                    };
                    message = {
                        channel: 'email',
                        message: vm.sendMessage(),
                        subject: vm.emailSubject(),
                        from: _.sprintf('"%s" <%s>',
                            vm.emailFrom(), vm.emailAddress()),
                        attachments: attachment
                    };

                    if (vm.emailTo() !== vm.storedEmailTo()) {
                        saveEmailToPromise =
                            waterfall.subscribers.fetch(vm.data().subscriber).
                                then(function (subscriber) {
                                    return subscriber.metadata.
                                        add(params);
                                });
                    } else {
                        saveEmailToPromise = $.Deferred().resolve();
                    }
                } else {
                    message = { message: vm.sendMessage() };

                    if (vm.mobileReplyType() === 'mms') {
                        message.subject = vm.subject();
                        message.files = vm.fileIds();
                    }

                    saveEmailToPromise = $.Deferred().resolve();
                }

                replyPromise = saveEmailToPromise.
                    then(function () {
                        return vm.data().conversation.reply(message);
                    }).
                    then(function () {
                        return vm.data().conversation.
                            fetch({ page: 1, size: 10 });
                    }).
                    then(function (conversation) {
                        vm.conversation(conversation);
                    }).
                    done(function () {
                        msgme.alert.success(sharedStrings.message.sent);
                        widget._resetReplyChannel();
                    }).
                    fail(api.getRequestFailureFn(null, 'stream.send', message));

                vm.replyPromise(replyPromise);
            } else {
                vm.isModified(true);
            }
        },

        _makeEditableAndFocusEmailTo: function () {
            var vm = this.option('viewmodel');
            var $emailToInput = this.element.find('.email-to input');

            vm.editEmailTo(true);
            $emailToInput.focus().select();
        },


        _addEmailButtonClicked: function () {
            var vm = this.option('viewmodel');
            vm.addEmailButtonClicked(true);
            this._makeEditableAndFocusEmailTo();
        },

        _resetSmsForm: function () {
            var vm = this.option('viewmodel');

            vm.subject(null);
            vm.sendMessage(null);
            vm.fileIds([]);
            vm.mobileReplyType('sms');
            vm.isModified(false);
        },

        _resetEmailForm: function () {
            var vm = this.option('viewmodel');
            var storedEmailTo = vm.storedEmailTo();

            vm.addEmailButtonClicked(false);
            vm.editEmailTo(false);

            vm.emailTo(storedEmailTo);
            vm.emailFrom(null);
            vm.emailAddress(null);
            vm.emailSubject(null);
            vm.sendMessage(null);
            vm.attachment(null);

            vm.isModified(false);
        },

        _resetReplyForms: function () {
            this._resetSmsForm();
            this._resetEmailForm();
        },

        _resetReplyChannel: function () {
            this.option('viewmodel').replyChannel('sms');
            this._resetReplyForms();
        },

        instantiateMessageTextbox: function () {
            var vm = this.option('viewmodel');

            if (vm.characterCount()) {
                this.element.find('.msgme-message-textbox').
                    msgme_message_textbox({
                        data: vm.sendMessage,
                        maxLength: vm.characterCount()
                    });
            } else {
                this.element.find('.count-container').hide();
            }
        }
    });

    return {
        widget: $.msgme.msgme_stream_entry,
        mapping: mapping
    };
});

