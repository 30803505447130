define('msgme/views/metadata',[
    'msgme/underscore',
    'msgme/splash',
    'msgme/path',
    'msgme/ko',
    'msgme/viewmodel',
    'msgme/viewmodel/mapping',
    'msgme/viewmodel/metadata',
    'msgme/views/View',
    'msgme/widgets/pager',
    'msgme/util/feature-flags',
    'msgme/globals',
    'json!widgets/shared-strings.json',
    'msgme/plugins'
],
function (_, splash, path, ko, viewmodel, mapping, metadata, View, Pager,
    featureFlags, globals, sharedStrings
) {
    var view = new View('#metadata-view');
    var scopes = {
        SYSTEM : 3,
        PROFILE : 2,
        ACCOUNT : 1,
        GROUP : 0
    };
    var auth = waterfall.authenticate;
    var modalInitialized = false;
    var page, pager;
    var url = sharedStrings.sitemap.metadata.url;

    pager = new Pager(viewmodel.metadata);

    // view model mapping for a single metadatum
    var cm = new mapping.CursorModel(waterfall.metadata, {
        record : {
            defaultData: {
                id: null,
                name: '',
                format: '',
                scope: null,
                validValues: null,
                multiValue: false,
                eventUrl: null,
                type: ''
            },
            mapping: metadata.mapping
        }
    });

    // fetch metadata list
    function fetchMetadata() {
        page = page && _.isNumber(parseInt(page, 10)) ? page : 1;

        $('#metadata-view-main').msgme('busy', true);

        // fetch the metadata
        view.callAPI('metadata.fetch', {
                page: page,
                size: viewmodel.metadata.pageSize
            }).
            done(onFetchMetadataSuccess);
    }

    // munge metadata list data before displaying
    function onFetchMetadataSuccess(result) {
        _.each(result, function (metadatum) {
            metadatum.selectType =
                _.find(metadata.formatToType, function (val, key) {
                    if (metadatum.format === null && key === 'undefined') {
                        return true;
                    }
                    if (metadatum.format) {
                        return metadatum.format.indexOf(key) === 0;
                    }
                }) || '--';

            metadatum.length =
                metadata.getLength(metadatum.format, metadatum.selectType);

            // is this row editable?
            var level = (auth.isRoot ? 2 : 0) + (auth.isAdmin ? 2 : 0);
            metadatum.editable = level >= scopes[metadatum.scope];
            metadatum.type = scopes[metadatum.scope];
        });

        viewmodel.metadata.rows(result.slice());
        pager.update(result);

        splash.hide();
        $('#metadata-view-main').msgme('busy', false);
        $('#metadata-view-empty').removeClass('hidden');
    }

    function afterCancel() {
        if (window.location.pathname.indexOf('new') !== -1) {
            path.history.pushState(null, null, url);
        }
    }

    function showModal() {
        var level = (auth.isRoot ? 2 : 0) + (auth.isAdmin ? 2 : 0);
        var editable = level >= scopes[viewmodel.metadatum().scope()] ||
            !viewmodel.metadatum().id();

        viewmodel.metadatum().isAdmin = ko.observable(level > 1);
        viewmodel.metadatum().error(false);
        if (!modalInitialized) {

            $('#metadata-view .msgme-modal').msgme_modal({
                okEnabled: false,

                submit: function () {
                    // Calculate the format, length, and validValues fields
                    // based on metadatum options selected in the modal

                    var model = viewmodel.metadatum();
                    var metadatum = viewmodel.metadatum();

                    if (metadatum.id() && metadatum.scope() === 'PROFILE') {
                        var meta = viewmodel.globals.metadata.oneById(
                            metadatum.id());

                        model.format(meta.format);
                        model.validValues(meta.validValues);
                        model.multiValue(meta.multiValue);
                        model.type(meta.type);
                    } else {
                        var type = model.selectType();
                        var format = metadata.formats[type];
                        var length = 0;
                        var validValues = null;

                        if (type === 'Text' || type === 'Number') {
                            if (model.values() === 'predefined') {
                                // get a trimmed list of valid values and
                                // determine length
                                validValues = model.validValues();
                                length = _.reduce(validValues,
                                    function (max, s) {
                                    return s.length > max ? s.length : max;
                                }, 0);
                            } else {
                                length =
                                    parseInt(model.length(), 10);
                                validValues = null;
                            }
                            format += '{1,' + length + '}';
                        } else if (type === 'Boolean') {
                            // Boolean type should force multiValue to false
                            model.multiValue(false);
                            validValues = [ 'yes', 'no' ];
                        }

                        // update model before saving
                        model.format(format);
                        model.validValues(validValues);

                        if (model.selectType() === 'Date') {
                            model.type('DATE');
                        } else {
                            model.type('STRING');
                        }
                    }

                    if (metadatum.scope() !== 'PROFILE' && !model.isValid()) {
                        model.isModified(true);
                        return false;
                    }

                    model.save().
                        done(function () {
                            $('#metadata-view .msgme-modal').
                                msgme_modal('close');
                            path.history.pushState(null, null, url);
                            msgme.alert.success('The metadata has been saved');
                            viewmodel.globals.metadata.refresh(true);
                        }).
                        fail(function (jqXHR, status, err) {
                            if (jqXHR.status === 400) {
                                viewmodel.metadatum().error(true);
                            } else {
                                view.getRequestFailureFn(null,
                                'metadata.save',
                                ko.toJS(viewmodel.metadatum()))(
                                    jqXHR, status, err);
                            }
                        });

                    // we'll close the dialog ourself, so cancel the default
                    // action
                    return false;
                },

                cancel: function () {
                    _.defer(afterCancel);
                },

                afteropen: function () {
                    $('#metadata-view .modal input.name').focus();
                }
            });

            $('#metadata-view').on('keydown', '.msgme-modal input.name',
                _.debounce(function (e) {
                    var nameOk = $(this).val().length > 0;
                    $(e.delegateTarget).find('.msgme-modal').
                        msgme_modal('option', 'okEnabled', nameOk);
                }, globals.constants.DEBOUNCE_DELAY));
        }

        view.root.find('.edit-metadata .modal-body').msgme('busy', false);
        view.root.find('.modal-body .name-segment').msgme('busy', false);
        $('#metadata-view .msgme-modal.edit-metadata').msgme_modal('open');
        viewmodel.metadatum.valueHasMutated();
        if (viewmodel.metadatum().scope() !== 'GROUP' &&
            viewmodel.metadatum().scope() !== 'ACCOUNT' &&
            viewmodel.metadatum().id() ||
            !editable) {

            if (viewmodel.metadatum().scope() === 'PROFILE' && auth.isRoot ||
                auth.isAdmin) {
                view.root.find('.modal-body .name-segment').msgme('busy', true);
                view.root.find('.modal-body .name').blur();
            } else {
                view.root.find('.edit-metadata .modal-body').
                    msgme('busy', true);
                // remove focus on the input field so 
                // there is no blinking cursor
                view.root.find('.modal-body .name').blur();
            }
        }
    }

    function editMetadata(record) {
        viewmodel.metadatum(record);
        showModal();
        view.root.find('.msgme-modal').
            msgme_modal('option', 'okEnabled', true);
    }

    $('#metadata-view').on('click', 'td i.edit', function () {
        var rowEl = $(this).closest('tr');
        var row = viewmodel.metadata.rows()[rowEl.index()];
        cm.fetch(row.id).
            done(editMetadata).
            fail(view.getRequestFailureFn(null,
                'metadata.fetch',
                row.id));
    });

    // TODO: refactor this as functionality on the datatable
    $('#metadata-view').on('click', 'td i.remove', function () {
        var rowEl = $(this).closest('tr');
        var record = viewmodel.metadata.rows()[rowEl.index()];
        var name = record.name;

        msgme.modal.confirm('Are you sure you want to delete metadata ' +
            name + '?', {
            title: 'Delete Metadata'
        }).done(_.bind(function (value) {
            if (value) {
                rowEl.addClass('deleting');
                record.del().
                    done(function () {
                        fetchMetadata();
                        msgme.alert.success('The metadata has been deleted');
                    }).
                    fail(function () { rowEl.removeClass('deleting'); }).
                    fail(view.getRequestFailureFn(null,
                        'metadata.delete',
                        record.data().id));
            }
        }, this));
    });

    featureFlags('restrictPapaMurphysUI').then(_.bind(function () {
        view.root.find('#metadata-view-header a.btn').hide();
    }, this));

    // Path for showing the edit metadata modal
    path.map(url + '/:id').to(function () {
        view.show();

        if (this.params.id.toLowerCase() === 'new') {
            editMetadata(cm.create());
        } else {
            cm.fetch(this.params.id).
                done(editMetadata).
                fail(view.getRequestFailureFn(null,
                    'metadata.fetch',
                    this.params.id));
        }
    });

    // Path for showing the metadata list view
    path.map(url + '(/page/:page)').to(function () {
        view.show();
        page = this.params.page;
        fetchMetadata();
    });

    return view;
});

