define('widgets/create-report/index',[
    'msgme/underscore',
    'msgme/viewmodel',
    'msgme/viewmodel/report',
    'msgme/util/feature-flags',
    './../three-widget',
    'text!./template.html',
    'json!./strings.json',
    'json!./../shared-strings.json',
    './../datetimepicker/index'
], function (
    _,
    viewmodel,
    report,
    featureFlags,
    ThreeWidget,
    template,
    strings,
    sharedStrings
) {
    /**
     * memo
     *
     * basically just _.reduce except it initializes the memo to an empty
     * object and always returns it
     */
    function memo(iterable, fn, seed) {
        return _.reduce(iterable, function (memo) {
            fn.apply(this, arguments);
            return memo;
        }, seed === void undefined ? {} : seed);
    }

    /**
     * partial
     *
     * partially apply arguments. similar to _.bind, but it doesn't fix the
     * 'this' binding
     */
    function partial(fn) {
        var args = _.toArray(arguments).slice(1);
        return function() {
            return fn.apply(this, args.concat(_.toArray(arguments)));
        };
    }

    /**
     * omit
     *
     * return an new object w/ all of the props of obj except 'key'
     */
    function omit(obj, key) {
        return memo(obj, function (newObj, v, k) {
            if (k !== key) {
                newObj[k] = v;
            }
        });
    }

    /**
     * optionize
     *
     * converts an options object to something that's easily used by ko's
     * <select> bindings
     */
    function optionize(opts) {
        return memo(opts, function (memo, string, token) {
            memo.push({value: token, text: string});
        }, []);
    }

    /**
     * optionizeResource
     *
     * given an OA (presumably from `viewmodel.globals`), produce an array of
     * options that ko's <select> bindings can use
     */
    function optionizeResource(observableArray) {
        return memo(observableArray(), function (memo, record) {
            memo.push({value: record.id, text: record.name});
        }, []);
    }

    /**
     * alphaOptionizeResource
     *
     * given an OA (presumably from `viewmodel.globals`), produce an array of
     * options that ko's <select> bindings can use in alphabetical order
     */
    function alphaOptionizeResource(observableArray) {
        var sorted = _.sortBy(observableArray(), function (record) {
            return record.name.toLowerCase();
        });

        return memo(sorted, function (memo, record) {
            memo.push({value: record.id, text: record.name});
        }, []);
    }

    /**
     * firstFromArray
     *
     * given the name of a query parameter which corresponds to an observable
     * array, provide a computed interface that gets/sets the first item of the
     * array.
     *
     * note that if the array has more than one item in the 'write' function,
     * all of them will be replaced by an array with just the single value.
     *
     * see the description above the 'coupon' computed for motivation.
     *
     * note that the api has a bad habit of setting these arrays to 'null', so
     * we try to guard against that case here.
     */
    function firstFromArray(queryParam) {
        return {
            read: function (root) {
                var array = root.data().query[queryParam]();
                return array && array[0];
            },

            write: function (root, value) {
                var array = root.data().query[queryParam];

                // ensure array is an empty array, not null or filled w/ values
                if (!array() || array().length) {
                    array([]);
                }

                if (value) {
                    array.push(value);
                }
            }
        };
    }

    var mapping = {
        defaults: {
            data: null,
            _scope: null,
            _summaryType: null,
            options: {},
            withinModal: false,
            error: null,
            restrictUI: false,
            isMarketron: false,
            lists: null,
            list: null,
            smartlist: null,
            subGrowthList: null
        },

        options: {
            mapping: {
                computed: {
                    bucketSize: partial(optionize, strings.bucketSize.options),

                    contentType: function (root) {
                        return root.isMarketron() ? partial(optionize,
                        _.omit(strings.contentType.options, 'SPORTSFEED')) :
                        partial(optionize, strings.contentType.options);
                    },

                    couponGroup: partial(optionizeResource,
                            viewmodel.globals.coupons),

                    flow: partial(alphaOptionizeResource,
                            viewmodel.globals.flows),

                    keyword: partial(alphaOptionizeResource,
                            viewmodel.globals.keywords),

                    list: partial(alphaOptionizeResource,
                            viewmodel.globals.lists),

                    messageType: partial(optionize,
                            strings.messageType.options),

                    queryType: function () {
                        var args = [omit(this.queryTypes())];
                        return optionize.apply(
                            this, args.concat(_.toArray([optionize, args])));
                    },

                    scope: partial(optionize, strings.scope.options),

                    summaryType: partial(optionize,
                            strings.summaryType.options),

                    timeframe: partial(optionize, strings.timeframe.options),

                    queryTypes: function (root) {
                        if (root.restrictUI()) {
                            return _.omit(strings.type.options,
                                'messageDetails', 'couponSummary',
                                'listDetails');
                        } else {
                            return strings.type.options;
                        }
                    },

                    repeatInterval:
                        partial(optionize, strings.repeatInterval.options),

                    repeatEvery:
                        partial(optionize, strings.repeatEvery.options),

                    smartlist: partial(optionizeResource,
                            viewmodel.globals.smartlists),
                }
            }
        },

        computed: {
            // the UI only allows one coupon or list, but the api allows
            // multiple, so map the computed to the first item of the array
            coupon: firstFromArray('couponGroups'),

            limitText: {
                read: function (root) {
                    var type = strings.type.options[root.type()];
                    var startLimit =
                        _.sprintf('%s days', root.data().startLimitDays());
                    var durationLimit =
                        _.sprintf('%s days', root.data().durationLimitDays());
                    var support = _.sprintf('<a href="mailto:%s">%s</a>',
                            sharedStrings.supportEmail,
                            sharedStrings.supportEmail);

                    return _.sprintf(strings.largeReports, {
                        type: type,
                        limit: startLimit,
                        duration: durationLimit,
                        contact: support
                    });
                },

                deferEvaluation: true
            },

            scope: {
                read: function (root) {
                    return root._scope();
                },

                write: function (root, value) {
                    root._scope(value);

                    if (value !== 'mobileFlow') {
                        root.data().query.mobileFlow(null);
                    }

                    if (value !== 'keyword') {
                        root.data().query.keyword(null);
                    }
                }
            },

            // in the case of a `messageSummary` report, if the user sets the
            // "Summarize by" select box to "All", or they set it to
            // "Campaigns" and then leave the campaign select box set to "All",
            // then we want to set `data.query.summaryType` to `MOBILEFLOW`.
            // we accomplish this via the computed below, which also has to set
            // `summaryType` to null when it's not a `messageSummary` report
            summaryType: {
                read: function (root) {
                    return root._summaryType();
                },

                write: function (root, value) {
                    var query = root.data().query;
                    var newValue = query.type() === 'messageSummary' ?
                        value : null;

                    root._summaryType(newValue);

                    if (newValue === 'SINGLE_MOBILEFLOW') {
                        query.summaryType('MOBILEFLOW');
                    } else {
                        query.summaryType(newValue);
                    }

                    if (value !== 'SINGLE_MOBILEFLOW') {
                        query.mobileFlow(null);
                    }

                    if (value !== 'KEYWORD') {
                        query.keyword(null);
                    }
                }
            },

            type: {
                read: function (root) {
                    return root.data().type();
                },

                write: function (root, val) {
                    if (!val) {
                        root.summaryType('MOBILEFLOW');
                    }
                    root.data().type(val);
                }
            },

            showList: function () {
                return _.isEmpty(this.data().query.queryFilters());
            },

            showSmartlist: function () {
                return _.isEmpty(this.data().query.lists()) &&
                    !_.isEmpty(viewmodel.globals.smartlists());
            }
        }
    };

    $.widget('msgme.msgme_create_report', ThreeWidget, {
        _mapping: mapping,

        _template: template,

        _create: function () {
            ThreeWidget.prototype._create.apply(this, arguments);

            this._instantiateDatePickers();

            this.on('click', '.cancel', '_onClickCancel');
            this.on('submit', '_onSubmit');
            this.option('viewmodel').data.subscribe(
                    _.bind(this._onDataChange, this));
            featureFlags('restrictPapaMurphysUI').then(_.bind(function () {
                this.option('viewmodel').restrictUI(true);
            }, this));
            featureFlags('marketronFeatures').then(_.bind(function () {
                this.option('viewmodel').isMarketron(true);
            }, this));
            this.on('change', '.select-type select', '_onTypeChange');
            this.on('change', '.multi-list-select', '_onSelectChange');
            this.on('change', '.list-details-select', '_onListDetailsChange');
            this.on('change', '.component-type', '_onContentTypeChange');
            this.on('change', '.query-filter-select', '_onQueryFilterChange');
            this.on('change', '.sub-growth-list', '_onSubGrowthChange');
        },

        _onTypeChange: function () {
            var data = this.option('viewmodel').data();

            data.name(strings.type.options[data.type()]);
        },

        _instantiateDatePickers: function () {
            var data = this.option('viewmodel').data();
            var startLimit = data.startLimit();
            var endLimit = data.endLimit();
            var startSubscription;
            var endSubscription;

            // remove existing ones if they're there
            this.element.find('.date.msgme-datetimepicker').
                msgme_datetimepicker('destroy');

            this.element.find('.start.date').
                msgme_datetimepicker({
                    data: data.startDate,
                    pickTime: false,
                    startDate: startLimit,
                    model: {
                        placeholder: strings.startplaceholder,
                        format: strings.dateformat
                    }
                });

            this.element.find('.end.date').
                msgme_datetimepicker({
                    data: data.endDate,
                    pickTime: false,
                    startDate: startLimit,
                    endDate: endLimit.clone().rewind({days: 1}),
                    model: {
                        placeholder: strings.endplaceholder,
                        format: strings.dateformat
                    }
                });

            // GROOOOOOSSSSSSSS
            startSubscription = data.startLimit.subscribe(_.bind(function () {
                if (!this.element.closest('body').length) {
                    startSubscription.dispose();
                    return;
                }

                this.element.find('.bs-datetimepicker-el').
                    each(function (i, el) {
                        $(el).datetimepicker('setStartDate', data.startLimit());
                    });
            }, this));

            endSubscription = data.endLimit.subscribe(_.bind(function () {
                if (!this.element.closest('body').length) {
                    endSubscription.dispose();
                    return;
                }

                this.element.find('.end .bs-datetimepicker-el').
                    each(function (i, el) {
                        $(el).datetimepicker('setEndDate',
                            data.endLimit().clone().rewind({days: 1}));
                    });
            }, this));
        },

        _onClickCancel: function (evt) {
            evt.stopPropagation();
            this.element.trigger('cancelled');
        },

        _onDataChange: function () {
            var vm = this.option('viewmodel');

            vm.type(null);
            vm.error(null);
            vm.data().timeframe(null);
            this._instantiateDatePickers();
            this.element.find('.sub-growth-list').
                val([]).trigger('change');
        },

        _onSubmit: function (evt) {
            evt.preventDefault();

            this.option('viewmodel').error(null);

            this.disable().
                option('viewmodel').data().save().
                    done(_.bind(function () {
                        this.enable();
                        this.element.trigger('finished');
                        this.option('viewmodel').data().
                            query.contentTypes(null);
                    }, this)).
                    fail(_.bind(function (err) {
                        var response = {};

                        try {
                            response = JSON.parse(err.responseText);
                        } catch (e) {}

                        this.option('viewmodel').error(response.errorMsg ||
                            sharedStrings.validation.badrequest);
                        this.enable();
                    }, this));
        },

        _onSelectChange: function (event) {
            var value = $(event.target).val();

            this.option('viewmodel').data().query.lists(value);
        },

        _onListDetailsChange: function (event) {
            var value = $(event.target).val();

            this.option('viewmodel').data().query.lists(value);
        },

        _onQueryFilterChange: function (event) {
            var value = $(event.target).val();

            this.option('viewmodel').data().query.queryFilters(value);
        },

        _onSubGrowthChange: function (event) {
            var value = $(event.target).val();
            var lists = this.option('viewmodel').data().query.lists;
            var hasLists = viewmodel.globals.lists().length;

            if (value) {
                lists([value]);
            } else if (hasLists) {
                lists([viewmodel.globals.lists()[0]]);
            }
        },

        _onContentTypeChange: function (event) {
            var value = $(event.target).val();

            this.option('viewmodel').data().query.contentTypes(value);
        },

        disable: function () {
            this.element.
                find('[type=submit]').addClass('disabled').end().
                find('select').prop('disabled', true);
            return this;
        },

        enable: function () {
            this.element.
                find('[type=submit]').removeClass('disabled').end().
                find('select').prop('disabled', false);
            return this;
        }
    });

    return {
        mapping: mapping,
        widget: $.msgme.msgme_create_report
    };
});

