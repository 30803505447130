define('msgme/viewmodel/flowmodule-sweeps',[
    'msgme/util/format',
    'msgme/viewmodel/util/dateparts',
    'msgme/viewmodel',
    'json!widgets/shared-strings.json'
], function(format, getDateParts, viewmodel, sharedStrings) {

    return {
        mapping: {
            local: {
                title: 'Sweepstakes',
                previousList: null
            },

            params: {
                mapping: {
                    defaults: {
                        startDate: format.apiDate(new Date()),
                        endDate: format.apiDate(
                            Date.now() + 24 * 60 * 60 * 1000),
                        listId: '',
                        preSweepsMessage: '',
                        preSweepsSubject: '',
                        preSweepsFiles: [],
                        preSweepsAd: null,
                        preSweepsSponsorship: null,
                        preSweepsNextStep: 'terminate',
                        preSweepsMobileFlow: '',
                        preSweepsSMSFallback: null,
                        postSweepsMessage: '',
                        postSweepsNextStep: 'terminate',
                        postSweepsMobileFlow: '',
                        postSweepsSubject: '',
                        postSweepsFiles: [],
                        postSweepsAd: null,
                        postSweepsSponsorship: null,
                        postSweepsSMSFallback: null,
                        alreadyEnteredMessage: '',
                        alreadyEnteredNextStep: 'terminate',
                        alreadyEnteredMobileFlow: '',
                        alreadyEnteredSubject: '',
                        alreadyEnteredFiles: [],
                        alreadyEnteredAd: null,
                        alreadyEnteredSponsorship: null,
                        alreadyEnteredSMSFallback: null,
                        response: '',
                        nextStep: 'terminate',
                        mobileFlow: '',
                        subject: '',
                        files: [],
                        ad: null,
                        sponsorship: null,
                        smsFallback: null
                    },

                    local: {
                        preSweepsCurrentTab: 'sms',
                        currentTab: 'sms',
                        postSweepsCurrentTab: 'sms',
                        alreadyEnteredCurrentTab: 'sms'
                    },

                    computed: {
                        startDateTime: {
                            read: function () {
                                if (!this.startDate()) {
                                    return null;
                                }

                                return Date.create(this.startDate());
                            },

                            write: function (parent, val) {
                                if (!val) {
                                    this.startDate(null);
                                } else {
                                    this.startDate(format.apiDate(val));
                                    this.startDate.valueHasMutated();
                                }
                            },

                            deferEvaluation: true
                        },
                        endDateTime: {
                            read: function () {
                                if (!this.endDate()) {
                                    return null;
                                }

                                return Date.create(this.endDate());
                            },

                            write: function (parent, val) {
                                if (!val) {
                                    this.endDate(null);
                                } else {
                                    this.endDate(format.apiDate(val));
                                    this.endDate.valueHasMutated();
                                }
                            },

                            deferEvaluation: true
                        }
                    },

                    validation: {
                        endDate: {
                            validation: {
                                validator: function (val) {
                                    return (new Date(this.parent.startDate())) <
                                        (new Date(val));
                                },
                                message:
                                    sharedStrings.mobileflows.validation.endDate
                            }
                        },
                        listId: {
                            validator: function (val) {
                                return !!val && val !== 'placeholder';
                            },

                            message: 'Please select a list'
                        },
                        nextStep: {
                            required: true
                        },
                        mobileFlow: {
                            validation: {
                                validator: function (val) {
                                    if (this.parent.nextStep() ===
                                        'forwardToMobileFlow') {
                                        if (viewmodel.flow() &&
                                            viewmodel.flow().id) {
                                            return !!val && val !==
                                                viewmodel.flow().id();
                                        } else {
                                            return !!val;
                                        }
                                    } else {
                                        return true;
                                    }
                                },

                                message: 'Please select a different list'
                            }
                        },
                        preSweepsNextStep: {
                            required: true
                        },
                        preSweepsMobileFlow: {
                            validation: {
                                validator: function (val) {
                                    if (this.parent.preSweepsNextStep() ===
                                        'forwardToMobileFlow') {
                                        if (viewmodel.flow() &&
                                            viewmodel.flow().id) {
                                            return !!val && val !==
                                                viewmodel.flow().id();
                                        } else {
                                            return !!val;
                                        }
                                    } else {
                                        return true;
                                    }
                                },

                                message: 'Please select a different list'
                            }
                        },
                        postSweepsNextStep: {
                            required: true
                        },
                        postSweepsMobileFlow: {
                            validation: {
                                validator: function (val) {
                                    if (this.parent.postSweepsNextStep() ===
                                        'forwardToMobileFlow') {
                                        if (viewmodel.flow() &&
                                            viewmodel.flow().id) {
                                            return !!val && val !==
                                                viewmodel.flow().id();
                                        } else {
                                            return !!val;
                                        }
                                    } else {
                                        return true;
                                    }
                                },

                                message: 'Please select a different list'
                            }
                        },
                        alreadyEnteredNextStep: {
                            required: true
                        },
                        alreadyEnteredMobileFlow: {
                            validation: {
                                validator: function (val) {
                                    if (this.parent.alreadyEnteredNextStep() ===
                                        'forwardToMobileFlow') {
                                        if (viewmodel.flow() &&
                                            viewmodel.flow().id) {
                                            return !!val && val !==
                                                viewmodel.flow().id();
                                        } else {
                                            return !!val;
                                        }
                                    } else {
                                        return true;
                                    }
                                },

                                message: 'Please select a different list'
                            }
                        }
                    }
                }
            }
        }
    };
});

