define('widgets/report-parameters/index',[
    'msgme/underscore',
    'msgme/ko',
    'msgme/util/format',
    'msgme/viewmodel',
    'msgme/viewmodel/report',
    './../three-widget',
    'text!./template.html',
    'json!./strings.json',
    'json!../create-report/strings.json'
], function (
    _,
    ko,
    format,
    viewmodel,
    reportViewModel,
    ThreeWidget,
    template,
    strings,
    createReportStrings
) {
    function getComputed(fn) {
        return {
            read: fn,
            deferEvaluation: true
        };
    }

    _.defaults(strings.messageType, createReportStrings.messageType.options);
    _.defaults(strings.contentType, createReportStrings.contentType.options);
    _.defaults(strings.timeframe, createReportStrings.timeframe.options);
    _.defaults(strings.bucketSize, createReportStrings.bucketSize.options);

    var mapping = {
        defaults: {
            data: null
        },
        computed: {
            summary: getComputed(function (rootModel) {
                        switch (rootModel.data().query.type()) {
                        case 'messageDetails':
                            return _.without([
                                rootModel.scope(),
                                rootModel.messageType(),
                                rootModel.contentType(),
                                rootModel.excludeBroadcasts(),
                                rootModel.timeframe()
                            ], '').join(', ');
                        case 'messageSummary':
                            return [
                                rootModel.summaryType(),
                                rootModel.timeframe(),
                                rootModel.bucketSize()
                            ].join(', ');
                        case 'subscriberGrowth':
                            return [
                                rootModel.list(),
                                rootModel.timeframe(),
                                rootModel.bucketSize()
                            ].join(', ');
                        case 'broadcastSummary':
                            return [
                                rootModel.allLists(),
                                rootModel.timeframe(),
                            ].join(', ');
                        case 'couponSummary':
                            return [
                                rootModel.couponGroup(),
                                rootModel.timeframe(),
                                rootModel.bucketSize()
                            ].join(', ');
                        case 'listDetails':
                            return [
                                rootModel.list()
                            ].join(', ');
                        case 'pollSummary':
                            return [
                                rootModel.flow()
                            ].join(', ');
                        case 'subscriberConversion':
                            return [
                                rootModel.list(),
                                rootModel.timeframe(),
                                rootModel.bucketSize()
                            ].join(', ');
                        default:
                            return '';
                        }
                    }),
            scope: getComputed(function (rootModel) {
                    var data = rootModel.data();

                    var keywordId = data.query.keyword();
                    var keyword;
                    var keywordString;

                    var flowId = data.query.mobileFlow();
                    var flow;
                    var flowString;

                    var campaignId = data.query.campaign();
                    var campaign;
                    var campaignString;

                    if (keywordId) {
                        keyword = viewmodel.globals.keywords.oneById(keywordId);
                        keywordString = keyword ?  keyword.name :
                            strings.notFound;
                        return _.sprintf(strings.scope.keyword, keywordString);
                    } else if (flowId) {
                        flow = viewmodel.globals.flows.oneById(flowId);
                        flowString = flow ? flow.name : strings.notFound;
                        return _.sprintf(strings.scope.campaign, flowString);
                    } else if (campaignId) {
                        campaign = viewmodel.globals.flows.oneById(campaignId);
                        campaignString = campaign ? campaign.name :
                            strings.notFound;
                        return _.sprintf(strings.scope.campaign,
                            campaignString);
                    } else {
                        return strings.scope.all;
                    }
                }),
            messageType: getComputed(function (rootModel) {
                    var messageType = rootModel.data().query.messageType();

                    return strings.messageType[messageType] ||
                        strings.messageType.all;
                }),
            contentType: getComputed(function (rootModel) {
                    var contentType = rootModel.data().query.contentTypes();

                    if (contentType && contentType.length) {
                        return _.map(contentType, function (type) {
                            return strings.contentType[type];
                        }).join(', ');
                    } else {
                        return strings.contentType.all;
                    }
                }),
            summaryType: getComputed(function (rootModel) {
                    var summaryType = rootModel.data().query.summaryType();

                    var keywordId = rootModel.data().query.keyword();
                    var keyword;
                    var keywordString;

                    var flowId = rootModel.data().query.mobileFlow();
                    var flow;
                    var flowString;

                    switch (summaryType) {
                    case 'KEYWORD':
                        if (keywordId) {
                            keyword =
                                viewmodel.globals.keywords.oneById(keywordId);
                            keywordString = keyword ?  keyword.name :
                                strings.notFound;
                            return _.sprintf(strings.summaryType.KEYWORD.one,
                                keywordString);
                        }

                        return strings.summaryType.KEYWORD.all;
                    case 'CAMPAIGN':
                        if (flowId) {
                            flow = viewmodel.globals.flows.oneById(flowId);
                            flowString = flow ? flow.name :
                                strings.notFound;
                            return _.sprintf(strings.summaryType.CAMPAIGN.one,
                                flowString);
                        }

                        return strings.summaryType.CAMPAIGN.all;
                    case 'MOBILEFLOW':
                        if (flowId) {
                            flow = viewmodel.globals.flows.oneById(flowId);
                            flowString = flow ? flow.name :
                                strings.notFound;
                            return _.sprintf(strings.summaryType.MOBILEFLOW.one,
                                flowString);
                        }

                        return strings.summaryType.MOBILEFLOW.all;
                    }
                }),
            timeframe: getComputed(function (rootModel) {
                    var data = rootModel.data();
                    var timeframe = data.query.timeframe();

                    if (timeframe === null) {
                        return _.sprintf(strings.timeframe.CUSTOM,
                            format.uiDateOnly(data.query.beginOn()),
                            format.uiDateOnly(data.query.endOn()));
                    } else {
                        return strings.timeframe[timeframe];
                    }
                }),
            bucketSize: getComputed(function (rootModel) {
                    var bucketSize = rootModel.data().query.bucketSize();

                    return _.sprintf(strings.bucketSize.template,
                        strings.bucketSize[bucketSize]);
                }),
            allLists: getComputed(function (rootModel) {
                    var query = rootModel.data().query;
                    var listGlobal;
                    var listIds;

                    if (!query.lists() && !query.queryFilters()) {
                        return strings.list.everything;
                    } else if (query.queryFilters()) {
                        listIds = query.queryFilters();
                        listGlobal = viewmodel.globals.smartlists;
                    } else {
                        listIds = query.lists();
                        listGlobal = viewmodel.globals.lists;
                    }

                    var listId = listIds && listIds[0];
                    var list = listGlobal.oneById(listId);
                    var listName = '';

                    if (listIds && listIds.length > 1) {
                        _.each(listIds, function (list, index) {
                            if (listGlobal.oneById(list) && index) {
                                listName = listName + ', ' +
                                    listGlobal.oneById(list).name;
                            } else if (listGlobal.oneById(list) && !index) {
                                listName = listGlobal.oneById(list).name;
                            }
                        });

                        return listName;
                    } else {
                        if (!list) { return strings.list.notFound; }
                        return list.name;
                    }
                }),
            list: getComputed(function (rootModel) {
                    var listIds = rootModel.data().query.lists();
                    var listId = listIds && listIds[0];
                    var list = viewmodel.globals.lists.oneById(listId);
                    var listName = '';

                    if (listIds && listIds.length > 1) {
                        _.each(listIds, function (list, index) {
                            if (viewmodel.globals.lists.oneById(list) &&
                                index) {
                                listName = listName + ', ' +
                                    viewmodel.globals.lists.oneById(list).name;
                            } else if (viewmodel.globals.lists.oneById(list) &&
                                !index) {
                                listName =
                                    viewmodel.globals.lists.oneById(list).name;
                            }
                        });

                        return listName;
                    } else {
                        if (!listId) { return strings.list.all; }
                        if (!list) { return strings.list.notFound; }
                        return list.name;
                    }
                }),
            couponGroup: getComputed(function (rootModel) {
                    var couponGroupIds = rootModel.data().query.couponGroups();
                    var couponGroupId = couponGroupIds && couponGroupIds[0];
                    var couponGroup =
                        viewmodel.globals.coupons.oneById(couponGroupId);

                    if (!couponGroupId) { return strings.couponGroup.all; }
                    if (!couponGroup) { return strings.couponGroup.notFound; }
                    return couponGroup.name;
                }),
            flow: getComputed(function (rootModel) {
                    var flowId = rootModel.data().query.mobileFlow();
                    var flow = viewmodel.globals.flows.oneById(flowId);

                    if (!flowId) { return strings.flow.all; }
                    if (!flow) { return strings.flow.notFound; }

                    return flow.name;
                }),
            excludeBroadcasts: getComputed(function (rootModel) {
                    var exclude = rootModel.data().query.excludeBroadcasts();

                    return exclude ? strings.excludeBroadcasts : '';
                })
        }
    };

    $.widget('msgme.msgme_report_parameters', ThreeWidget, {
        _template: template,
        _mapping: mapping
    });

    return {
        widget: $.msgme.msgme_report_parameters,
        mapping: mapping
    };
});

