define('msgme/viewmodel/report',[
    'msgme/underscore',
    'msgme/ko',
    'msgme/viewmodel',
    'msgme/util/format',
    'json!widgets/create-report/strings.json'
], function(_, ko, viewmodel, format, createReportStrings) {
    var util = waterfall.util;

    function mutuallyExcludeScope() {
        var props = ['mobileFlow', 'campaign', 'keyword'];
        var parent = this.parent;

        return _.compact(_.map(props, function (p) {
            return parent[p]();
        })).length < 2;
    }

    function mutuallyExcludeDuration() {
        var root = this.root;

        var isTimeframe = this.parent.timeframe() == null &&
            (root.startDate() != null && root.endDate() != null);
        var isStartEnd = this.parent.timeframe() != null &&
            (root.startDate() == null && root.endDate() == null);

        return (isTimeframe && !isStartEnd) || (!isTimeframe && isStartEnd);
    }

    function apiDateWrapper(which) {
        return {
            read: function (root) {
                var val = root.query[which + 'On']();

                // in the case where this viewmodel represents an existing
                // report (not a new one we created in the browser), the value
                // will already be a date object, so just return that.
                if (!_.isString(val)) {
                    return val;
                } else {
                    return util.dateStringToDate(val);
                }
            },

            write: function (root, val) {
                var type = 'On';

                root.query[which + type](val == null ? val :
                    util.dateToDateString(val));
            }
        };
    }

    function startEndValidation(which) {
        return {
            validator: function () {
                var value = this.observable();
                var startLimit = this.parent.startLimit();
                var endLimit = this.parent.endLimit();

                if (this.parent.timeframe() !== 'CUSTOM') {
                    return true;
                }

                return which === 'start' ?
                    value > startLimit : value < endLimit;
            },

            message: function () {
                var strings = createReportStrings.validation;
                var type = this.parent.type();
                var label = (createReportStrings.type.options[type] || '').
                    toLowerCase();
                var startLimitDays = this.parent.startLimitDays();
                var durationLimitDays = this.parent.durationLimitDays();

                return _.sprintf(strings.startEndLimits, {
                    type: _.capitalize(label),
                    limit: startLimitDays + ' ' + strings.days,
                    duration: durationLimitDays + ' ' + strings.days
                });
            }
        };
    }

    return {
        reportViewMapping: {
            defaults: {
                query: {
                    type: null
                },
                type: null,
                ctypeBasicText: false,
                ctypeCatchAll: false,
                ctypeCollectMetadataQuestion: false,
                ctypeCollectMetadataValidation: false,
                ctypeDynamicContent: false,
                ctypeHelp: false,
                ctypePollQuestion: false,
                ctypePollAnswer1: false,
                ctypePollAnswer2: false,
                ctypePollAnswer3: false,
                ctypePollAnswer4: false,
                ctypePollAnswer5: false,
                ctypePostToURL: false,
                ctypeSubscriptionOptIn: false,
                ctypeSubscriptionConfirm: false,
                ctypeTagMetadata: false,
                ctypeTextToWin: false,
                ctypeValidation: false,
                rows: null,
                pageIndex: -1,
                pageCount: -1,
                pageSize: 15,
                links: [],
                url: null
            },

            computed: {
                typeName: function (root) {
                    return createReportStrings.type.options[root.query.type()];
                }
            }
        },

        collection: viewmodel.reports = {
            rows: ko.observableArray(),
            pageIndex: ko.observable(-1),
            pageCount: ko.observable(-1),
            pageSize: ko.observable(5),
            links: ko.observableArray(),
            url: ko.observable('reports/page')
        },

        bucketSizes: ['DAY', 'WEEK', 'MONTH'],

        contentTypes: [
            'SUBSCRIPTION',
            'STOP',
            'BASICTEXT',
            'CATCHALL',
            'COLLECTMETADATA',
            'TAGMETADATA',
            'DYNAMICCONTENT'
        ],

        messageTypes: ['MO', 'MT'],

        queryTypes: [
            'broadcastSummary',
            'couponSummary',
            'messageDetails',
            'messageSummary',
            'subscriberGrowth',
            'listDetails'
        ],

        summaryTypes: ['MOBILEFLOW', 'KEYWORD', 'CAMPAIGN'],

        timeframes: [
            'TODAY',
            'YESTERDAY',
            'THISWEEK',
            'LAST7',
            'LASTWEEK',
            'LAST14',
            'THISMONTH',
            'LAST30',
            'LASTMONTH',
            'CUSTOM'
        ],

        mapping: {
            defaults: {
                id: null,

                // the following 3 are optional
                account: null,
                group: null,
                shortCode: null,

                name: null,
                runAt: null,
                repeatInterval: null,
                repeatEvery: 0,
                endAt: null,
                query: {}
            },

            local: {
                timeframeState: null,
                startLimitDays: 365,
                durationLimitDays: 95
            },

            computed: {
                startLimit: {
                    read: function () {
                        var startLimitDays = this.startLimitDays();

                        return this.type() === 'messageDetails' ?
                            Date.create().
                                rewind({days: startLimitDays}).reset() :
                            new Date(null);
                    },

                    deferEvaluation: true
                },

                endLimit: {
                    read: function () {
                        var startDate = this.startDate();
                        var durationLimitDays = this.durationLimitDays();
                        var hasLimit = this.type() === 'messageDetails' &&
                            startDate != null;

                        if (this.type() !== 'messageDetails') {
                            return Date.create().addYears(5);
                        }

                        return hasLimit ?
                            startDate.clone().addDays(durationLimitDays) :
                            Date.create().addDays(1);
                    },

                    deferEvaluation: true
                },

                // this is a proxy to the `query.type` value, but setting it
                // will un-set the values that don't apply to the given type
                type: {
                    read: function (root) {
                        return root.query.type();
                    },

                    write: function (root, value) {
                        if (value === 'broadcastSummary') {
                            root.query.summaryType(null);
                            root.query.contentTypes(null);
                            root.query.messageType(null);
                            root.query.bucketSize(null);
                            root.query.couponGroups([]);
                            root.query.queryFilters([]);
                            root.query.lists([]);
                        } else if (value === 'couponSummary') {
                            root.query.summaryType(null);
                            root.query.contentTypes(null);
                            root.query.messageType(null);
                            root.query.bucketSize(null);
                            root.query.lists([]);
                            root.query.queryFilters([]);
                        } else if (value === 'messageDetails') {
                            root.query.summaryType(null);
                            root.query.bucketSize(null);
                            root.query.lists([]);
                            root.query.couponGroups([]);
                            root.query.queryFilters([]);
                        } else if (value === 'messageSummary') {
                            root.query.contentTypes(null);
                            root.query.messageType(null);
                            root.query.bucketSize(null);
                            root.query.lists([]);
                            root.query.couponGroups([]);
                            root.query.queryFilters([]);
                        } else if (value === 'subscriberGrowth') {
                            root.query.summaryType(null);
                            root.query.contentTypes(null);
                            root.query.messageType(null);
                            root.query.couponGroups([]);
                            root.query.queryFilters([]);
                            root.query.lists([]);
                        } else if (value === 'listDetails') {
                            root.query.summaryType(null);
                            root.query.contentTypes(null);
                            root.query.messageType(null);
                            root.query.bucketSize(null);
                            root.query.couponGroups([]);
                            root.timeframe(null);
                            root.query.queryFilters([]);
                            root.query.lists([]);
                        } else if (value === 'subscriberConversion') {
                            root.query.summaryType(null);
                            root.query.contentTypes(null);
                            root.query.messageType(null);
                            root.query.bucketSize(null);
                            root.query.couponGroups([]);
                            root.timeframe(null);
                            root.query.queryFilters([]);
                            root.query.lists([]);
                        }

                        root.query.mobileFlow(null);
                        root.query.keyword(null);
                        root.query.type(value);
                    }
                },

                // this is also a proxy. it unsets the start/endDate when
                // appropriate.
                //
                // the api will also return an error if the value 'CUSTOM' is
                // submitted for the timeframe, but we use that client-side for
                // validation purposes, so we store the 'CUSTOM' value in the
                // `timeframeState` local and set the actual timeframe to null.
                timeframe: {
                    read: function (root) {
                        return root.timeframeState();
                    },

                    write: function (root, value) {
                        if (value !== 'CUSTOM') {
                            root.startDate(null);
                            root.endDate(null);
                        }

                        root.query.timeframe(value === 'CUSTOM' ? null : value);
                        root.timeframeState(value);
                    }
                },

                // these are just pass-through's to query.{start,end}Date
                //
                // for some reason we convert dates to
                // api-formatted-date-strings in the sdk's Record#save method,
                // but not the Collection#create method that initially saves
                // the records. so we have an api-date ko mapping extender.
                //
                // the extender is problematic because:
                // 1) it blows away the stuff set up by 'validation'
                // 2) it's buggy and very complicated to fix
                //
                // so we'll just store the dates as api-date-strings in the
                // query object, and then provide computeds that produce the
                // corresponding date objects
                startDate: apiDateWrapper('begin'),
                endDate: apiDateWrapper('end')
            },

            validation: {
                startDate: startEndValidation('start'),
                endDate: startEndValidation('end'),
                runAt: {
                    validation: {
                        validator: function (val) {
                            return val ? val > new Date() : true;
                        },
                        message: createReportStrings.validation.scheduleDate
                    }
                }
            },

            query: {
                mapping: {
                    defaults: {
                        // must be one of the 'queryTypes'
                        type: null,

                        // **for messageSummary**
                        // must be one of summaryTypes
                        summaryType: null,
                        excludeBroadcasts: false,

                        // **for messageDetails or messageSummary**
                        mobileFlow: null,
                        campaign: null,
                        keyword: null,

                        // **for messageDetails**
                        // must be one of 'contentTypes'
                        contentTypes: [],
                        // must be one of 'messageTypes'
                        messageType: null,

                        // **for all types**
                        timeframe: null,
                        beginOn: null,
                        endOn: null,

                        // **for messageSummary, subscriberGrowth, and
                        // couponSummary**
                        // must be one of 'bucketSizes'
                        bucketSize: null,

                        // **for broadcastSummary, subscriberGrowth, and
                        // listDetails**
                        lists: [],

                        // **for couponSummary**
                        couponGroups: [],

                        queryFilters: []
                    },

                    validation: {
                        type: {
                            required: true
                        },

                        // required for messageSummary
                        summaryType: {
                            validation: {
                                validator: function (val) {
                                    var parent = this.parent;

                                    return parent.type() !== 'messageSummary' ||
                                        !!val;
                                }
                            }
                        },

                        // the following 3 are mutually exclusive and optional
                        mobileFlow: {
                            validation: {
                                validator: mutuallyExcludeScope
                            }
                        },
                        campaign: {
                            validation: {
                                validator: mutuallyExcludeScope
                            }
                        },
                        keyword: {
                            validation: {
                                validator: mutuallyExcludeScope
                            }
                        },

                        // timeframe and start/endDate are mutually exclusive
                        // but one is required
                        timeframe: mutuallyExcludeDuration
                    }
                }
            }
        }
    };
});




