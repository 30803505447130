define('msgme/widgets/flowmodule-validation',[
    'jquery',
    'msgme/ko',
    'msgme/viewmodel',
    'msgme/underscore',
    'msgme/util/format',
    'msgme/widgets/flowmodule',
    'msgme/util/api',
    'widgets/new-list-modal/index'
],
function ($, ko, viewmodel, _, format, flowmodule, api) {
    $.widget('msgme.msgme_flowmodule_validation', flowmodule, {
        options: {
            widgetEventPrefix: 'flowmodule-validation-'
        },

        _create: function () {
            this.on('click', '.add-condition', '_onAddConditionClick');
            this.on('click', '.conditions .btn.remove-condition',
                '_onRemoveConditionClick');
            // TODO: factor this out as a widget
            this.on('change', '.conditions select.metadatum',
                '_onMetadatumChange');
            this.on('change', '.inner-operator', '_onOperatorChange');
            this.on('change', '.right-hand.list', '_onListChange');

            flowmodule.prototype._create.apply(this);
        },

        _onListChange: function (e) {
            if (e.target.value === 'new') {
                $(e.target).find('option:eq(0)').prop('selected', true);
                this._openModal(e);
            } else if (e.target.value === 'placeholder') {
                this.element.find('.list-selection option:eq(0)').
                    prop('selected', true);
            }
        },

        _onOperatorChange: function (e) {
            var index = $(e.target.closest('.condition')).index();

            this.options.data().params.conditions()[index].
                rightHand.valueHasMutated();
        },
        
        _openModal: function (e) {
            var $newListEl = this.element.find('.new-list-container');
            var index = $(e.target.closest('.condition')).index();
            var list = this.options.data().params.
                conditions()[index].rightHand.list;
            
            $newListEl.replaceWith('<div class="new-list-container"/>');
            $newListEl = this.element.find('.new-list-container');
            $newListEl.msgme_new_list_modal({
                model: {
                    list: list
                }
            });
            $newListEl.msgme_new_list_modal('show');
        },

        _onAddConditionClick: function (e) {
            e.preventDefault();
            var mapping = config.mapping.params.mapping.conditions.mapping;

            // add an empty condition, passing the appropriate mapping object
            // and setting the correct root model
            this.options.data().params.conditions.
                push(ko.mapping.fromJS({}, mapping, null, this.options.data()));
        },

        _onRemoveConditionClick: function (e) {
            e.preventDefault();
            var i = $(e.currentTarget).closest('.condition').index();
            this.options.data().params.conditions.splice(i, 1);
        },

        _updateMetadata: function (index, metadataId) {
            // mark the valid values dropdown busy
            var condition = this.options.data().params.conditions()[index];
            var busyEls =
                this.element.find('.condition').eq(index).find('.right-hand');
            busyEls.msgme('busy', true);

            // save off handle to local observable for validValues
            var validValues = condition.rightHandValidValues;

            api.call('metadata.fetch', metadataId).
                done(function (metadata) {
                    // clear the busy indicator for the valid values dropdown
                    busyEls.msgme('busy', false);
                    validValues(metadata.validValues);
                }).
                fail(function () {
                    busyEls.msgme('busy', false);
                });
        },

        _onMetadatumChange: function (e) {
            var index = $(e.currentTarget).closest('.condition').index();
            var condition = this.options.data().params.conditions()[index];
            var metadatumId = condition.rightHand.metadatum();

            if (condition.rightHand.hasMetadatumValue() && metadatumId) {
                this._updateMetadata(index, metadatumId);
            }
        }
    });

    var EMPTY_CONDITION = {
        leftHand: 'subscriber',
        innerOperator: null,
        rightHand: null,
        rightHandValue: null
    };

    var config = {
        mapping: {
            local: {
                title: 'Validation'
            },

            params: {
                mapping: {
                    defaults: {
                        outerOperator: 'any',
                        consequentResponse: '',
                        consequentNextStep: 'terminate',
                        consequentMobileFlow: null,
                        consequentSubject: '',
                        consequentFiles: [],
                        consequentAd: null,
                        consequentSponsorship: null,
                        consequentSMSFallback: null,
                        alternativeResponse: '',
                        alternativeNextStep: 'terminate',
                        alternativeMobileFlow: null,
                        alternativeSubject: '',
                        alternativeFiles: [],
                        alternativeAd: null,
                        alternativeSponsorship: null,
                        alternativeSMSFallback: null,
                        conditions: [_.clone(EMPTY_CONDITION)]
                    },

                    local: {
                        leftHand: 'subscriber',
                        consequentResponseChecked: false,
                        consequentCurrentTab: 'sms',
                        alternativeResponseChecked: false,
                        alternativeCurrentTab: 'sms'
                    },

                    computed: {
                        // In both cases, the computed is true if the user has
                        // checked the box to send a message, or if response is
                        // not the empty string.
                        //
                        // the backend sends '' instead of null, so we need a
                        // local to track the checked state, otherwise it would
                        // be necessary to set the string to ' ' or something,
                        // which would prevent displaying the placeholder
                        sendConsequentResponse: {
                            read: function () {
                                var response = this.consequentResponse();
                                return this.consequentResponseChecked() ||
                                    (response !== null && response.length > 0);
                            },
                            write: function (root, value) {
                                if (!value) {
                                    this.consequentResponse('');
                                }
                                this.consequentResponseChecked(value);
                            }
                        },
                        sendAlternativeResponse: {
                            read: function () {
                                var response = this.alternativeResponse();
                                return this.alternativeResponseChecked() ||
                                    (response !== null && response.length > 0);
                            },
                            write: function (root, value) {
                                if (!value) {
                                    this.alternativeResponse('');
                                }
                                this.alternativeResponseChecked(value);
                            }
                        }
                    },

                    validation: {
                        alternativeNextStep: {
                            required: true
                        },
                        alternativeMobileFlow: function (val) {
                            if (this.parent.alternativeNextStep() ===
                                'forwardToMobileFlow')
                            {
                                if (viewmodel.flow() && viewmodel.flow().id) {
                                    return !!val && val !==
                                        viewmodel.flow().id();
                                } else {
                                    return !!val;
                                }
                            } else {
                                return true;
                            }
                        },
                        consequentNextStep: {
                            required: true
                        },
                        consequentMobileFlow: function (val) {
                            if (this.parent.consequentNextStep() ===
                                'forwardToMobileFlow')
                            {
                                if (viewmodel.flow() && viewmodel.flow().id) {
                                    return !!val && val !==
                                        viewmodel.flow().id();
                                } else {
                                    return !!val;
                                }
                            } else {
                                return true;
                            }
                        },
                        consequentResponse: function (val) {
                            if (this.parent.consequentResponseChecked()) {
                                return !!val;
                            } else {
                                return true;
                            }
                        },
                        alternativeResponse: function (val) {
                            if (this.parent.alternativeResponseChecked()) {
                                return !!val;
                            } else {
                                return true;
                            }
                        }

                    },

                    conditions: {} // defined below to avoid deep indentation
                }
            }
        }
    };

    config.mapping.params.mapping.conditions.mapping = {
        defaults: _.clone(EMPTY_CONDITION),

        local: {
            rightHandValidValues: null
        },

        subcomputed: {
            innerOperator: {
                selected: {
                    read: function () {
                        return this.innerOperator();
                    },
                    write: function (root, parent, value) {
                        this.rightHand(null);
                        this.innerOperator(value);
                    }
                }
            },
            rightHand: {
                valueType: function () {
                    if (this.innerOperator() === 'in' ||
                        this.innerOperator() === 'nin'
                    ) {
                        return 'list';
                    } else if (this.innerOperator() === 'has' ||
                        this.innerOperator() === 'doesNotHave' ||
                        this.innerOperator() === 'hasValue' ||
                        this.innerOperator() === 'doesNotHaveValue'
                    ) {
                        return 'metadatum';
                    } else {
                        return null;
                    }
                },

                list: {
                    read: function (root, parent) {
                        return parent.valueType() === 'list' ?
                            parent() : void undefined;
                    },
                    write: function (root, parent, value) {
                        if (parent.valueType() === 'list') {
                            parent(value);
                        }
                    }
                },

                metadatum: {
                    read: function (root, parent) {
                        return parent.valueType() === 'metadatum' ?
                            parent() : void undefined;
                    },
                    write: function (root, parent, value) {
                        if (parent.valueType() === 'metadatum') {
                            parent(value);
                        }
                    }
                },

                hasMetadatumValue: function () {
                    return this.innerOperator() === 'hasValue' ||
                        this.innerOperator() === 'doesNotHaveValue';
                }
            },

            rightHandValue: {
                fixed: {
                    read: function (root, parent) {
                        return parent();
                    },
                    write: function (root, parent, value) {
                        if (this.rightHandValidValues()) {
                            parent(value);
                        }
                    }
                },

                free: {
                    read: function (root, parent) {
                        return parent();
                    },
                    write: function (root, parent, value) {
                        if (!this.rightHandValidValues()) {
                            parent(value);
                        }
                    }
                }
            }
        },

        validation: {
            innerOperator: {
                required: true
            },
            rightHandValue: function (val) {
                var innerOperator = this.parent.innerOperator();

                if (innerOperator === 'hasValue' ||
                    innerOperator === 'doesNotHaveValue'
                ) {
                    return !!val;
                } else {
                    return true;
                }
            }
        }
    };

    $.msgme.msgme_flowmodule_validation.Model =
        flowmodule.modelFactory({}, config);

    return $.msgme.msgme_flowmodule_validation;
});

