define('msgme/widgets/flowmodule-agegate',[
    'jquery',
    'msgme/underscore',
    'msgme/ko',
    'msgme/widgets/flowmodule',
    'msgme/viewmodel/flowmodule-agegate'
], function (
    $,
    _,
    ko,
    flowmodule,
    ageGate
) {
    $.widget('msgme.msgme_flowmodule_agegate', flowmodule, {
        options: {
            widgetEventPrefix: 'flowmodule-agegate-'
        },

        _create: function () {
            flowmodule.prototype._create.apply(this);
        }
    });

    $.msgme.msgme_flowmodule_agegate.Model =
        flowmodule.modelFactory({}, {mapping: ageGate.mapping});

    return $.msgme.msgme_flowmodule_agegate;
});


